import { Flex, Box, Text, Tooltip } from '@chakra-ui/react';
import { WarningIcon } from './icons';
import { getColorHandler, getColorTextHandler } from './function';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableList = [
    {
        name: 'status',
        value: '',
        sortValue: 'status',
        label: 'sort by status',
        width: 100,
        children: ['-'],
        component: ({ ordersData }) => (
            <Box className="box-column-status" background={getColorHandler({ status: ordersData?.status })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: ordersData?.status })}>
                    {ordersData?.status}
                </Text>
            </Box>
        ),
    },
    {
        name: <WarningIcon color={'#2D2D2D'} />,
        value: 'is_warning',
        // width: 50,
        width: 55,
        children: ['-'],
        component: ({ value }) => (
            <Flex alignItems={'center'} pl={'16px'} pt={'16px'}>
                <WarningIcon color={value ? '#FF7777' : '#FFFF'} />
            </Flex>
        ),
    },
    {
        name: 'kode item',
        width: 140,
        value: 'kode_item',
        sortValue: 'kode_item',
        label: 'sort by kode item',
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast})} cursor='pointer'>
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'nama item',
        width: 280,
        // width: 320,
        value: 'trade_name',
        sortValue: 'trade_name',
        label: 'sort by nama item',
        children: ['-'],
        component: ({ value }) => (
            <Tooltip
                label={value}
                fontStyle={'normal'}
                fontWeight={'400'}
                fontSize={'12px'}
                lineHeight={'18px'}
                textAlign={'left'}
                color="white"
            >
                <Text className="column-text text-ellipsis">{value || '-'}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'kanban card',
        width: 90,
        value: 'kanban_card',
        sortValue: 'kanban_card',
        label: 'sort by kanban card',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'order card',
        width: 90,
        value: 'order_card',
        sortValue: 'order_card',
        label: 'sort by order card',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'UOM',
        width: 50,
        value: 'uom',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'baseline per day',
        value: 'baseline_per_day',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'ROP',
        value: 'stock_pti_rop',
        width: 70,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'max stock',
        value: 'stock_pti_max',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'OSPO',
        value: 'ospo',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'OSPO update',
        value: 'ospo_update',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'SOH PTI',
        value: 'soh_pti',
        width: 110,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'order',
        value: 'order_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="center" gap="8px">
                {['order_qty', 'order_kanban'].map((item, index) => {
                    return (
                        <Box w="60px" display="flex" key={index} justifyContent="center">
                            <Text className="column-text">
                                {formatNumber(ordersData[item])}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'in preparation',
        value: 'preparation_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="center" gap="8px">
                {['in_preparation_qty', 'in_preparation_kanban'].map((item, index) => {
                    return (
                        <Box w="60px" display="flex" key={index} justifyContent="center">
                            <Text className="column-text">
                                {formatNumber(ordersData[item])}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'on delivery',
        value: 'delivery_pcs',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="center" gap="8px">
                {['on_delivery_qty', 'on_delivery_kanban'].map((item, index) => {
                    return (
                        <Box w="60px" display="flex" key={index} justifyContent="center">
                            <Text className="column-text" >
                                {formatNumber(ordersData[item])}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'quality checking',
        value: 'quality',
        width: 150,
        children: ['pcs', 'kanban'],
        component: ({ ordersData }) => (
            <Box display="flex" justifyContent="center" gap="8px">
                {['quality_checking_qty', 'quality_checking_kanban'].map((item, index) => {
                    return (
                        <Box w="60px" display="flex" key={index} justifyContent="center">
                            <Text className="column-text">
                                {formatNumber(ordersData[item])}
                            </Text>
                        </Box>
                    );
                })}
            </Box>
        ),
    },
];
