import React from 'react';
import { Text, Box, Popover, PopoverTrigger, PopoverContent, PopoverBody, Button } from '@chakra-ui/react';

import './styles.scss';

function Index({
    open,
    onClose,
    children,
    option,
    onClickMenu,
    placement,
    width,
    disablelistMenuIcon,
    fontWeightListMenu,
    borderRadiusListMenu = 0,
    paddingListMenu = '9px 12px',
    body = false,
    customBodyContentClassName,
}) {
    return (
        <Box className="raw-data-order-drop-down">
            <Popover placement={placement} isOpen={open} isLazy onClose={onClose}>
                <PopoverTrigger>{children}</PopoverTrigger>
                <PopoverContent
                    className={`filterList ${customBodyContentClassName}`}
                    width={width}
                    borderRadius={borderRadiusListMenu}
                >
                    <PopoverBody h="full" display="grid" padding={0}>
                        {body ||
                            option?.map((item, index) => {
                                return (
                                    <Button
                                        key={index}
                                        onClick={() => {
                                            onClickMenu(item);
                                            onClose();
                                        }}
                                        variant="ghost"
                                        sx={{
                                            padding: paddingListMenu,
                                        }}
                                        className="button-filter-options"
                                    >
                                        {disablelistMenuIcon ? null : (
                                            <Box
                                                sx={{
                                                    marginRight: '11px',
                                                }}
                                            >
                                                {item.icon}
                                            </Box>
                                        )}

                                        <Text
                                            sx={{
                                                color: index === 0 ? '#183775' : '#223647',
                                                fontWeight: fontWeightListMenu,
                                            }}
                                            className="text-select-filter"
                                        >
                                            {item.name}
                                        </Text>
                                    </Button>
                                );
                            })}
                    </PopoverBody>
                </PopoverContent>
            </Popover>
        </Box>
    );
}

export default Index;

/// placement props : (letak popover content)
//              bottom-start,bottom,bottom-end
//              top-start,top,top-end
//              left-start,left,left-end
//              right-start,right,right-end
