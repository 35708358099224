import { sideMenuList } from '@components/sideMenu/functions';
import Cookies from 'universal-cookie';

// switch get cookies for navigate to home
export const goToMainPage = ({ navigate }) => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    switch (appType) {
        case 'ndcToManufacture':
            navigate('/');
            break;
        case 'warehouseToSupplier':
            navigate('/');
            break;
        case 'ndcToManufacture':
            navigate('/');
            break;
        default:
    }
};

// handle set cookies lobbyPage
export const handleClickAppMenu = ({ cookie, navigate }) => {
    const cookies = new Cookies();
    cookies.set('appType', cookie);
    const routerList = sideMenuList();
    try {
        if (cookie === 'userManagement') {
            navigate('/user-management');
            return;
        }
        navigate(routerList[0].link);
    } catch (error) {
        throw new Error('Error handling navigate at handleClickAppMenu');
    }
};

export const hideMenu = ({ manufactureImg, warehouseImg, ndcImg, userImg, profileApps }) => {
    const cookies = new Cookies();
    const appType = cookies.get('role');
    // SuperAdmin
    let menu = [
        {
            img: warehouseImg,
            title: 'Warehouse to Supplier',
            cookies: 'warehouseToSupplier',
            beResponse: 'Warehouse to Supplier',
            isShow: false,
        },
        {
            img: manufactureImg,
            title: 'Manufacture to Warehouse',
            cookies: 'ndcToManufacture',
            beResponse: 'Manufacturing to Warehouse',
            isShow: false,
        },

        {
            img: ndcImg,
            title: 'NDC to Manufacture',
            cookies: 'ndcToManufacture',
            beResponse: 'NDC to Manufacturing',
            isShow: false,
        },
    ];
    profileApps?.map((item) => {
        menu.map((listMenu) => {
            if (listMenu?.beResponse === item?.name) {
                listMenu.isShow = true;
            }
        });
        return item;
    });

    switch (appType) {
        case 'AdminWTS':
            menu = [
                {
                    img: warehouseImg,
                    title: 'Warehouse to Supplier',
                    cookies: 'warehouseToSupplier',
                },
                {
                    img: ndcImg,
                    title: 'User Management',
                    cookies: 'userManagement',
                },
            ];
            break;
        case 'AdminNTM':
            menu = [
                {
                    img: ndcImg,
                    title: 'NDC to Manufacture',
                    cookies: 'ndcToManufacture',
                },
                {
                    img: userImg,
                    title: 'User Management',
                    cookies: 'userManagement',
                },
            ];
            break;
        case 'AdminWTM':
            menu = [
                {
                    img: manufactureImg,
                    title: 'Manufacture to Warehouse',
                    cookies: 'ndcToManufacture',
                },
                {
                    img: userImg,
                    title: 'User Management',
                    cookies: 'userManagement',
                },
            ];
            break;
        default:
    }

    return menu;
};
