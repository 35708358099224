import { DottedIcon } from '@asset/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { isAllowed } from '@helpers/roleAccess';
import DropDown from '@pagesPhase2/rawDataOrder/components/dropDownMenu';
import { IconPlus, RekapDeliveryIcon } from '@pagesPhase2/rawDataOrder/icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { optionMenuDot } from './const';
import ModalFilterMultiple from './multipleFilter';
import { RawDataOrderContext } from '@pagesPhase2/rawDataOrder';
import FilterTradeName from '@components/filterTradeName';
import FilterItemCode from '@components/filterItemCode';

import './styles.scss';

function SectionHead({ section, setSection, onDownloadData }) {
    const { setFilter, setPage: setMainPage } = React.useContext(RawDataOrderContext);
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);

    const onClickMenuDots = (item) => {
        onDownloadData();
        setSection(item?.section);
    };

    return (
        <Box>
            <Flex className="raw-data-order-box-header">
                <Flex gap="10px" alignItems="center">
                    <Text className="text-supplier-stock">Raw Data Order</Text>
                </Flex>
                <Box>
                    {section?.includes('main') && (
                        <Flex className="action-button-container" alignItems="center">
                            <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                            <Box mx="12px">
                                <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                            </Box>
                            <ModalFilterMultiple />
                            <Box className="divider" />
                            {isAllowed({ pageName: 'Raw Data Order', permissionName: 'Rekap Delivery' }) && (
                                <Box className="rekap-delivery_box" mr="19px">
                                    <Box mr="8px">
                                        <RekapDeliveryIcon />
                                    </Box>
                                    <Text
                                        className="text-rekap-delivery"
                                        onClick={() => navigate('/raw-data-order/table-rekap-delivery')}
                                    >
                                        Rekap Delivery
                                    </Text>
                                </Box>
                            )}
                            {isAllowed({ pageName: 'Raw Data Order', permissionName: 'Create New Order' }) && (
                                <Box
                                    onClick={() => navigate('/raw-data-order/add-more-order')}
                                    className="button-add-more-order__container"
                                >
                                    <Box mr="8px">
                                        <IconPlus />
                                    </Box>
                                    <Text className="text-add-more-order">Add More Order</Text>
                                </Box>
                            )}
                            {isAllowed({ pageName: 'Raw Data Order', permissionName: 'Download Raw Data' }) && (
                                <DropDown
                                    fontWeightListMenu={600}
                                    width="160px"
                                    placement="left-start"
                                    customBodyContentClassName="box"
                                    option={optionMenuDot}
                                    open={open}
                                    onClickMenu={(item) => onClickMenuDots(item)}
                                    onClose={() => setOpen(false)}
                                >
                                    <Button
                                        onClick={() => setOpen(true)}
                                        sx={{
                                            width: '32px',
                                            padding: 1,
                                            height: '32px',
                                        }}
                                        variant="ghost"
                                    >
                                        <DottedIcon />
                                    </Button>
                                </DropDown>
                            )}
                        </Flex>
                    )}
                </Box>
            </Flex>
        </Box>
    );
}
export default SectionHead;
