import { Flex, Box } from '@chakra-ui/react';
import { MainDashboardContext } from '@pages/mainDashboard';
import Cookies from 'universal-cookie';
import { useContext, useEffect, useState } from 'react';
import TabMenu from './components/tabMenu';
import Filter from './components/filter';
import SelectStreamLine from './components/streamline';
import Search from './components/search';

import './styles.scss';

const SectionTab = ({ setSelectedTab, selectedTab, streamline, isLoading }) => {
    const cookies = new Cookies();
    const {
        setSelectedStreamlineProductionStatus,
        isOpenListStreamLine,
        searchStreamline,
        setSearchStreamline,
        setIsOpenListStreamLine,
        setSelectedFilterStreamline,
        selectedFilterStreamline,
        filterOrderStatus,
    } = useContext(MainDashboardContext);

    const [filterStreamline, setFilterStreamline] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const onChangeTab = ({ value, filter }) => {
        cookies.set('tab', value);
        cookies.set('filterListOrderStatus', filter || false);
        setSelectedTab(value);
    };

    useEffect(() => {
        onChangeTab({ value: cookies.get('tab') || 'production-status', filter: cookies.get('filterListOrderStatus') });
    }, [setSelectedTab]);

    useEffect(() => {
        if (isOpenListStreamLine) {
            setFilterStreamline([...selectedFilterStreamline]);
        }
    }, [isOpenListStreamLine]);

    return (
        <Flex className="section-tab__container__main-dashboard">
            <Box
                sx={{
                    display: isOpen ? 'none' : 'block',
                }}
            >
                <TabMenu onChangeTab={onChangeTab} selectedTab={selectedTab} filterOrderStatus={filterOrderStatus} />
            </Box>
            <Flex
                sx={{
                    justifyContent: 'flex-end',
                    width: isOpen && '100%',
                }}
            >
                <Box
                    sx={{
                        display: isOpen ? 'none' : 'block',
                    }}
                >
                    <Filter />
                </Box>
                <SelectStreamLine
                    isOpenListStreamLine={isOpenListStreamLine}
                    setIsOpenListStreamLine={setIsOpenListStreamLine}
                    selectedFilterStreamline={selectedFilterStreamline}
                    streamline={streamline}
                    isLoading={isLoading}
                    searchStreamline={searchStreamline}
                    setSelectedStreamlineProductionStatus={setSelectedStreamlineProductionStatus}
                    filterStreamline={filterStreamline}
                    setSelectedFilterStreamline={setSelectedFilterStreamline}
                    setFilterStreamline={setFilterStreamline}
                />

                <Search isOpen={isOpen} setIsOpen={setIsOpen} setSearchStreamline={setSearchStreamline} />
            </Flex>
        </Flex>
    );
};

export default SectionTab;
