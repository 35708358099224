import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { getColorHandler, getColorTextHandler, getColorProgresStatus, getColorTextProgresStatus } from './function';
import { EditIcon } from './icons';
import { isAllowed } from '@helpers/roleAccess';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableList = [
    {
        name: 'No Order',
        value: 'no_order',
        width: 20,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 28,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Status Order',
        value: 'status_order',
        width: 95,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorHandler({ status: value })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: value })}>
                    {value}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text">{value}</Text>
            </Box>
        ),
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        width: 210,
        children: ['-'],
        component: ({ value }) => (
            <Tooltip
                label={value}
                fontStyle={'normal'}
                fontWeight={'400'}
                fontSize={'12px'}
                lineHeight={'18px'}
                textAlign={'left'}
                color="white"
            >
                <Text className="column-text-ellipsis">{value || '-'}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Create Date',
        value: 'created_date',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Konfirmasi ETA',
        value: 'eta_konfirmasi',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Progres Status',
        value: 'progress',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorProgresStatus({ status: value })}>
                <Text className="text-colum-status" color={getColorTextProgresStatus({ status: value })}>
                    {value}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Konfirmasi',
        value: 'qty_konfirmasi',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'In Preparation',
        value: 'in_preparation',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'On Delivery',
        value: 'on_delivery',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Received',
        value: 'recieved',
        width: 40,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Arrival Date',
        value: 'arrived',
        width: 90,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Qty Karantina',
        value: 'qty_karantina',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Status QLT Retain',
        value: 'status_qlt_pending',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Status QLT Reject',
        value: 'status_qlt_reject',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Status QLT Released',
        value: 'status_qlt_released',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: '% Diterima',
        value: 'persentase_diterima',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Action',
        width: 87,
        value: null,
        children: ['-'],
        component: ({ onEdit }) => (
            <Flex
                cursor={
                    isAllowed({ pageName: 'Raw Data Order', permissionName: 'Create New Order' })
                        ? 'pointer'
                        : 'not-allowed'
                }
            >
                <Box
                    cursor="pointer"
                    onClick={() => {
                        if (isAllowed({ pageName: 'Raw Data Order', permissionName: 'Create New Order' })) {
                            onEdit();
                        }
                    }}
                    opacity={
                        isAllowed({ pageName: 'Raw Data Order', permissionName: 'Create New Order' }) ? '100%' : 0.2
                    }
                >
                    <EditIcon />
                </Box>
            </Flex>
        ),
    },
];
