import { useContext } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SectionProductionStatusContext } from '../../section/sectionProductionStatus';

import './styles.scss';

const TableColumnDetailProductStatus = ({
    tableList,
    idx,
    obj,
    setOpenStopProblem,
    setSelectedStopProblem,
    hasMutationAccess,
}) => {
    const { onSelectedDataProductStatus, onSetOpenDetail } = useContext(SectionProductionStatusContext);
    const openStopProblem = () => {
        setSelectedStopProblem({ id: obj.id });
        setOpenStopProblem(true);
    };
    return (
        <Flex className="table-column__container__product-detail-status" overflow={'auto'}>
            {tableList.map((item) => {
                return (
                    <Box key={item.name} background={idx % 2 ? '#F5F5F5' : 'white'} className="column-sticky__item">
                        {item.component({
                            onSelectedDataProductStatus,
                            onSetOpenDetail,
                            obj,
                            value: obj[item.value] || '-',
                            idx,
                            openStopProblem,
                            hasMutationAccess,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumnDetailProductStatus;
