import Axios from '@services/axios';
import moment from 'moment';
import Cookies from 'universal-cookie';
import { initialValue } from '../../const';

export const onSaveNewOrder = async ({ toast, formData, setIsLoading, isEdit, id, navigate, setFormData }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const payload = {
        kode_item: formData?.kode_item,
        supplier_name: formData?.supplier_name,
        no_po: formData?.no_po,
        due_date: moment(formData?.due_date).format('YYYY-MM-DD'),
        qty_konfirmasi: Number(formData?.quantity_confirmation),
    };

    const url = isEdit ? `/raw-data-order/${id}` : '/raw-data-order';
    try {
        const { data } = await Axios({
            method: isEdit ? 'PATCH' : 'POST',
            url: url,
            headers: { Authorization: 'Bearer ' + userToken },
            data: payload,
        });
        await toast({
            position: 'center-top',
            title: data.data.mesage || 'Succes',
            description: 'Succes',
            status: 'success',
            isClosable: true,
            duration: 1000,
        });
        await setFormData(initialValue);
        await navigate('/raw-data-order');
    } catch (error) {
        const message = error?.response?.data?.errors[0]?.message || error?.response?.data?.errors?.join(', ');
        console.log(error);
        toast({
            position: 'top-center',
            description: message,
            status: 'error',
            duration: 3000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};
