import { routeList } from '@routers/const';
import { find, forEach, isEmpty, round } from 'lodash';
import AWS from 'aws-sdk';
import formatThousands from 'format-thousands';
import { Box } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export const getCurrentPage = ({ location }) => {
    const { pathname } = location;
    const route = find(routeList, { link: pathname });

    return route || { name: '', link: '' };
};

export const numToArr = (num) => {
    const arr = [];
    for (let i = 0; i < num; i++) {
        arr.push(i);
    }
    return arr;
};

export const removeEmptyValue = (obj) => {
    const temp = {};

    forEach(obj, (value, key) => {
        if (!isEmpty(value)) {
            temp[key] = value;
        }
    });

    return temp;
};

export const isForbiddenPage = ({ routeList, type }) => {
    return !Boolean(find(routeList, ({ path }) => type === path));
};

/// https://stackoverflow.com/a/10232792
export function isEmptyString(arg) {
    return arg?.toString() === null || arg?.toString()?.match(/^ *$/) !== null;
}

export const FormatTableNumber = ({ page, limit }) => {
    const defaultPaginationPerPage = limit || 10;
    return Array.from(Array(defaultPaginationPerPage * page).keys()).slice(-defaultPaginationPerPage);
};

let sortValue = '';
let sort = '';
export const handleClickSortTable = ({ sortItem, setSort }) => {
    if (sortValue !== sortItem?.sortValue) {
        sort = 'asc';
        sortValue = sortItem?.sortValue;
        setSort(`${sortValue},${sort}`);
    } else {
        sort = 'desc';
        setSort(`${sortValue},${sort}`);
        sortValue = '';
    }
};

/// HELPER UNTUK MNERUBAH ANGKA KOMA MENJADI 2 DIGIT DIBELAKANG KOMA &
/// HELPER UNTUK NGEFORMAT ANGKA ADA SEPERATORNYA MISAL : 1,500,000
export const formatNumber = (arg) => {
    try {
        return formatThousands(round(arg, 2), ',');
    } catch (error) {
        return arg;
    }
};

export const handleCopyToClipboard = async ({ item, toast }) => {
    try {
        if (navigator?.clipboard?.writeText) {
            await navigator.clipboard.writeText(item);
            toast({
                render: () => (
                    <Box color="white" p="15px" fontSize="16px" bg="#303344" textAlign="center" borderRadius="4px">
                        <CopyIcon w={5} h={5} color="white" /> Copy to Clipboard {item}
                    </Box>
                ),
                position: 'center-bottom',
                duration: 1500,
            });
        }
    } catch (err) {
        console.error(err);
    }
};
