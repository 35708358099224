export const handleClickMenu = ({ item, selected, setFormData }) => {
    const isStatus = item?.name === 'label_status';
    if (isStatus) {
        setFormData((prev) => {
            return { ...prev, status_id: selected?.id, label_status: selected['name'] };
        });
    }
    setFormData((prev) => {
        let body = { ...prev, [item?.name]: selected['name'] };
        for (const key in body) {
            if (body[key] === undefined) {
                delete body[key];
            }
        }
        return body;
    });

    if (item?.extra) {
        setFormData((prev) => {
            return {
                ...prev,
                [selected?.form?.name + '_extra']: {
                    ...prev?.[selected?.form?.name + '_extra'],
                    operator: selected?.field?.operator || 'none',
                    [item?.extra?.name]: item?.input,
                },
            };
        });
    }
};

export const selectedFilter = ({ value, setFilterSelected }) => {
    const filterSelected = Object.values(value);
    const keyFilterSelected = Object.keys(value);
    let result = [];
    for (let index = 0; index < filterSelected.length; index++) {
        if (Boolean(filterSelected[index]) && value[keyFilterSelected[index]] === filterSelected[index]) {
            result.push({ [keyFilterSelected[index]]: filterSelected[index] });
        }
    }
    setFilterSelected(result);
};

export const handleCloseModal = ({ filterSelected, setFormData, initialValue, setOpenModal }) => {
    let prevInitialValue = { ...initialValue };
    let keyValue = Object.keys(initialValue);
    let currentSelect = [...filterSelected];
    keyValue.map((key) => {
        currentSelect.map((current) => {
            const keyObject = Object.keys(current);

            if (key === keyObject[0]) {
                prevInitialValue[key] = current[keyObject[0]];
            }
            prevInitialValue[keyObject[0]] = current[keyObject[0]];
        });
    });
    setFormData(prevInitialValue);
    setOpenModal(false);
};

export const findBadgeSelectedFilter = (value) => {
    let result = value?.filter((item) => {
        const keyObject = Object.keys(item);
        if (typeof item[keyObject[0]] === 'string') {
            return item;
        }
    });
    return result;
};
