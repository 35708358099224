import { Text } from '@chakra-ui/react';
import React from 'react';
import { getColorVariant } from './function';

import './styles.scss';

function index({ children }) {
    const { color, bg } = getColorVariant({ text: children });
    return (
        <Text
            sx={{
                color,
                bg,
            }}
            className="text-status-material-config"
        >
            {children}
        </Text>
    );
}

export default index;
