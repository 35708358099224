import { Flex, Box, Text, Tooltip } from '@chakra-ui/react';
import { RestoreIcon, NoteIcon } from './icons';

export const tableArchivedList = [
    {
        name: 'Kode Item',
        value: 'kode_item',
        width: 200,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Trade Name',
        value: 'trade_name',
        width: 352,
        children: ['-'],
        component: ({ value }) => (
            <Tooltip
                label={value}
                fontStyle={'normal'}
                fontWeight={'400'}
                fontSize={'12px'}
                lineHeight={'18px'}
                textAlign={'left'}
                color="white"
            >
                <Text className="column-text text-ellipsis">{value || '-'}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Supplier',
        value: 'supplier',
        width: 300,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['name'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {value[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Kode Supplier',
        value: 'supplier',
        width: 60,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['supplier_ref'].map((item, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {value[item] || '-'}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Catatan',
        width: 60,
        value: 'notes',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Action',
        width: 300,
        value: null,
        children: ['-'],
        component: ({ handleClickRestore, handleClickNote }) => (
            <Flex gap="14px">
                <Box h="max-content" cursor="pointer" rounded={'4px'} onClick={handleClickNote}>
                    <NoteIcon />
                </Box>
                <Box h="max-content" cursor="pointer" rounded={'4px'} onClick={handleClickRestore}>
                    <RestoreIcon />
                </Box>
            </Flex>
        ),
    },
];

export const dummyDataArchivedList = {
    kode_item: '22134432e',
    trade_name: 'Plug Crystallure SR Rich Cream 50 g Wardah...',
    supplier: 'Metalipark Kreasindo, PT',
    kode_supplier: '2200054',
    catatan: 'Kurang bahan baku.....',
};
