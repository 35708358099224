import { routeList, routeListPhase2, routeListUserMangement } from '@routers/const';
import Cookies from 'universal-cookie';

export const isActivePageHandler = ({ link, isActive }) => {
    return isActive === link;
};

export const changeNavigationHandler = ({ navigate, link }) => {
    navigate(link);
};

export const onLogoutHandler = ({ dispatch, toggleLoginHandler, navigate }) => {
    localStorage.removeItem('userToken');
    dispatch(toggleLoginHandler(false));
    navigate('/login');
};

export const sideMenuList = () => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    let arr = routeList();

    if (appType === 'warehouseToSupplier') {    
        arr = routeListPhase2();
    }
    if (appType === 'userManagement') {
        arr = routeListUserMangement;
    }

    return arr?.filter((item) => item?.isShowInSideMenu);
};

export const onLogout = ({
    dispatch,
    onChangeSelectedMaterial,
    onChangeSupplierType,
    onChangeSupplierRef,
    toggleLoginHandler,
    navigate,
}) => {
    const cookies = new Cookies();
    const cookieList = cookies.getAll();
    try {
        for (const key in cookieList) {
            cookies.remove(key, { path: '/' });
        }
        dispatch(onChangeSelectedMaterial([]));
        dispatch(onChangeSupplierType([]));
        dispatch(onChangeSupplierRef([]));
        dispatch(toggleLoginHandler(false));
        navigate('/login');
    } catch (error) {
        console.log(error);
    }
};
