import { round } from 'lodash';

export const handleChange = ({ valueOnchange, setItemEdited, selectedItem, toast }) => {
    const { id, konfirmasi_pcs, konfirmasi_kanban } = selectedItem || {};
    if (Number(valueOnchange) < 0) {
        toast({
            description: 'Konfirmasi Pcs tidak boleh kurang dari 0',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
        return;
    }
    setItemEdited((prev) => {
        let clone = [...prev];
        const hasDuplicateItem = clone.find((item) => item?.id === id);
        if (Boolean(hasDuplicateItem)) {
            clone.map((item) => {
                if (item?.id === id) {
                    item.konfirmasi_pcs = valueOnchange;
                    item.konfirmasi_kanban = round(Number(valueOnchange) / Number(item.qty_per_kanban), 1);
                }
                return item;
            });
        } else {
            clone.push({
                ...selectedItem,
                konfirmasi_pcs: valueOnchange,
                konfirmasi_kanban: round(Number(valueOnchange) / Number(selectedItem.qty_per_kanban), 1),
                prev_konfirmasi_pcs: konfirmasi_pcs,
                prev_konfirmasi_kanban: konfirmasi_kanban,
            });
        }
        return clone;
    });
};
