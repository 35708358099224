import React, { useContext, useEffect, useState, useRef } from 'react';
import { isEmpty } from 'lodash';
import ScreenTemplate from '@components/screenTemplateV2';
import { ArrowLeftIcon, ArrowRightIcon } from '@asset/icons';
import { skuConfigurationContext } from '@pages/skuConfiguration';
import { formDefaultValue, jenisList, listItemCriticalPoint } from './const';
import {
    getMpq,
    getOddoCode,
    getStreamline,
    onChangeFormData,
    onSubmitForm,
    mappedSelectedSku,
    getProductMpqCO,
    getProductMpqCP,
} from './function';

import InputGroups from '../inputGroup';
import { ChevronRightIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
    Box,
    Flex,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Button,
    useToast,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react';

import './styles.scss';
import Modalsuccess from '@components/modalSuccess';

const CreateAndEditModal = ({ isOpen, isEditModal, reFecthSkuHandler, selectedSku }) => {
    const toast = useToast();
    const listRef = useRef(null);
    const { onChangeModalState } = useContext(skuConfigurationContext);
    // oddo code input
    const [formData, setFormData] = useState(formDefaultValue);
    const [idxAccordion, setIdxAccordion] = useState([]);
    const [streamline, setStreamline] = useState([]);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isGenerateLoading, setIsGenerateLoading] = useState(false);
    const [isSuccessSubmit, setIsSuccessSubmit] = useState(false);
    const [isSuccessSelectStorage, setSuccessSelectStorage] = useState(false);
    const [isSumPcsCO, setIsSumPCSCoLoading] = useState(false);
    const [isSumPcsCP, setIsSumPCSCPLoading] = useState({
        idx: null,
        isLoading: false,
    });

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [accordionIsShow, setAccordionIsShow] = useState(true);

    const onClose = () => onChangeModalState({ value: null });

    const onSetShowAccordion = () => {
        let nums = null;
        if (isEditModal) nums = [0, 1, 2, 3, 4];
        if (!formData.kode_oddo) nums = [];
        if (formData.kode_oddo && !accordionIsShow) nums = [0, 1, 2, 3, 4];
        setIdxAccordion(nums);
    };

    useEffect(() => {
        onSetShowAccordion();
    }, [accordionIsShow, formData.kode_oddo]);

    useEffect(() => {
        getStreamline({ setStreamline });
    }, []);

    useEffect(() => {
        if (isEditModal) {
            mappedSelectedSku({ setFormData, selectedSku });
            setAccordionIsShow(false);
            setSuccessSelectStorage(true);
            // setButtonIsShow(true);
        } else {
            setFormData(formDefaultValue);
            setAccordionIsShow(true);
            // setButtonIsShow(false);
        }
    }, [isEditModal, selectedSku]);

    const onCloseSuccessSubmit = () => {
        setIsSuccessSubmit(false);
        onClose();
    };

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        onChangeFormData({ type: name, value, setFormData, formData });
    };

    const generateCodeItemHandler = () => {
        getOddoCode({
            codeItem: formData.kode_item,
            toast,
            setFormData,
            setIsGenerateLoading,
            // setButtonIsShow,
            setAccordionIsShow,
        });
    };
    const onResetForm = () => {
        setFormData(formDefaultValue);
        setIsSubmitted(false);
        setSuccessSelectStorage(false);
    };

    const onSubmitHandler = () => {
        onSubmitForm({
            selectedSku,
            toast,
            formData,
            onClose,
            setIsSubmitLoading,
            reFecthSkuHandler,
            isEditModal,
            setIsSubmitted,
            onResetForm,
            setIsSuccessSubmit,
        });
    };

    const onUpdateCOPcs = () => {
        const data = {
            odooCode: formData.kode_oddo,
            includeCO: true,
            jenisStorage: formData.jenis_storage_ndc,
            pcs: formData.pcs_co,
        };
        getProductMpqCO({ toast, data, setIsSumPCSCoLoading, setFormData });
    };

    const onUpdateCPPcs = ({ idx, keyName }) => {
        const data = {
            payload: {},
            safetyPcs: formData.cp_safety_pcs,
            ropPcs: formData.cp_rop_pcs,
            maxPcs: formData.cp_max_pcs,
            jenisStorage: formData.jenis_storage_ndc,
            odooCode: formData.kode_oddo,
        };

        if (keyName === 'Safety') Object.assign(data.payload, { safetyPcs: Number(data.safetyPcs) });
        else if (keyName === 'Reorder Point') Object.assign(data.payload, { ropPcs: Number(data.ropPcs) });
        else if (keyName === 'Maximum') Object.assign(data.payload, { maxPcs: Number(data.maxPcs) });

        getProductMpqCP({ toast, data, setIsSumPCSCPLoading, setFormData, idx, keyName });
    };

    const onExpandAccordion = ({ idx, isExpanded }) => {
        if (isExpanded) setIdxAccordion(idxAccordion.filter((i) => i !== idx));
        else setIdxAccordion([...idxAccordion, idx]);
    };

    const ButtonSubmit = () => {
        return (
            <Box className="wrapper-button">
                <Button
                    className="btn-back_to_top"
                    onClick={() => {
                        listRef.current.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    Back to top
                </Button>

                <Button
                    border={isSubmitLoading ? '1px solid grey' : 'none'}
                    isLoading={isSubmitLoading}
                    className="btn-save__item"
                    onClick={onSubmitHandler}
                >
                    Save Item
                </Button>
            </Box>
        );
    };

    return (
        <ScreenTemplate isOpen={isOpen}>
            <Box p="20px">
                <Box ref={listRef} className={`sku-management__container ${isOpen && 'modal-actived__sku-management'}`}>
                    <Box className="create-and-edit-modal_container_sku">
                        <Flex className="section-head__container">
                            <Flex onClick={onClose} cursor="pointer" alignItems="center">
                                <ArrowLeftIcon />
                                <Text className="arrow-back__text">Kembali</Text>
                            </Flex>
                            <Text className="head-title__container">
                                {isEditModal ? 'Edit SKU' : 'Add Item Manualy'}
                            </Text>
                        </Flex>
                        <Box className="section-body__container">
                            {/* KODE ODDO */}
                            <Box className="section-body-oddo">
                                <Box className="input-wrapper-oddo">
                                    <Box className="edit-wrapper__input">
                                        <Text className="edit-label__input">
                                            Kode Item<span>*</span>
                                        </Text>
                                        <Box>
                                            <input
                                                disabled={isEditModal}
                                                value={formData.kode_item}
                                                name="kode_item"
                                                className="edit-input_disable"
                                                onChange={onChangeHandler}
                                            />

                                            {isSubmitted && isEmpty(formData.kode_item) && (
                                                <Text className="required-field__text">*Masukkan Kode Item</Text>
                                            )}

                                            {!isEditModal && (
                                                <Button
                                                    isDisabled={!formData.kode_item}
                                                    border={isGenerateLoading ? '1px solid grey' : 'none'}
                                                    onClick={generateCodeItemHandler}
                                                    isLoading={isGenerateLoading}
                                                    className={
                                                        !formData.kode_item ? 'button-oddo-disable' : 'button-oddo'
                                                    }
                                                >
                                                    Generate
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            {!accordionIsShow && (
                                <>
                                    <Box className="edit-wrapper__input">
                                        <Text className="edit-label__input">
                                            Kode SL
                                            <Text as="span" color="red">
                                                *
                                            </Text>
                                        </Text>
                                        <Box flex={1}>
                                            <input
                                                value={formData.kode_sl}
                                                disabled={isEditModal}
                                                className={isEditModal ? 'edit-input_disable' : 'edit-input'}
                                                name="kode_sl"
                                                onChange={onChangeHandler}
                                            />
                                            {isSubmitted && isEmpty(formData.kode_sl) && (
                                                <Text className="required-field__text">*Masukkan Kode SL</Text>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className="edit-wrapper__input">
                                        <Box flex={1}>
                                            <Text className="edit-label__input">Oddo Code</Text>
                                            <input
                                                className="edit-input_disable"
                                                name="kode_oddo"
                                                value={formData.kode_oddo}
                                                defaultValue={selectedSku?.kode_oddo}
                                                placeholder="Masukan Kode ODDO"
                                                onChange={onChangeHandler}
                                                disabled={true}
                                            />
                                            {isSubmitted && isEmpty(formData.kode_oddo) && (
                                                <Text className="required-field__text">*Masukkan Kode ODOO</Text>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className="edit-wrapper__input">
                                        <Text className="edit-label__input">
                                            Nama Item
                                            <Text as="span" color="red">
                                                *
                                            </Text>
                                        </Text>
                                        <Box flex={1}>
                                            <input value={formData.name} className="edit-input_disable" disabled />
                                            {isSubmitted && isEmpty(formData.name) && (
                                                <Text className="required-field__text">*Masukkan Nama Item</Text>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box className="edit-wrapper__input">
                                        <Menu flex={1}>
                                            <Text className="edit-label__input">
                                                Dedicated Streamline
                                                <Text as="span" color="red">
                                                    *
                                                </Text>
                                            </Text>
                                            <Box className="edit-input__wrapper">
                                                <MenuButton
                                                    className="menu-button"
                                                    as={Button}
                                                    rightIcon={<ArrowRightIcon />}
                                                >
                                                    {formData.streamline?.name || 'Pilih Streamline'}
                                                </MenuButton>
                                                {isSubmitted && isEmpty(formData.streamline?.name) && (
                                                    <Text className="required-field__text">
                                                        *Pilih decidated streamline
                                                    </Text>
                                                )}
                                            </Box>

                                            <MenuList>
                                                {streamline.map((item, i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            name
                                                            onClick={() => {
                                                                onChangeFormData({
                                                                    type: 'streamline',
                                                                    value: item,
                                                                    setFormData,
                                                                    formData: formData,
                                                                });
                                                            }}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </MenuList>
                                        </Menu>
                                    </Box>
                                    <Box className="edit-wrapper__input">
                                        <Menu flex={1}>
                                            <Text className="edit-label__input">
                                                Jenis Storage
                                                <Text as="span" color="red">
                                                    *
                                                </Text>
                                            </Text>
                                            <Box className="edit-input__wrapper">
                                                <MenuButton
                                                    className="menu-button"
                                                    as={Button}
                                                    rightIcon={<ArrowRightIcon />}
                                                >
                                                    {formData.jenis_storage_ndc || 'Pilih Jenis Storage'}
                                                </MenuButton>
                                                {isSubmitted && isEmpty(formData.jenis_storage_ndc) && (
                                                    <Text className="required-field__text">*Pilih jenis storage</Text>
                                                )}
                                            </Box>
                                            <MenuList>
                                                {jenisList.map((item, i) => {
                                                    return (
                                                        <MenuItem
                                                            key={i}
                                                            onClick={() => {
                                                                if (!isEditModal && !formData.kode_oddo.length) {
                                                                    return toast({
                                                                        position: 'top-center',
                                                                        description: 'generate oddo code before',
                                                                        status: 'error',
                                                                        duration: 1000,
                                                                    });
                                                                }
                                                                onChangeFormData({
                                                                    type: 'jenis_storage_ndc',
                                                                    value: item.name,
                                                                    setFormData,
                                                                    formData: formData,
                                                                });
                                                                getMpq({
                                                                    id: formData.kode_oddo,
                                                                    jenisStorage: item.name,
                                                                    formData,
                                                                    includeCO: false,
                                                                    pcs_co: null,
                                                                    setFormData,
                                                                    setIdxAccordion,
                                                                    toast,
                                                                    setSuccessSelectStorage,
                                                                    onSetShowAccordion,
                                                                });
                                                            }}
                                                        >
                                                            {item.name}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </MenuList>
                                        </Menu>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </Box>

                    {/* ACCORDION SECTION */}

                    <Accordion allowMultiple index={idxAccordion}>
                        {/* KEMAS */}
                        <AccordionItem
                            isDisabled={isEditModal ? false : accordionIsShow}
                            className="accordion-container"
                        >
                            {({ isExpanded }) => (
                                <Box className="accordion-wrapper-child__container">
                                    <h2>
                                        <AccordionButton
                                            onClick={() => {
                                                onExpandAccordion({ idx: 0, isExpanded });
                                            }}
                                            className="wrapper-title-accordion"
                                        >
                                            <Box
                                                as="span"
                                                className={isExpanded ? 'accordion-title_active ' : 'accordion-title'}
                                            >
                                                <Text textTransform="uppercase">
                                                    minimum production quantity (mpq kemas)
                                                </Text>
                                                {isExpanded ? (
                                                    <ChevronDownIcon className="accordion-icon_active" />
                                                ) : (
                                                    <ChevronRightIcon className="accordion-icon" />
                                                )}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p={0} pb={4}>
                                        <Box className="accordion-panel-wrapper__container">
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Pcs</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        value={formData.mpq_kemas}
                                                        name="mpq_kemas"
                                                        disabled={isEditModal}
                                                        className="edit-input_disable"
                                                        text="pcs"
                                                        onChange={onChangeHandler}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Pcs Per Koli</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.pcs_per_koli}
                                                        type="text"
                                                        name="pcs_pertroli_mpq"
                                                        disabled
                                                        className="edit-input_disable"
                                                        text="pcs per koli"
                                                        onChange={onChangeHandler}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Koli</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        name="troli_mpq"
                                                        value={formData.koli}
                                                        disabled
                                                        className="edit-input_disable"
                                                        text="koli"
                                                        onChange={onChangeHandler}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Pcs Perstorage NDC</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.koli_per_storage}
                                                        type="text"
                                                        name="troli_perstrorange_ndc"
                                                        disabled
                                                        className="edit-input_disable"
                                                        text="Pcs per storage NDC"
                                                        onChange={onChangeHandler}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Storage NDC</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.storage_ndc}
                                                        type="text"
                                                        name="storage_ndc"
                                                        disabled
                                                        className="edit-input_disable"
                                                        text={formData.jenis_storage_ndc}
                                                        onChange={onChangeHandler}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </Box>
                            )}
                        </AccordionItem>

                        {/* CO */}
                        <AccordionItem
                            isDisabled={isEditModal ? false : accordionIsShow}
                            className="accordion-container"
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton
                                            className="wrapper-title-accordion"
                                            onClick={() => {
                                                onExpandAccordion({ idx: 1, isExpanded });
                                            }}
                                        >
                                            <Box
                                                as="span"
                                                className={isExpanded ? 'accordion-title_active ' : 'accordion-title'}
                                            >
                                                <Text textTransform="uppercase">
                                                    weekly customer order (co mingguan)
                                                </Text>
                                                {isExpanded ? (
                                                    <ChevronDownIcon className="accordion-icon_active" />
                                                ) : (
                                                    <ChevronRightIcon className="accordion-icon" />
                                                )}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p={0} pb={4}>
                                        <Box className="accordion-wrapper-child__container">
                                            <Box className="wrapper-co">
                                                <Box className="accordion-wrapper__input">
                                                    <Box className="accordion-wrapper-label">
                                                        <Text className="accordion-label__input">
                                                            Pcs
                                                            <Text as="span" color="red">
                                                                *
                                                            </Text>
                                                        </Text>
                                                    </Box>
                                                    <Box className="accordion-container-input">
                                                        <InputGroups
                                                            disable={false}
                                                            type="text"
                                                            name="pcs_co"
                                                            onChange={onChangeHandler}
                                                            value={formData.pcs_co}
                                                            className="accordion-input"
                                                            text="pcs"
                                                        />
                                                        {isSubmitted && !formData.pcs_co && (
                                                            <Text className="required-field__text">*Masukkan pcs</Text>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Button
                                                    className="button-co"
                                                    border={isSumPcsCO ? '1px solid grey' : 'none'}
                                                    onClick={onUpdateCOPcs}
                                                    isLoading={isSumPcsCO}
                                                >
                                                    Hitung
                                                </Button>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Koli</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        name="koli_co"
                                                        onChange={onChangeHandler}
                                                        disabled
                                                        className="edit-input_disable"
                                                        type="text"
                                                        value={formData.koli_co}
                                                        text="koli"
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Storage NDC</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        name="ndc_co"
                                                        onChange={onChangeHandler}
                                                        type="text"
                                                        text={formData.jenis_storage_ndc}
                                                        disabled
                                                        className="edit-input_disable"
                                                        value={formData.storage_co}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>

                        {/* STOK NASIONAL */}
                        <AccordionItem
                            isDisabled={isEditModal ? false : accordionIsShow}
                            className="accordion-container"
                        >
                            {({ isExpanded }) => (
                                <Box className="accordion-wrapper-child__container">
                                    <h2>
                                        <AccordionButton
                                            className="wrapper-title-accordion"
                                            onClick={() => {
                                                onExpandAccordion({ idx: 2, isExpanded });
                                            }}
                                        >
                                            <Box
                                                as="span"
                                                className={isExpanded ? 'accordion-title_active ' : 'accordion-title'}
                                            >
                                                <Text textTransform="uppercase">Stock Nasional</Text>
                                                {isExpanded ? (
                                                    <ChevronDownIcon className="accordion-icon_active" />
                                                ) : (
                                                    <ChevronRightIcon className="accordion-icon" />
                                                )}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p={0} pb={4}>
                                        <Box className="accordion-panel-wrapper__container">
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Standard Stock Nasional
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        disable={false}
                                                        onChange={onChangeHandler}
                                                        value={formData.stok_nasional}
                                                        name="stok_nasional"
                                                        className="accordion-input"
                                                        text="pcs"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </Box>
                            )}
                        </AccordionItem>

                        {/* CRITICAL POINT */}
                        <AccordionItem
                            isDisabled={isEditModal ? false : accordionIsShow}
                            className="accordion-container"
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton
                                            onClick={() => {
                                                onExpandAccordion({ idx: 3, isExpanded });
                                            }}
                                            className="wrapper-title-accordion"
                                        >
                                            <Box
                                                as="span"
                                                className={isExpanded ? 'accordion-title_active ' : 'accordion-title'}
                                            >
                                                <Text textTransform="uppercase">critical point</Text>
                                                {isExpanded ? (
                                                    <ChevronDownIcon className="accordion-icon_active" />
                                                ) : (
                                                    <ChevronRightIcon className="accordion-icon" />
                                                )}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p={0}>
                                        <Box className="accordion-wrapper-child__container">
                                            {listItemCriticalPoint.map((i, idx) => (
                                                <Box key={idx}>
                                                    <Text
                                                        className="accordion-label-title__input"
                                                        mb="30px"
                                                        mt={idx !== 0 && '30px'}
                                                    >
                                                        {i.title}
                                                    </Text>
                                                    {i.listKey.map((v, id) => {
                                                        const names =
                                                            id === 0
                                                                ? 'Pcs'
                                                                : id === 1
                                                                ? 'Koli'
                                                                : id === 2
                                                                ? 'Storage NDC'
                                                                : '';

                                                        return (
                                                            <Flex key={id}>
                                                                <Box className="accordion-wrapper__input">
                                                                    <Box className="accordion-wrapper-label">
                                                                        <Text className="accordion-label__input">
                                                                            {names}
                                                                            {id === 0 && (
                                                                                <Text as="span" color="red">
                                                                                    *
                                                                                </Text>
                                                                            )}
                                                                        </Text>
                                                                    </Box>
                                                                    <Box className="accordion-container-input">
                                                                        <InputGroups
                                                                            type="text"
                                                                            name={v}
                                                                            onChange={onChangeHandler}
                                                                            value={formData[v]}
                                                                            className={
                                                                                id === 0
                                                                                    ? 'accordion-input'
                                                                                    : 'edit-input_disable'
                                                                            }
                                                                            text={names}
                                                                            rightText={
                                                                                id === 2
                                                                                    ? formData.jenis_storage_ndc
                                                                                    : names
                                                                            }
                                                                        />
                                                                        {id === 0 && (
                                                                            <>
                                                                                {isSubmitted && !formData[v] && (
                                                                                    <Text className="required-field__text">
                                                                                        *Masukkan {names}
                                                                                    </Text>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Box>
                                                                </Box>
                                                                {id === 0 && (
                                                                    <Button
                                                                        className="button-co"
                                                                        border={
                                                                            isSumPcsCP.idx === idx &&
                                                                            isSumPcsCP.isLoading
                                                                                ? '1px solid grey'
                                                                                : 'none'
                                                                        }
                                                                        onClick={() =>
                                                                            onUpdateCPPcs({ idx, keyName: i.title })
                                                                        }
                                                                        isLoading={
                                                                            isSumPcsCP.idx === idx &&
                                                                            isSumPcsCP.isLoading
                                                                        }
                                                                        mt={'25px'}
                                                                    >
                                                                        Hitung
                                                                    </Button>
                                                                )}
                                                            </Flex>
                                                        );
                                                    })}
                                                </Box>
                                            ))}
                                        </Box>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>

                        {/* LEADTIME */}
                        <AccordionItem
                            isDisabled={isEditModal ? false : accordionIsShow}
                            className="accordion-container"
                        >
                            {({ isExpanded }) => (
                                <>
                                    <h2>
                                        <AccordionButton
                                            onClick={() => {
                                                onExpandAccordion({ idx: 4, isExpanded });
                                            }}
                                            className="wrapper-title-accordion"
                                        >
                                            <Box
                                                as="span"
                                                className={isExpanded ? 'accordion-title_active ' : 'accordion-title'}
                                            >
                                                <Text textTransform="uppercase">leadtime (jam)</Text>
                                                {isExpanded ? (
                                                    <ChevronDownIcon className="accordion-icon_active" />
                                                ) : (
                                                    <ChevronRightIcon className="accordion-icon" />
                                                )}
                                            </Box>
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel p={0} pb={4}>
                                        <Box className="accordion-wrapper-child__container">
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Timbang
                                                        <Text as="span" color="red">
                                                            *
                                                        </Text>
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.timbang_leadtime}
                                                        type="text"
                                                        className="edit-input_disable"
                                                        name="timbang_leadtime"
                                                        text="jam"
                                                        onChange={onChangeHandler}
                                                    />
                                                    {isSubmitted && isEmpty(formData.timbang_leadtime) && (
                                                        <Text className="required-field__text">*Masukkan timbang</Text>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Premix
                                                        <Text as="span" color="red">
                                                            *
                                                        </Text>
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.premix_leadtime}
                                                        type="text"
                                                        className="edit-input_disable"
                                                        name="premix_leadtime"
                                                        onChange={onChangeHandler}
                                                        text="jam"
                                                    />
                                                    {isSubmitted && isEmpty(formData.premix_leadtime) && (
                                                        <Text className="required-field__text">*Masukkan premix</Text>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Olah 1
                                                        <Text as="span" color="red">
                                                            *
                                                        </Text>
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        value={formData.olah_1_leadtime}
                                                        type="text"
                                                        className="accordion-input"
                                                        name="olah_1_leadtime"
                                                        onChange={onChangeHandler}
                                                        text="jam"
                                                        disable={false}
                                                    />
                                                    {isSubmitted && isEmpty(formData.olah_1_leadtime) && (
                                                        <Text className="required-field__text">*Masukkan olah 1</Text>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Olah 2
                                                        <Text as="span" color="red">
                                                            *
                                                        </Text>
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        value={formData.olah_2_leadtime}
                                                        className="accordion-input"
                                                        name="olah_2_leadtime"
                                                        onChange={onChangeHandler}
                                                        text="jam"
                                                        disable={false}
                                                    />
                                                    {isSubmitted && isEmpty(formData.olah_2_leadtime) && (
                                                        <Text className="required-field__text">*Masukkan olah 2</Text>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">
                                                        Kemas
                                                        <Text as="span" color="red">
                                                            *
                                                        </Text>
                                                    </Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        className="accordion-input"
                                                        value={formData.kemas_leadtime}
                                                        name="kemas_leadtime"
                                                        onChange={onChangeHandler}
                                                        text="jam"
                                                        disable={false}
                                                    />
                                                    {isSubmitted && isEmpty(formData.kemas_leadtime) && (
                                                        <Text className="required-field__text">*Masukkan kemas</Text>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Lain-lain</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        type="text"
                                                        className="accordion-input"
                                                        value={formData.lain_lain_leadtime}
                                                        name="lain_lain_leadtime"
                                                        onChange={onChangeHandler}
                                                        text="jam"
                                                        disable={false}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box className="accordion-wrapper__input">
                                                <Box className="accordion-wrapper-label">
                                                    <Text className="accordion-label__input">Total</Text>
                                                </Box>
                                                <Box className="accordion-container-input">
                                                    <InputGroups
                                                        disabled
                                                        type="text"
                                                        name="total_leadtime"
                                                        value={formData.total_leadtime}
                                                        onChange={onChangeHandler}
                                                        className="edit-input_disable"
                                                        text="jam"
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    </Accordion>

                    {/* END ACCORDION SECTION */}

                    {idxAccordion?.length > 0 && isSuccessSelectStorage && <ButtonSubmit />}
                </Box>
            </Box>

            <Modalsuccess
                isOpen={isSuccessSubmit}
                onClose={onCloseSuccessSubmit}
                title={`Sukses ${isEditModal ? 'Edit' : 'Membuat'} Sku`}
                description={`Sku berhasil ${
                    isEditModal ? 'diedit' : 'dibuat'
                }, kembali ke table untuk melihat dan mengatur sku`}
            />
        </ScreenTemplate>
    );
};

export default CreateAndEditModal;
