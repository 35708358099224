import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onSubmit = async ({
    currentWeek,
    currentYear,
    value,
    isAdditional,
    updatedValue,
    setIsLoading,
    setHasUpdated,
    demand_anticipation_id,
    id,
    toast,
    setUpdatedValue,
    setIsFocus,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    let data = {
        demand_per_weeks: [
            {
                week_n: Number(currentWeek),
                year: Number(currentYear),
                demand: Number(value),
            },
        ],
    };
    if (isAdditional) data = { additional_order: Number(value) };
    try {
        if (value !== updatedValue) {
            setIsLoading(true);
            setHasUpdated(false);

            await Axios({
                method: 'PATCH',
                url: `/demand-anticipation/${demand_anticipation_id}`,
                headers: { Authorization: 'Bearer ' + userToken },
                data: { ...data, material_config_id: id },
            }).then(() => {
                toast({
                    position: 'center-top',
                    description: 'Berhasil Meng-Update Data',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            });
            setUpdatedValue(value);
            setHasUpdated(true);
        }
    } catch (error) {
        toast({
            title: 'Error',
            description:
                error?.response?.data?.errors?.map((item) => item?.message)?.join() ||
                error?.response?.data?.errors?.join() ||
                'Failed',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
    } finally {
        setIsLoading(false);
        setIsFocus(false);
    }
};

export const onClickOutside = ({
    currentWeek,
    currentYear,
    value,
    isAdditional,
    updatedValue,
    setIsLoading,
    setHasUpdated,
    demand_anticipation_id,
    id,
    toast,
    setUpdatedValue,
    setIsFocus,
    isFocus,
}) => {
    if (isFocus && value !== updatedValue) {
        onSubmit({
            currentWeek,
            currentYear,
            value,
            isAdditional,
            updatedValue,
            setIsLoading,
            setHasUpdated,
            demand_anticipation_id,
            id,
            toast,
            setUpdatedValue,
            setIsFocus,
        });
    }

    if (isFocus) {
        setIsFocus(false);
    }
};
