import React from 'react';
import { TableOrderConfirmationContext } from '../..';
import { Box, Tooltip } from '@chakra-ui/react';

import './styles.scss';
import DatePicker from 'react-date-picker';
import { CalendarIcon } from '@pagesPhase2/orderManagement/sections/sectionOrderDelivery/icons';
import { onChangeInputDate } from './function';

function Index({ item, initialDateValue, errorHighlight = { has_eta_error: null } }) {
    const { isEditColumn, setItemEdited } = React.useContext(TableOrderConfirmationContext);
    return (
        <Tooltip hasArrow bg="red.500" label={errorHighlight?.has_eta_error}>
            <Box
                className={`container-edit-table-date-picker-order-confirmation ${
                    errorHighlight?.has_eta_error && 'error-eta__container'
                }`}
            >
                {isEditColumn ? (
                    <>
                        <Box className="input-date-eta__container">
                            <Box className="calender_eta">
                                <DatePicker
                                    value={initialDateValue}
                                    onChange={(selectDate) =>
                                        onChangeInputDate({
                                            selectDate,
                                            selectedItem: item,
                                            setItemEdited,
                                        })
                                    }
                                    className="date-picker-content-order-confirmation"
                                    openCalendarOnFocus={false}
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon />}
                                    calendarClassName="calendar-first"
                                    format="yyyy-MM-dd"
                                    dayPlaceholder="ETA"
                                    monthPlaceholder="tanggal"
                                    yearPlaceholder="Input"
                                    minDate={new Date()}
                                />
                            </Box>
                        </Box>
                    </>
                ) : null}
            </Box>
        </Tooltip>
    );
}

export default Index;
