import { IconMinus, IconMinusDisabled } from './icons';

export const operatorList = ['Between', 'Equal', 'More Than', 'Less Than', 'More Than Equal', 'Less Than Equal'];
export const initialForm = [
    {
        type: 'single',
        width: '100%',
        name: 'label_status',
        title: 'Status',
        option: [],
        placeholder: 'pilih status',
    },
    {
        type: 'single',
        width: '170px',
        name: 'kanban_card',
        title: 'Kanban Card',
        option: [
            {
                name: 'Between',
                operator: 'between',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                operator: 'eq',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                operator: 'gt',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                operator: 'lt',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                operator: 'gte',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                operator: 'lte',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
    {
        type: 'single',
        width: '170px',
        name: 'order_card',
        title: 'Order Card',
        option: [
            {
                name: 'Between',
                operator: 'between',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinus />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                    },
                ],
            },
            {
                name: 'Equal',
                operator: 'eq',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than',
                operator: 'gt',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than',
                operator: 'lt',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'More Than Equal',
                operator: 'gte',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
            {
                name: 'Less Than Equal',
                operator: 'lte',
                option: [
                    {
                        name: 'from',
                        type: 'input',
                        width: '95px',
                    },
                    {
                        name: 'start',
                        type: <IconMinusDisabled />,
                    },
                    {
                        name: 'to',
                        type: 'input',
                        width: '95px',
                        disabled: true,
                    },
                ],
            },
        ],
        placeholder: 'Pilih satuan',
    },
];
