import { useState } from 'react';
import { Box, Text, Flex } from '@chakra-ui/react';
import Cookies from 'universal-cookie';
import { onChangeSupplierType, onChangeSelectedMaterial, onChangeSupplierRef } from '@store/list/globalFilter';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toggleLoginHandler } from '@store/list/login';

import { ChevronRightIcon, UserIcon, Logo, ExpandLeftIcon, ChevronLeftIcon, RedLogo } from './icons';
import './styles.scss';
import { onLogout, sideMenuList } from './functions';

const SideMenu = ({ togglerHideLeftSide }) => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [page, setPage] = useState('/');

    useEffect(() => {
        const { pathname } = location;
        setPage(pathname);
    }, [location]);

    return (
        <Box className={`side-menu__wrapper`}>
            <Box className="side-menu__container">
                <Box className="section-head__side-menu">
                    {appType === 'warehouseToSupplier' && <RedLogo />}
                    {appType === 'ndcToManufacture' && <Logo />}
                    <Box className="icon-expand__container" onClick={togglerHideLeftSide}>
                        <ExpandLeftIcon />
                    </Box>
                </Box>
                <Box className="section-body__side-menu">
                    {sideMenuList()?.map(({ icon, link, name }) => (
                        <Flex
                            key={link}
                            onClick={() => navigate(link)}
                            className={`section-body-item__side-menu ${
                                page === link && 'section-body-item__side-menu__active'
                            }`}
                        >
                            <Box>{icon({ isActive: page === link })}</Box>
                            <Text className="section-body-item__text">{name}</Text>
                        </Flex>
                    ))}

                    <Box className="section-footer__side-menu">
                        <Flex
                            className="username-container"
                            onClick={() =>
                                onLogout({
                                    dispatch,
                                    onChangeSelectedMaterial,
                                    onChangeSupplierType,
                                    onChangeSupplierRef,
                                    toggleLoginHandler,
                                    navigate,
                                })
                            }
                            alignItems="center"
                        >
                            <UserIcon />
                            <Text className="username-text__global-header">Sign Out </Text>
                            <ChevronRightIcon />
                        </Flex>
                    </Box>

                    <Flex className="back-to-main-container" onClick={() => navigate('/lobby')} alignItems="center">
                        <ChevronLeftIcon />
                        <Text className="back-to-main-text">Back to Main</Text>
                    </Flex>
                </Box>
            </Box>
        </Box>
    );
};

export default SideMenu;
