import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FilterIcon } from './icons';
import ModalMultiple from './modal';
import { handleClickMenu, selectedFilter, handleCloseModal, findBadgeSelectedFilter } from './function';
import { initialValue } from './const';
import { OrderManagementContext } from '@pagesPhase2/orderManagement';

import './styles.scss';

function Index() {
    const { setFilter } = React.useContext(OrderManagementContext);
    const [openModal, setOpenModal] = React.useState(false);
    const [formData, setFormData] = React.useState(initialValue);
    const [filterSelected, setFilterSelected] = React.useState([]);
    const handleApplyFilter = () => {
        selectedFilter({ value: formData, setFilterSelected });
        setOpenModal(false);
        const operatorOrder = formData?.order_card_extra?.operator;
        const fromOrder = formData?.order_card_extra?.from;
        const operatorKanban = formData?.kanban_card_extra?.operator;
        const fromKanban = formData?.kanban_card_extra?.from;
        setFilter((prev) => ({
            ...prev,
            status_id: formData?.status_id,
            order_card: Boolean(formData?.order_card_extra)
                ? operatorOrder?.includes('between')
                    ? `${operatorOrder}:${fromOrder},${formData?.order_card_extra?.to}`
                    : `${operatorOrder}:${fromOrder}`
                : '',
            kanban_card: Boolean(formData?.kanban_card_extra)
                ? operatorKanban?.includes('between')
                    ? `${operatorKanban}:${fromKanban},${formData?.kanban_card_extra?.to}`
                    : `${operatorKanban}:${fromKanban}`
                : '',
        }));
    };
    const handleResetFilter = () => {
        setFormData(initialValue);
    };
    const handleChangeExtraInput = ({ extra, input, form, field }) => {
        handleClickMenu({ setFormData, item: { extra, input }, selected: { form, field } });
    };
    const badgeFilterSelected = findBadgeSelectedFilter(filterSelected);
    return (
        <Box className="multiple-filter-order-management">
            <ModalMultiple
                onClick={({ item, selected }) => handleClickMenu({ setFormData, item, selected })}
                outlineLabel="Reset Filter"
                onClose={() => handleCloseModal({ formData, filterSelected, setFormData, initialValue, setOpenModal })}
                isOpen={openModal}
                handleApplyFilter={handleApplyFilter}
                value={formData}
                handleResetFilter={handleResetFilter}
                handleChangeExtraInput={handleChangeExtraInput}
            />
            <Box
                onClick={() => setOpenModal(true)}
                className={`multiple-icon-filter ${badgeFilterSelected?.length > 0 && 'has-filter-multiple-selected'}`}
            >
                <FilterIcon color={badgeFilterSelected?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                {badgeFilterSelected?.length > 0 && (
                    <Text className="btn-filter-multiple-badge">{badgeFilterSelected?.length}</Text>
                )}
            </Box>
        </Box>
    );
}

export default Index;
