import Axios from '@services/axios';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const handleFormatSetComponent = ({ item, id, index, formData, setFormData }) => {
    let data = [...formData.field_set_components];
    data[index]['id_dummy'] = id;
    data[index]['set_component_id'] = item?.id;
    data[index]['set_component_kode_item'] = item?.kode_item;
    data[index]['set_component_internal_name'] = item?.internal_name;
    setFormData({ ...formData, field_set_components: data });
};

export const onResetHandler = ({ setFormData, setSearch, setDebounceVal, setIsSubmitted }) => {
    const defaultValue = {
        supplier_name: '',
        supplier_ref: '',
        main_component_kode_item: '',
        main_component_internal_name: '',
        main_component_id: null,
        field_set_components: [
            {
                id_dummy: Math.floor(Math.random() * 10000) + 1,
                set_component_internal_name: '',
                set_component_kode_item: '',
                set_component_id: null,
            },
        ],
    };
    setSearch('');
    setDebounceVal('');
    setFormData(defaultValue);
    setIsSubmitted(false);
};

export const fetchSupplierNewItemSet = async ({
    page,
    setSupplier,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
    loadingOnUserTyping,
    setLoadingUserTyping,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify({ q: search, page, source: 'INTERNAL' }, { skipEmptyString: true });
    setLoadingSearch(true);
    setLoadingUserTyping(loadingOnUserTyping);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/supplier/list?limit=15&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { suppliers, totalData } = data.data;
        if (moreData && !search) {
            setSupplier((prev) => [...prev, ...(suppliers || [])]);
            setPage(page + 1);
        } else {
            setSupplier(suppliers || []);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    }
};

export const handleFocus = ({ setOpenInfiniteScroll }) => {
    setOpenInfiniteScroll(true);
};

export const onValidationForm = ({ formData }) => {
    const { supplier_ref, main_component_kode_item, main_component_internal_name } = formData;
    return (
        supplier_ref &&
        !isEmpty(supplier_ref) &&
        main_component_kode_item &&
        !isEmpty(main_component_kode_item) &&
        main_component_internal_name &&
        !isEmpty(main_component_internal_name)
    );
};

const onDataFormatting = ({ formData, isEditModal }) => {
    const data = { ...formData };
    data.field_set_components.map((item) => item.set_component_id);
    const set_component_id = data.field_set_components.map((item) => item.set_component_id);
    delete data.main_component_kode_item;
    delete data.main_component_internal_name;
    delete data.field_set_components;
    delete data.supplier_name;
    if (isEditModal) {
        delete data.main_component_id;
    }
    return { ...data, set_component_id };
};

export const onSubmitForm = async ({
    formData,
    toast,
    setIsSubmitted,
    isEditModal,
    reFetchMaterialItemSetHandler,
    onResetForm,
    setIsSubmitLoading,
    setIsModalSuccess,
    setIsModalWarning,
    mainId,
}) => {
    const isValid = onValidationForm({ formData });
    setIsSubmitted(true);
    setIsSubmitLoading(true);
    if (!isValid) {
        toast({
            position: 'top-center',
            description: 'Field tidak boleh kosong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
        return;
    }
    const data = onDataFormatting({ formData, isEditModal });
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const url = isEditModal ? `/material-item-set/${mainId}` : `/material-item-set/`;
    const method = isEditModal ? 'PATCH' : 'POST';
    try {
        await Axios({
            url,
            method,
            headers: { Authorization: 'Bearer ' + userToken },
            data: data,
        });
        await onResetForm();
        await reFetchMaterialItemSetHandler();
        await setIsModalSuccess(true);
    } catch (error) {
        const message = error?.response?.data?.errors[0]?.message;
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 1500,
            isClosable: true,
        });
    } finally {
      setIsModalWarning(false);
        setIsSubmitted(false);
        setIsSubmitLoading(false);
    }
};

const onFormatEdit = ({ materialItemSet }) => {
    let formatObj;
    materialItemSet.map((item) => {
        formatObj = {
            supplier_name: item.supplier.name,
            supplier_ref: item.supplier.supplier_ref,
            main_component_kode_item: item.kode_item,
            main_component_internal_name: item.trade_name,
            main_component_id: item.id,
            field_set_components: item.set_component.map((res, i) => ({
                id_dummy: i + 1,
                set_component_internal_name: res.trade_name,
                set_component_kode_item: res.kode_item,
                set_component_id: res.id,
            })),
        };
        return item;
    });

    return {
        formatObj,
    };
};

export const fetchDetailItemSet = async ({ mainId, setFormData }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material-item-set/${mainId}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { materialItemSet } = data.data;
        const { formatObj } = onFormatEdit({
            materialItemSet,
        });
        setFormData((prev) => ({
            ...prev,
            ...formatObj,
        }));
    } catch (error) {
        console.log(error);
    }
};
