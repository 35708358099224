import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { ChevronLeftIcon } from './icons';
import { useNavigate, useParams } from 'react-router-dom';
import FormDataLengkap from './section/formDataLengkap';
import FormAfterGenerate from './section/formAfterGenerate';
import React, { useEffect } from 'react';
import { initialValue } from './const';
import { getDetailRawDataOrder } from './function.';
import ModalConfirm from '@components/modalConfirm';
import { useDispatch } from 'react-redux';
import { onChangeManualShowGlobalFilter } from '@store/list/globalFilter';

import './styles.scss';

const SectionAddMoreOrder = () => {
    const { id } = useParams();
    const toast = useToast();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const [formData, setFormData] = React.useState(initialValue);
    const [showBottomForm, setShowBottomForm] = React.useState(false);
    const [isLoadingEdit, setIsLoadingEdit] = React.useState(false);
    const [confirmOut, setConfirmOut] = React.useState(false);

    useEffect(() => {
        if (Boolean(id)) {
            dispatch(onChangeManualShowGlobalFilter(id));
            getDetailRawDataOrder({
                id,
                setFormData,
                toast,
                setShowBottomForm,
                setIsLoadingEdit,
                navigate,
            });
        }
    }, [id, toast, navigate, dispatch]);

    const handleClose = () => {
        if (Boolean(id) || Boolean(formData?.kode_item || formData?.supplier_name)) {
            setConfirmOut(true);
            return;
        }
        setFormData(initialValue);
        navigate('/raw-data-order');
    };

    return (
        <Box className="section-add-more-order">
            <Box className="title-header-container">
                <Flex role="button" className="back-btn" onClick={handleClose}>
                    <ChevronLeftIcon />
                    <Text className="title"> {Boolean(id) ? 'Edit Item' : 'Add New Order'}</Text>
                </Flex>
            </Box>
            <Box>
                <FormDataLengkap
                    setShowBottomForm={setShowBottomForm}
                    formData={formData}
                    setFormData={setFormData}
                    IsLoadingEdit={isLoadingEdit}
                />
            </Box>
            <Box className="divider" />
            <Box>
                {showBottomForm && (
                    <FormAfterGenerate formData={formData} setFormData={setFormData} isEdit={Boolean(id)} id={id} />
                )}
            </Box>
            <ModalConfirm
                isOpen={confirmOut}
                onClose={() => {
                    setConfirmOut(false);
                }}
                onSubmit={() => {
                    setFormData(initialValue);
                    navigate('/raw-data-order');
                }}
                submitLabel="Ya"
                title="Apakah anda yakin ingin keluar?"
            />
        </Box>
    );
};

export default SectionAddMoreOrder;
