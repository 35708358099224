import { Text, Tooltip, Box } from '@chakra-ui/react';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableListRekapDelivery = [
    {
        name: 'No Order',
        value: 'no_order',
        width: 20,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 40,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        width: 360,
        flex: 1,
        children: ['-'],
        component: ({ value }) => (
            <Tooltip
                label={value}
                fontStyle={'normal'}
                fontWeight={'400'}
                fontSize={'12px'}
                lineHeight={'18px'}
                textAlign={'left'}
                color="white"
            >
                <Text className="column-text text-ellipsis">{value || '-'}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Konfirmasi ETA',
        value: 'eta_konfirmasi',
        width: 110,
        children: ['-'],
        component: ({ value }) => (
            <Box className="wrapper-column-text">
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Qty To Delivery',
        value: 'on_delivery',
        width: 110,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value) || '-'}</Text>,
    },
    {
        name: 'Update ETA',
        value: 'eta_update',
        width: 110,
        children: ['-'],
        component: ({ value }) => (
            <Box className="wrapper-column-text">
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Notes',
        value: 'notes',
        width: 119,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
];
