import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';
import { onFormatDate } from './section/sectionHead/multipleFilter/function';

export const fetchRawDataOrder = async ({
    page,
    filter,
    setRawData,
    setIsLoading,
    setTotalData,
    setTotalPage,
    selectedPerPage,
    jenisMaterial,
    supplier,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const { itemCode, trade_name, eta_confirm_date, no_PO } = filter;

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            limit: selectedPerPage,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            eta_konfirmasi: Boolean(eta_confirm_date) ? onFormatDate(eta_confirm_date) : '',
            no_po: no_PO,
        },
        { skipEmptyString: true }
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/raw-data-order?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { rawDataOrder, totalData, totalPage } = data.data;
        setTotalData(totalData);
        setTotalPage(totalPage);
        setRawData(rawDataOrder);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setRawData([]);
    } finally {
        setIsLoading(false);
    }
};

export const numberToFixedRDO = (num) => {
    if (typeof num === 'number' || num === 0) {
        return Number(num.toFixed(2)) % num ? num.toFixed(2) : num;
    } else {
        return '-';
    }
};

export const getColorHandler = ({ status }) => {
    switch (status) {
        case 'ON TRACK':
            return '#D9F2E3';
        case 'OVER DUE':
            return '#FDBDC3';
        default:
            return '#0000';
    }
};

export const getColorTextHandler = ({ status }) => {
    switch (status) {
        case 'ON TRACK':
            return '#267346';
        case 'OVER DUE':
            return '#EA4F4F';
        default:
            return '#0000';
    }
};

export const getColorProgresStatus = ({ status }) => {
    switch (status) {
        case 'On Delivery':
            return '#CCE2FF';
        case 'Done':
            return '#F3F3F3';
        case 'In Preparation':
            return '#F0E9AA';
        case 'Received':
            return '#D9F2E3';
        default:
            return '#0000';
    }
};

export const getColorTextProgresStatus = ({ status }) => {
    switch (status) {
        case 'On Delivery':
            return '#004199';
        case 'Done':
            return '#2D2D2D';
        case 'In Preparation':
            return'#7a7652'
        case 'Received':
            return '#267346';
        default:
            return '#0000';
    }
};

export const onDownloadRawDataOrder = async ({
    dispatch,
    jenisMaterial,
    format_file,
    name,
    title,
    titleErr,
    fileName,
    filter,
    supplier,
    date,
    dateKey,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const { itemCode, trade_name, no_PO } = filter;
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            format_file,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            no_po: no_PO,
            [dateKey]: Boolean(date) ? date : '',
        },
        { skipEmptyString: true }
    );

    dispatch(
        onDownloadGlobalHandler({
            name,
            type: 'loading',
            id: downloadId,
            title,
        })
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/raw-data-order/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        dispatch(
            onDownloadGlobalHandler({
                name: name,
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: name,
                type: 'error',
                title: titleErr,
                id: downloadId,
            })
        );
    }
};
