import { isEmptyString } from '@helpers/functions';
import Axios from '@services/axios';
import moment from 'moment';
import Cookies from 'universal-cookie';

export const handleCancelEdit = ({ setItems, setIsEditColumn, itemEdited, setItemEdited }) => {
    setItems((prev) => {
        let clone = [...prev];
        clone.forEach((item) => {
            itemEdited?.forEach((itemEdit) => {
                if (item?.id === itemEdit?.id) {
                    item.konfirmasi_kanban = itemEdit?.prev_konfirmasi_kanban || '';
                    item.konfirmasi_pcs = itemEdit?.prev_konfirmasi_pcs || '';
                    item.konfirmasi_eta = itemEdit?.prev_konfirmasi_eta || '';
                }
            });
        });
        return clone;
    });
    setItemEdited([]);
    setIsEditColumn(false);
};

export const handleConfirm = async ({
    itemEdited,
    toast,
    setIsLoading,
    setItemEdited,
    setIsEditColumn,
    setItems,
    onClose,
    setAutocorrectionList,
    refecthOrderConfirmation,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');

    let data = itemEdited
        ?.filter((emptyItem) => !isEmptyString(emptyItem?.konfirmasi_pcs))
        ?.map((item) => ({
            id: item?.id,
            kode_item: item?.kode_item,
            supplier_name: item?.supplier_name,
            konfirmasi_eta: isEmptyString(item?.konfirmasi_eta)
                ? ''
                : moment(item?.konfirmasi_eta).format('YYYY-MM-DD'),
            konfirmasi_pcs: Number(item?.konfirmasi_pcs),
        }));

    setIsLoading(true);

    let isErrorValidation = false;
    let itemError = [];
    for (let index = 0; index < data.length; index++) {
        if (data[index]?.konfirmasi_pcs < 0) {
            isErrorValidation = true;
            itemError.push({
                id: data[index]?.id,
                has_konfirmasi_pcs_error: 'Konfirmasi pcs tidak boleh kurang dari 0',
            });
        }

        if (isEmptyString(data[index].konfirmasi_eta)) {
            isErrorValidation = true;
            itemError.push({
                id: data[index]?.id,
                has_eta_error: 'ETA wajib diisi',
            });
        }
    }
    /// SET ERROR MESSAGE
    if (isErrorValidation) {
        setItemEdited((prev) => {
            let clone = [...prev];
            clone.forEach((item) => {
                item.has_konfirmasi_pcs_error = null;
                item.has_eta_error = null;
                itemError?.forEach((errorItem) => {
                    console.log(item, errorItem, '<<<compare');
                    if (item?.id === errorItem?.id) {
                        item.has_konfirmasi_pcs_error = errorItem?.has_konfirmasi_pcs_error;
                        item.has_eta_error = errorItem?.has_eta_error;
                    }
                });
                return item;
            });
            setIsLoading(false);

            return clone;
        });
        toast({
            description: 'Confirm gagal. Periksa kembali!',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
        onClose();
        return;
    }

    data = data?.map((item) => ({
        kode_item: item?.kode_item,
        supplier_name: item?.supplier_name,
        konfirmasi_eta: item?.konfirmasi_eta,
        konfirmasi_pcs: item?.konfirmasi_pcs,
    }));

    await Axios({
        method: 'POST',
        url: `/order-management/confirmation`,
        headers: { Authorization: 'Bearer ' + userToken },
        data,
    })
        .then((res) => {
            setItemEdited([]);

            const autoCorrectList = res?.data?.data?.autoCorrectList;

            toast({
                position: 'center-top',
                description: res.data?.data?.message || 'Berhasil',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            if (autoCorrectList?.length) {
                setAutocorrectionList(autoCorrectList);
            }
            setItems((prev) => {
                for (let index = 0; index < prev.length; index++) {
                    prev[index]['konfirmasi_pcs'] = '';
                    prev[index]['konfirmasi_eta'] = '';
                    prev[index]['konfirmasi_kanban'] = '';
                }
                return prev;
            });
            setIsEditColumn(false);
        })
        .catch((error) => {
            try {
                toast({
                    title: 'Error',
                    description: Boolean(error?.response?.data?.message)
                        ? error?.response?.data?.message
                        : Boolean(error?.response?.data?.errors[0]?.message)
                        ? error?.response?.data?.errors?.map((item) => item?.message)?.join(', ')
                        : error?.response?.data?.errors?.join(),
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                    position: 'top-center',
                });
            } catch (error) {
                toast({
                    description: 'Invalid Handling Error',
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                    position: 'top-center',
                });
                throw Error('Invalid Handling Error');
            }
        })
        .finally(() => {
            setIsLoading(false);
            onClose();
            refecthOrderConfirmation();
        });
};
