export const IconSuccess = () => (
    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="42.5" cy="42.25" r="42.25" fill="#D1FAB8" />
        <path
            d="M67.3457 26.0039L38.0054 55.3442L24.6689 42.0077"
            stroke="white"
            strokeWidth="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
