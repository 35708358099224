import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const getDetailRawDataOrder = async ({
    setFormData,
    id,
    toast,
    setShowBottomForm,
    setIsLoadingEdit,
    navigate,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoadingEdit(true);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `raw-data-order/${id}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { rawDataOrder } = data?.data || {};
        setFormData({
            kode_item: rawDataOrder?.orderManagement?.materialConfig?.kode_item,
            supplier_name: rawDataOrder?.orderManagement?.materialConfig?.supplier?.name,
            nama_item: rawDataOrder?.orderManagement?.materialConfig?.trade_name,
            due_date: new Date(rawDataOrder?.eta_konfirmasi || null),
            quantity_confirmation: String(rawDataOrder?.qty_konfirmasi),
            qty_ospo: rawDataOrder?.outstandingPo[0]?.amount_total,
            no_po: rawDataOrder?.outstandingPo[0]?.name,
        });
        setIsLoadingEdit(false);
        setShowBottomForm(true);
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;
        toast({
            title: 'Error',
            description: message || 'Something wrong',
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'top-center',
        });
        setTimeout(() => {
            navigate('/raw-data-order');
        }, 3000);
    }
};
