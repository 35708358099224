import { OrderManagementIcon, EditIcon } from '@asset/icons';
import { Box, Button, Flex, Text, useDisclosure, useToast } from '@chakra-ui/react';
import { TableOrderConfirmationContext } from '../..';
import React, { useState } from 'react';
import { handleCancelEdit, handleConfirm } from './function';
import { ChevronLeftIcon } from '@pagesPhase2/rawDataOrder/section/sectionAddMoreOrder/icons';
import { useNavigate } from 'react-router-dom';
import FilterItemCode from '@components/filterItemCode';
import FilterTradeName from '@components/filterTradeName';
import ModalConfirm from '@components/modalConfirm';
import './styles.scss';
import ModalAutocorrection from '@pagesPhase2/orderManagement/component/modalAutocorrection';

const SectionHead = ({ goBack }) => {
    const {
        setIsEditColumn,
        isEditColumn,
        setItems,
        itemEdited,
        setItemEdited,
        setPage: setMainPage,
        setFilter,
        refecthOrderConfirmation,
    } = React.useContext(TableOrderConfirmationContext);
    const [isLoading, setIsLoading] = React.useState(false);
    const toast = useToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const [autocorrectionList, setAutocorrectionList] = useState([]);
    return (
        <div>
            <ModalConfirm
                loading={isLoading}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={() =>
                    handleConfirm({
                        itemEdited,
                        toast,
                        setIsLoading,
                        setItemEdited,
                        setIsEditColumn,
                        setItems,
                        onClose,
                        setAutocorrectionList,
                        refecthOrderConfirmation,
                    })
                }
                submitLabel="Confirm"
                title="Apa anda yakin telah mengisi dengan benar?"
            />
            <ModalAutocorrection
                autocorrectionList={autocorrectionList}
                isOpen={autocorrectionList?.length > 0}
                onClose={() => setAutocorrectionList([])}
            />
            <Flex className="order-confirmation-box-header">
                <Flex className="container-text-order-confirmation" onClick={goBack}>
                    <Box>
                        <ChevronLeftIcon />
                    </Box>
                    <Box>
                        <OrderManagementIcon />
                    </Box>
                    <Text className="text-order-confirmation">Order Confirmation</Text>
                </Flex>
                <Flex className="wrapper-btn" display={isEditColumn ? 'flex' : 'none'}>
                    <Button
                        className="btn-cancel"
                        onClick={() => handleCancelEdit({ setIsEditColumn, setItems, itemEdited, setItemEdited })}
                    >
                        Cancel
                    </Button>
                    <Button
                        isDisabled={isLoading}
                        _hover={{ cursor: 'block' }}
                        isLoading={isLoading}
                        className="btn-confirm"
                        onClick={onOpen}
                    >
                        Confirm
                    </Button>
                </Flex>
                <Flex className="action-button-container" display={isEditColumn ? 'none' : 'flex'}>
                    <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                    <Box ml="12px">
                        <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                    </Box>
                    <Box className="divider" />
                    <Button className="button-edit" onClick={() => setIsEditColumn(true)}>
                        <Box mr="5px">
                            <EditIcon />
                        </Box>
                        edit
                    </Button>
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;
