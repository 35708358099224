import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchInfiniteSetComponent = async ({
    page = 1,
    setData,
    setLoadingSearch,
    setFilterData,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setLoadingSearch(true);
    const filterStringify = queryString.stringify({ page }, { skipEmptyString: true });
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/list?&${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { listMaterial } = data.data;
        setData(listMaterial);
        setFilterData(listMaterial);
        setLoadingSearch(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
    }
};
