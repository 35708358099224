import React, { useState, useEffect } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Flex,
    Spinner,
    Menu,
    MenuList,
    InputGroup,
    Input,
    InputRightElement,
} from '@chakra-ui/react';
import { CloseIcon, SearchIcon, CalendarIcon } from './icons';
import { handleFocus } from '../function';
import { formNewItem } from './const';
import InfiniteScroll from 'react-infinite-scroll-component';
import DatePicker from 'react-date-picker';
import './styles.scss';

function Index({
    onClose,
    isOpen,
    handleResetFilter,
    handleApplyFilter,
    outlineLabel,
    formData,
    setFormData,
    loadingSearch,
    search,
    setSearch,
    data,
    getMoreData,
    totalData,
    selectDate,
    setSelectDate,
    onSearchHandler,
    loadingUserTyping,
    debounceSearchNoPO,
    debounceValueNoPO,
    setDebounceValueNoPO,
    setPage,
}) {
    const [openInf, setOpenIf] = useState(false);
    useEffect(() => {
        if (openInf) {
            getMoreData({ moreData: false, loadingOnUserTyping: true });
        }
    }, [debounceValueNoPO, openInf]);

    const onChangeInputDate = (selectDate) => {
        setSelectDate(selectDate);
        setFormData((prev) => ({ ...prev, inputDate: selectDate }));
    };
    const handleClickNumberPO = (item) => {
        setSearch(item?.name);
        setFormData((prev) => ({
            ...prev,
            no_po: item?.name,
        }));
        setOpenIf(false);
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} position="relative">
            <Box className="modal-container-filter-multiple-raw-data-order">
                <ModalOverlay />
                <ModalContent maxW="410px" maxH="max-content">
                    <Flex className="modal-filter-multiple-header">
                        <Text className="modal-header-text">Filter</Text>
                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Flex className="filter-no-po__container">
                            {formNewItem.map((formList) => {
                                if (formList.type === 'select') {
                                    return (
                                        <>
                                            <Box>
                                                <Text className="label-title">{formList?.title}</Text>
                                                <Box position="relative">
                                                    <InputGroup>
                                                        <Input
                                                            className="fieldmenu-input"
                                                            placeholder={formList?.placeholder}
                                                            onFocus={() => {
                                                                setDebounceValueNoPO(search || '');
                                                                setPage(1);
                                                                handleFocus({
                                                                    setOpenIf,
                                                                    setSearch,
                                                                    setSelectDate,
                                                                    formData,
                                                                });
                                                            }}
                                                            value={search}
                                                            onChange={(text) => {
                                                                setSearch((prev) => {
                                                                    return text.target.value;
                                                                });
                                                                debounceSearchNoPO(text);
                                                            }}
                                                        />
                                                        <InputRightElement h="32px">
                                                            <Box className="divider" />
                                                            {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                                                        </InputRightElement>
                                                    </InputGroup>
                                                    <Menu isOpen={openInf} onClose={() => setOpenIf(false)}>
                                                        <MenuList mt="38px" zIndex={20}>
                                                            <div id="scrollableDiv" className="scroll-box-container">
                                                                <InfiniteScroll
                                                                    dataLength={data.length}
                                                                    next={() =>
                                                                        getMoreData({
                                                                            moreData: true,
                                                                            loadingOnUserTyping: false,
                                                                        })
                                                                    }
                                                                    hasMore={data.length === totalData ? false : true}
                                                                    scrollableTarget="scrollableDiv"
                                                                    loader={
                                                                        data.length < 9 ? null : (
                                                                            <Text className="loading_fetch_next_page">
                                                                                Loading
                                                                            </Text>
                                                                        )
                                                                    }
                                                                    endMessage={null}
                                                                >
                                                                    {data?.length === 0 && !loadingSearch && (
                                                                        <Text className="loading_fetch_next_page">
                                                                            no po tidak ditemukan "{debounceValueNoPO}"
                                                                        </Text>
                                                                    )}
                                                                    {loadingUserTyping ? (
                                                                        <Text className="loading_fetch_next_page">
                                                                            Loading...
                                                                        </Text>
                                                                    ) : (
                                                                        data?.map((item, index) => {
                                                                            return (
                                                                                <Flex
                                                                                    key={index}
                                                                                    justifyContent="space-between"
                                                                                    className="list_item_infinite"
                                                                                    onClick={() =>
                                                                                        handleClickNumberPO(item)
                                                                                    }
                                                                                >
                                                                                    <Text>{item?.name}</Text>
                                                                                </Flex>
                                                                            );
                                                                        })
                                                                    )}
                                                                </InfiniteScroll>
                                                            </div>
                                                        </MenuList>
                                                    </Menu>
                                                </Box>
                                            </Box>
                                        </>
                                    );
                                }
                            })}
                        </Flex>

                        <Box className="datepicker-konfirmasi-eta__container">
                            <Text className="label-title-confirm">Konfirmasi ETA</Text>
                            <Box className="calender_confirm-eta">
                                <DatePicker
                                    autoFocus={true}
                                    value={selectDate}
                                    onChange={onChangeInputDate}
                                    className="date-picker-confirm-eta"
                                    openCalendarOnFocus={false}
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon color={'#2D2D2D'} />}
                                    calendarClassName="calendar-confirm-eta"
                                    format="yyyy-MM-dd"
                                    dayPlaceholder="ETA"
                                    monthPlaceholder="Konfirmasi"
                                    yearPlaceholder="Input"
                                />
                            </Box>
                        </Box>
                    </ModalBody>
                    <ModalFooter className="ModalFooter">
                        <Box className="btn-modal-close" onClick={handleResetFilter}>
                            <Text className="text-modal-close">{outlineLabel}</Text>
                        </Box>
                        <Box className={`btn-apply-filter`} onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;
