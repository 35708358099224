import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, InputGroup, Input, InputRightElement, Menu, MenuList, Spinner } from '@chakra-ui/react';
import { ArrowUpIconBlack } from '@asset/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyField } from '@components/fieldMenu';
import { isEmpty } from 'lodash';
import { fetchInfiniteSetComponent } from './function';
import './styles.scss';

const SetComponent = ({ onChangeValHandler, isSubmitted, id, kode_item, internal_name, handleChangeSetComp }) => {
    const [data, setData] = useState([]);
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [debounceVal, setDebounceVal] = useState('');
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const getMoreData = () => {
        fetchInfiniteSetComponent({
            setData,
            setLoadingSearch: setLoadingSearch,
            setFilterData,
        });
    };
    const handleChange = (text) => {
        const { value } = text.target;
        handleChangeSetComp(value, id);
        if (value) {
            setLoadingSearch(true);
            const filteredData = data?.filter((item) => {
                const itemData = item.kode_item ? item.kode_item.toUpperCase() : ''.toUpperCase();
                const valUpper = value.toUpperCase();
                return itemData.indexOf(valUpper) > -1;
            });
            setFilterData(filteredData);
        } else {
            setFilterData(data);
        }
        setDebounceVal(text.target.value);
        setLoadingSearch(false);
    };

    const handleClickSupplierName = (item, idTarget) => {
        onChangeValHandler(item, idTarget);
        setOpenInfiniteScroll(false);
    };

    useEffect(() => {
        if (openInfiniteScroll) {
            getMoreData();
        }
    }, [openInfiniteScroll]);

    return (
        <>
            <Flex className="set-component__container">
                <Box mr="34">
                    <Text className="title-fieldmenu">
                        kode item main<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Box position="relative">
                        <InputGroup>
                            <Input
                                className={`fieldmenu-input__set-component ${
                                    isSubmitted && isEmpty(kode_item) && 'required-field__input'
                                }`}
                                placeholder={'Masukan kode item'}
                                onFocus={() => setOpenInfiniteScroll(true)}
                                value={kode_item}
                                height="48px"
                                onChange={(text) => {
                                    handleChange(text);
                                }}
                            />
                            <InputRightElement height="48px" right="12px">
                                <Box className="divider_input_infinite_scroll" />
                                {loadingSearch ? <Spinner size="sm" /> : <ArrowUpIconBlack />}
                            </InputRightElement>
                        </InputGroup>
                        <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                            <MenuList mt="55px" maxHeight="310px">
                                <div id="scrollableDiv" className="scroll-container">
                                    <InfiniteScroll
                                        dataLength={filterData.length}
                                        next={() => getMoreData()}
                                        hasMore={false}
                                        scrollableTarget="scrollableDiv"
                                        loader={
                                            data.length === 0 ? (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            ) : data.length < 9 ? null : (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            )
                                        }
                                        endMessage={null}
                                    >
                                        {filterData.length === 0 && !loadingSearch ? (
                                            <Text className="no_data_infinite_scroll">
                                                Tidak Bisa Menemukan "{debounceVal}"
                                            </Text>
                                        ) : (
                                            filterData?.map((item, index) => {
                                                return (
                                                    <Box
                                                        id={id}
                                                        onClick={(e) =>
                                                            handleClickSupplierName(item, e.currentTarget.id)
                                                        }
                                                        className="list_item_infinite"
                                                        key={index}
                                                    >
                                                        {item?.kode_item}
                                                    </Box>
                                                );
                                            })
                                        )}
                                    </InfiniteScroll>
                                </div>
                            </MenuList>
                        </Menu>
                        {isSubmitted && isEmpty(kode_item) && (
                            <Text className="required-field__text">*Masukan Kode Item</Text>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Text className="title-fieldmenu">
                        internal name<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <EmptyField className="input-field-disabled" buttonName={internal_name || 'Terisi Otomatis'} />
                    {isSubmitted && isEmpty(internal_name) && (
                        <Text className="required-field__text">*Masukan Internal Name</Text>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default SetComponent;
