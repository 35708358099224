import React from 'react';
import { Box } from '@chakra-ui/react';
import './styles.scss';

function index({ data }) {
    return (
        <Box className="container-error-detail-upload-material-config">
            <Box>Periksa kembali file mu, dan jaringan internet, untuk melanjutkan upload file</Box>
            {data?.type === 'then' && (
                <Box role="ul" className="error-detail">
                    {data?.message?.map((item, index) => (
                        <li key={index} style={{ textAlign: 'left' }}>
                            {item?.msg}
                        </li>
                    ))}
                </Box>
            )}
        </Box>
    );
}

export default index;
