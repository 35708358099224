import dateFormat from 'dateformat';
import { find, get } from 'lodash';
import { onChangeMaterialType } from '@store/list/material';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { routeListPhase2, routeList } from '@routers/const';

export const handleCheckboxChange = ({ checked, selectedData, data, setData, type }) => {
    let clone = [...data];
    clone.map((userItem) => {
        if (userItem?.id === selectedData?.id) {
            userItem.checked = checked;
        }
        return userItem;
    });
    setData(clone);
};

export const getFullDateHandler = () => {
    const date = new Date();
    return dateFormat(date, 'dd-mmm-yyyy');
};
export const getWeekHandler = () => {
    const date = new Date();
    return dateFormat(date, 'W');
};

export const reloadPageHandler = () => window.location.reload();

export const togglerFullscreenHandler = ({ isFullscreen }) => {
    if (isFullscreen) {
        document?.exitFullscreen();
    } else {
        document.querySelector('#root').requestFullscreen({ navigationUI: 'show' });
    }
};
export const showFilterJenisMaterialByRole = ({ userRole }) => {
    return Boolean(
        ['Super Admin', 'Admin', 'Superior', 'Admin Warehouse to Supplier'].find((item) => item === userRole)
    );
};
export const disabledFilterSupplierByRole = ({ userRole }) => {
    return Boolean(['Supplier PM', 'Supplier RM'].find((item) => item === userRole));
};
export const isShowMaterialFilter = ({ location, paramId }) => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');
    const routes = appType === 'ndcToManufacture' ? routeList() : routeListPhase2(paramId);
    return get(find(routes, { link: location.pathname }), 'isShowMaterialFilter');
};

export const isHideFullScreenReload = ({ location }) => {
    const cookies = new Cookies();
    const appType = cookies.get('appType');

    const routes = appType === 'ndcToManufacture' ? routeList() : routeListPhase2();

    return get(find(routes, { link: location.pathname }), 'isHideFullScreenReload');
};

export const onSelectedMaterial = ({ material, dispatch }) => {
    const cookies = new Cookies();
    cookies.set('selectedMaterial', queryString.stringify(material));
    dispatch(onChangeMaterialType(material));
};
