import { Box, Text } from '@chakra-ui/react';
import { FilterIcon } from '@pagesPhase2/materialConfiguration/section/sectionHead/modalFiltersMultiple/icons';
import React from 'react';
import { optionFilterMenu } from './const';
import DropDown from '@pagesPhase2/demandAnticipation/components/dropDownMenu';
import FieldMenu from '@components/fieldMenu';
import { ArrowUpIconBlack } from '@asset/icons';
import { countBadges } from './function';
import { DemandAnticipationContext } from '@pagesPhase2/demandAnticipation';
import './styles.scss';

function Index() {
    const { onChangeFilterStatus, setPage: setMainPage } = React.useContext(DemandAnticipationContext);
    const [open, setOpen] = React.useState(false);
    const [filter, setFilter] = React.useState({
        sort_by: 'Weekly',
        status: 'All',
    });

    const isHasFilter = Boolean(filter.sort_by !== 'Weekly' || filter.status !== 'All');
    const filterBadges = countBadges(filter);

    const handleChangeFilter = ({ selected, item }) => {
        setMainPage(1);
        if (item.name === 'sort_by') {
            onChangeFilterStatus({ key: 'sortBy', value: selected.value });
        }
        if (item.name === 'status') {
            onChangeFilterStatus({ key: 'status', value: selected.value });
        }
        setFilter((prev) => ({ ...prev, [item?.name]: selected?.name }));
    };

    return (
        <Box className="multiple-filter-demand-anticipation">
            <Box
                onClick={() => setOpen(true)}
                className={`multiple-icon-filter ${isHasFilter && 'has-filter-multiple-selected'}`}
            >
                <DropDown
                    width="216px"
                    placement="bottom"
                    body={optionFilterMenu.map((item, index) => (
                        <Box key={index}>
                            <Text className="label-field">{item.label}</Text>
                            <FieldMenu
                                flip={false}
                                buttonName={
                                    <span>
                                        <span>
                                            {filter[item.name] ? (
                                                <span className="selected-filter-text">{filter[item.name]}</span>
                                            ) : (
                                                <span className="default-filter-text">{filter[item.name]}</span>
                                            )}
                                        </span>
                                    </span>
                                }
                                menuList={item.option}
                                keyName="name"
                                onClick={(selected) => handleChangeFilter({ selected: selected, item })}
                                menuClassName="menu-container__user-management"
                                menuButtonClassName="menu-button"
                                menuItemClassNam="menu-item"
                                stylePropsMenuButtonClassName={{
                                    width: '179px',
                                }}
                                menuListClassName="menuList-container"
                                icon={<ArrowUpIconBlack />}
                            />
                        </Box>
                    ))}
                    customBodyContentClassName="body"
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Box>
                        <FilterIcon color={isHasFilter ? '#1746A2' : '#2D2D2D'} />
                    </Box>
                </DropDown>

                {isHasFilter && <Text className="btn-filter-multiple-badge">{filterBadges}</Text>}
            </Box>
        </Box>
    );
}

export default Index;
