import React, { useState, useContext } from 'react';
import { Box } from '@chakra-ui/react';
import { CalendarIcon } from '../../../icons';
import DatePicker from 'react-date-picker';
import { tableOrderDeliveryContext } from '../../..';
import { onFormatDate } from '../../../function';
import './styles.scss';

const InputDateETA = () => {
    const { setFilter } = useContext(tableOrderDeliveryContext);
    const [selectDate, setSelectDate] = useState(null);

    const onChangeInputDate = (selectDate) => {
        setSelectDate(selectDate);
        setFilter((prev) => ({ ...prev, inputDate: onFormatDate(selectDate) }));
    };

    return (
        <Box className="input-date-eta__container">
            <Box className="calender_eta">
                <DatePicker
                    value={selectDate}
                    onChange={onChangeInputDate}
                    className="date-picker-content"
                    openCalendarOnFocus={false}
                    clearIcon={null}
                    calendarIcon={<CalendarIcon />}
                    calendarClassName="calendar-first"
                    format="yyyy-MM-dd"
                    dayPlaceholder="ETA"
                    monthPlaceholder="tanggal"
                    yearPlaceholder="Input"
                    minDate={new Date()}
                />
            </Box>
        </Box>
    );
};

export default InputDateETA;
