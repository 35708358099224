import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PyramidHamburgerIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { handleCloseModalFilter } from './function';
import ModalFilter from './modalFilterChecked/index';
import './styles.scss';

function FilterItemCode({ setFilter, setMainPage }) {
    const [selectedData, setSelectedData] = useState([]);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [codeItem, setCodeItem] = useState([]);
    const [codeItemChoosed, setCodeItemChoosed] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [refetch, setRefetch] = useState(false);
    const [page, setPage] = useState(1);
    const dataSelected = codeItemChoosed?.filter((item) => item.checked);

    const handleApplyFilter = () => {
        setSelectedData(dataSelected);
        setFilter((prev) => ({ ...prev, itemCode: codeItemChoosed }));
        setOpenModalFilter(false);
        setSelectedData(dataSelected);
        setPage(1);
        setMainPage(1);
        setSearch('');
        setRefetch(!refetch);
    };

    const handleResetFilter = () => {
        handleCloseModalFilter({
            selectedData,
            setCodeItem,
            codeItem,
            type: 'reset',
            setCodeItemChoosed,
        });
        setPage(1);
        setSearch('');
        setRefetch(!refetch);
    };
    return (
        <Box className="container-filter-item-code-component">
            <ModalFilter
                search={search}
                page={page}
                setPage={setPage}
                setSearch={setSearch}
                refetch={refetch}
                setRefetch={setRefetch}
                outlineLabel="Reset Filter"
                modalTitle="Filter Kode Item"
                labelSearch="Kode yang dipilih"
                labelSelectedData="Pilih Kode Item"
                codeItem={codeItem}
                setCodeItem={setCodeItem}
                isOpen={openModalFilter}
                codeItemChoosed={codeItemChoosed}
                setCodeItemChoosed={setCodeItemChoosed}
                loadingSearch={loadingSearch}
                setLoadingSearch={setLoadingSearch}
                onClose={() => {
                    handleCloseModalFilter({
                        setOpenModalFilter,
                        selectedData,
                        codeItem,
                        setCodeItem,
                        setCodeItemChoosed,
                    });
                    setPage(1);
                    setSearch('');
                    setRefetch(!refetch);
                }}
                selectedData={selectedData}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`btn-filter-code-item-container ${selectedData?.length > 0 && 'has-item-code-selected'}`}
            >
                <Text className={`btn-filter-text`}>Cari kode item</Text>
                <Box className={`btn-filter-code-item-icon `}>
                    <PyramidHamburgerIcon fill={selectedData?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {selectedData?.length > 0 && <Text className="btn-filter-badge">{selectedData?.length}</Text>}
            </Box>
        </Box>
    );
}

export default FilterItemCode;
