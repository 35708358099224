import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const fetchDataInfinite = async ({
    page,
    setData,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    searchDataName,
    toast,
    loadingOnUserTyping,
    setLoadingUserTyping,
    api,
    apiParams,
    dataResponse,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify({ page, limit: 10, ...apiParams }, { skipEmptyString: true });
    setLoadingSearch(true);
    setLoadingUserTyping(loadingOnUserTyping);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `${api}?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { totalData } = data.data;
        if (moreData && !searchDataName) {
            setData((prev) => [...prev, ...(data.data?.[dataResponse] || [])]);
            setPage(page + 1);
        } else {
            setData(data.data?.[dataResponse] || []);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    } catch (error) {
        const message = error?.response?.data?.errors[0]?.message || error?.response?.data?.errors?.join(', ');
        console.log(error);
        toast({
            title: 'Error',
            description: message || 'Something wrong',
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'top-center',
        });
        setTotalData(1);
        setData([]);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    }
};

export const handleFocus = ({ setOpenInfiniteScroll }) => {
    setOpenInfiniteScroll(true);
};
