import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import OutsideClickHandler from 'react-outside-click-handler';
import { Input, Text, Box, Spinner, useToast, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import React from 'react';
import { SupplierStockContext } from '@pagesPhase2/supplierStock';
import { EditDateIcon } from '@pagesPhase2/supplierStock/icons';
import { formatNumber } from '@helpers/functions';
import { onClickOutside, onSubmit } from './function';

const EditableColumn = ({ currentValue, id, keyAttribute }) => {
    const { setRefetch, isEdit, setDisabledLoadingMain } = React.useContext(SupplierStockContext);
    const [isFocus, setIsFocus] = useState(false);
    const toast = useToast();
    const [value, setValue] = useState(currentValue);
    const [updatedValue, setUpdatedValue] = useState(currentValue);
    const [hasUpdated, setHasUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    return (
        <>
            {isLoading && <Spinner color="blue" size="sm" />}
            {!isLoading && isFocus && (
                <OutsideClickHandler
                    onOutsideClick={() =>
                        onClickOutside({
                            setDisabledLoadingMain,
                            keyAttribute,
                            value,
                            updatedValue,
                            setIsLoading,
                            setHasUpdated,
                            id,
                            setUpdatedValue,
                            setRefetch,
                            toast,
                            setIsFocus,
                            isFocus,
                        })
                    }
                >
                    <Box position="relative">
                        <Input
                            value={value}
                            onChange={onChange}
                            className="column-editable"
                            fontSize={12}
                            border="1px solid #3e5ba6"
                        />
                        <CloseIcon
                            cursor="pointer"
                            color="red"
                            position="absolute"
                            right={'30px'}
                            top="14px"
                            zIndex={3}
                            fontSize={10}
                            onClick={() => setIsFocus(false)}
                        />
                        <CheckIcon
                            cursor="pointer"
                            color="green"
                            position="absolute"
                            zIndex={3}
                            right={'8px'}
                            top="12px"
                            fontSize={14}
                            onClick={() =>
                                onSubmit({
                                    setDisabledLoadingMain,
                                    keyAttribute,
                                    value,
                                    updatedValue,
                                    setIsLoading,
                                    setHasUpdated,
                                    id,
                                    setUpdatedValue,
                                    setRefetch,
                                    toast,
                                    setIsFocus,
                                })
                            }
                        />
                    </Box>
                </OutsideClickHandler>
            )}
            {!isLoading && !isFocus && (
                <Tooltip
                    hasArrow
                    label={isEdit ? 'Click the numbers to edit' : ''}
                    color={'#2D2D2D'}
                    p="11px"
                    fontWeight={400}
                    bg="#F8ED8E"
                    rounded={'8px'}
                    fontSize="12px"
                    w="235px"
                    lineHeight={'18px'}
                >
                    <Box
                        cursor={Boolean(id) && isEdit ? 'pointer' : 'default'}
                        onClick={() => {
                            if (!Boolean(id) || !isEdit) return;
                            setIsFocus(true);
                        }}
                        display="flex"
                        alignItems="center"
                    >
                        <Text className="column-text" width="100%" color={hasUpdated ? 'green !important' : 'black'}>
                            {formatNumber(updatedValue)}
                        </Text>
                        {Boolean(id) && isEdit && (
                            <Box ml={2}>
                                <EditDateIcon size="12" />
                            </Box>
                        )}
                    </Box>
                </Tooltip>
            )}
        </>
    );
};

export default EditableColumn;
