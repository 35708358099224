import { Box } from '@chakra-ui/react';
import { useContext } from 'react';

import { AssetManagementContext } from '@pages/assetManagement';
import TableHead from '../../component/tableHead';
import TableColumn from '../../component/tableColumn';
import './styles.scss';

const SectionTable = () => {
    const { assets } = useContext(AssetManagementContext);

    return (
        <Box className="section-table__container__asset-management">
            <TableHead />
            <Box className="columns-container" bg="white">
                {assets.map((asset, idx) => (
                    <TableColumn idx={idx} key={idx} asset={asset} />
                ))}
            </Box>
        </Box>
    );
};

export default SectionTable;
