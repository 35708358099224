import { Flex, Box } from '@chakra-ui/react';
import { useContext } from 'react';

import { AssetManagementContext } from '@pages/assetManagement';
import { tabList } from '../../const';
import './styles.scss';

const SectionTabView = () => {
    const { onChangeTabView, selectedView } = useContext(AssetManagementContext);

    return (
        <Flex className="section-tab__container__asset-management">
            {tabList.map(({ name, value }, index) => (
                <Box
                    key={index}
                    className={`tab-item ${selectedView === value && 'active-tab'}`}
                    onClick={() => onChangeTabView({ value, name })}>
                    {name}
                </Box>
            ))}
        </Flex>
    );
};

export default SectionTabView;
