import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';

export const fetchDataOrderConfirmation = async ({
    setItems,
    setIsLoading,
    setTotalData,
    selectedPerPage,
    setTotalPage,
    jenisMaterial,
    supplier,
    filter,
    page,
    itemEdited,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const { itemCode, trade_name } = filter;
    setIsLoading(true);

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');

    try {
        const filterStringify = queryString.stringify(
            {
                page,
                limit: selectedPerPage,
                trade_name: tradeNameFilter,
                kode_item: tradeItemCode,
                jenis_material_id: jenisMaterial?.id,
                supplier_name: supplierFilter,
            },
            { skipEmptyString: true }
        );
        const { data } = await Axios({
            method: 'GET',
            url: `/order-management/confirmation/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { orderManagement, totalData, totalPage } = data?.data || {};
        const rebuildOrderManagementList = orderManagement?.map((item) => ({
            ...item,
            konfirmasi_pcs: '',
            konfirmasi_kanban: '',
            konfirmasi_eta: '',
        }));
        let cloneRebuildOrderManagementList = [...rebuildOrderManagementList];
        if (Boolean(itemEdited?.toString())) {
            cloneRebuildOrderManagementList.forEach((item) => {
                itemEdited?.forEach((editedItem) => {
                    if (item?.id === editedItem?.id) {
                        item.notes = editedItem?.notes;
                        item.qty_revisi = editedItem?.qty_revisi;
                    }
                });
                return item;
            });
        }
        setItems(cloneRebuildOrderManagementList);
        setTotalData(totalData);
        setTotalPage(totalPage);
    } catch (error) {
        console.log(error);
        setItems([]);
        setTotalData(0);
        setTotalPage(0);
    } finally {
        setIsLoading(false);
    }
};
export const handleReplaceItem = ({ itemEdited, setItems }) => {
    setItems((prev) => {
        let clone = [...prev];
        clone.forEach((item) => {
            itemEdited?.forEach((editedItem) => {
                if (item?.id === editedItem?.id) {
                    item.konfirmasi_pcs = editedItem?.konfirmasi_pcs;
                    item.konfirmasi_kanban = editedItem?.konfirmasi_kanban;
                    item.konfirmasi_eta = editedItem?.konfirmasi_eta;
                }
            });
            return item;
        });
        return clone;
    });
};
