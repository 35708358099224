import { useNavigate } from 'react-router-dom';
import { Flex, Text, Box, Button } from '@chakra-ui/react';
import { ErrorIcon } from './icons';

import './styles.scss';

const SectionUploadError = ({ errorData, onResetErrorData }) => {
    const navigate = useNavigate();
    return (
        <Flex className="section-upload-error__container__orderplanning">
            <ErrorIcon />
            <Text className="title">File tidak bisa diupload</Text>

            <Box w="504px">
                <Box borderBottom="0.5px solid #BEBEBE" mb="16px">
                    <Text className="error-message__label">Error Message</Text>
                </Box>
                <Flex className="error-message__container">
                    {errorData?.map((message, index) => (
                        <Text key={index} className="error-message__text">{message} </Text>
                    ))}
                </Flex>
            </Box>
            <Box className="button-container">
                <Button className="back-button" onClick={() => navigate('/order-planning')}>
                    Kembali ke Table
                </Button>
                <Button className="upload-again" onClick={onResetErrorData}>
                    Upload Ulang
                </Button>
            </Box>
        </Flex>
    );
};

export default SectionUploadError;
