import { Text, Box } from '@chakra-ui/layout';

// role List Input Problem Limited By Plant
export const roleList = ['PPC', 'Produksi', 'Viewer'];
//
export const tableList = [
    {
        name: 'Proses',
        value: 'position',
        width: 200,
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'NOMO',
        width: 400,
        value: 'nomo',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'SNFG',
        width: 400,
        value: 'snfg',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Start time',
        width: 400,
        value: 'startTime',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'finish time',
        width: 200,
        value: 'endTime',
        component: ({ value }) => <Text className="column-text  ">{value}</Text>,
    },
    {
        name: 'Actual  Duration',
        width: 200,
        value: 'actualDuration',
        component: ({ value }) => <Text className="column-text column-text__blue">{value}</Text>,
    },
    {
        name: 'Expected duration',
        width: 200,
        value: 'expectedTime',
        component: ({ value }) => <Text className="column-text column-text__blue">{value}</Text>,
    },
];

export const tableListDelayReporting = [
    {
        name: 'Jenis Kendala',
        value: 'jenis',
        width: '100%',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Deskripsi',
        width: '100%',
        value: 'detail',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Start time',
        width: '100%',
        value: 'createdAt',
        component: ({ value }) => (
            <Text className="column-text column-text__red" textAlign="left !important">
                {value}
            </Text>
        ),
    },
    {
        name: 'Finish time',
        width: '100%',
        value: 'solvedAt',
        component: ({ value }) => (
            <Text className="column-text column-text__green" textAlign="left !important">
                {value}
            </Text>
        ),
    },
    {
        name: 'Duration',
        width: '100%',
        value: 'duration',
        component: ({ value }) => (
            <Text className="column-text column-text__blue" textAlign="left !important">
                {value}
            </Text>
        ),
    },
    {
        name: 'Status',
        width: '100%',
        value: 'status',
        component: ({ value }) => (
            <Box className="column-status" bg={value === 'Solved' ? '#bbf5ba !important' : '#F69997 !important'}>
                {value}
            </Box>
        ),
    },
    {
        name: 'Action',
        width: '100%',
        value: 'status',
        component: ({ value, openStopProblem, hasMutationAccess }) =>
            hasMutationAccess ||
            (value === 'On going' && (
                <Box cursor="pointer" className="column-action" onClick={() => openStopProblem(true)}>
                    Solve Problem
                </Box>
            )),
    },
];

export const dataHistory = [
    {
        id: 7,
        status_id: 3,
        kard_id: 8,
        start: '2023-01-24T00:54:34.000Z',
        stop: null,
        flow_input_mo_id: 20,
        posisi: 'PENIMBANGAN',
        jenis_proses: 'JADWAL BARU',
        nama_line: 'timbang 2',
        siklus: 1,
        meta: {},
        createdAt: '2023-02-02T03:59:28.092Z',
        updatedAt: '2023-02-02T03:59:54.523Z',
        deletedAt: null,
        kardId: 8,
        status: {
            id: 3,
            name: 'Timbang S1',
            idiom: 'timbang-s1',
            meta: {},
            createdAt: '2023-01-19T03:30:15.616Z',
            updatedAt: '2023-01-19T03:30:15.616Z',
            deletedAt: null,
        },
    },
    {
        id: 7,
        status_id: 3,
        kard_id: 8,
        start: '2023-01-24T00:54:34.000Z',
        stop: null,
        flow_input_mo_id: 20,
        posisi: 'PENIMBANGAN',
        jenis_proses: 'JADWAL BARU',
        nama_line: 'timbang 2',
        siklus: 1,
        meta: {},
        createdAt: '2023-02-02T03:59:28.092Z',
        updatedAt: '2023-02-02T03:59:54.523Z',
        deletedAt: null,
        kardId: 8,
        status: {
            id: 3,
            name: 'Timbang S1',
            idiom: 'timbang-s1',
            meta: {},
            createdAt: '2023-01-19T03:30:15.616Z',
            updatedAt: '2023-01-19T03:30:15.616Z',
            deletedAt: null,
        },
    },
];
export const dataReport = [
    {
        id: 7,
        jenis_kendala: 'Kendala bahan baku',
        deskripsi: 'terdapat bahan baku Powder menggumpal karena ketumpahan cairan',
        start_time: '2023-01-24T00:54:34.000Z',
        finish_time: '2023-01-24T00:54:34.000Z',
        duration: '2023-01-24T00:54:34.000Z',
        status: 'On Going',
    },
    {
        id: 8,
        jenis_kendala: 'Kendala bahan baku',
        deskripsi: 'terdapat bahan baku Powder',
        start_time: '2023-01-24T00:54:34.000Z',
        finish_time: '2023-01-24T00:54:34.000Z',
        duration: '2023-01-24T00:54:34.000Z',
        status: 'On Going',
    },
];
