import React from 'react';
import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuList,
    Spinner,
    Text,
    useToast,
    Skeleton,
} from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { fetchDataInfinite, handleFocus } from './function';
import { debounce } from 'lodash';

import './styles.scss';

function InfiniteDataSelect({
    placeholderInput = 'text here',
    height = '48px',
    width = '100%',
    widthDropDownContainer = '706px',
    api = '/',
    apiParams = {},
    setFormData,
    formData,
    initialAttribute,
    dataResponse,
    searchData,
    setSearchData,
    IsLoadingEdit,
    handleBlur,
}) {
    const [data, setData] = React.useState([]);
    const [totalData, setTotalData] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [loadingUserTyping, setLoadingUserTyping] = React.useState(false);
    const [openInfiniteScroll, setOpenInfiniteScroll] = React.useState(false);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const getMoreData = ({ hasMore, loadingOnUserTyping }) => {
        fetchDataInfinite({
            page,
            setData,
            setPage,
            setTotalData,
            moreData: Boolean(hasMore),
            searchDataName: searchData,
            setLoadingSearch,
            toast,
            loadingOnUserTyping,
            setLoadingUserTyping,
            api,
            apiParams,
            dataResponse,
        });
    };
    const handleClickDataName = (item) => {
        const { name } = item || {};
        setFormData((prev) => ({ ...prev, supplier_name: name }));
        setSearchData(name);
        setOpenInfiniteScroll(false);
    };

    const handleChangeDebounceDataName = (text) => {
        setTotalData(1);
        setSearchData(text.target.value);
        setPage(() => {
            return 1;
        });
    };
    const debounceSearchDataName = React.useMemo(() => debounce(handleChangeDebounceDataName, 500), []);
    const toast = useToast();

    React.useEffect(() => {
        if (openInfiniteScroll) {
            getMoreData({
                hasMore: false,
                loadingOnUserTyping: true,
            });
        }
    }, [searchData, openInfiniteScroll]);
    return (
        <Box className="component-infinite-data-select">
            <Box className="infinite-scroll-container" position="relative">
                <InputGroup>
                    {!IsLoadingEdit ? (
                        <Input
                            onBlur={handleBlur}
                            placeholder={placeholderInput}
                            className="input-data"
                            width={width}
                            onFocus={() => {
                                setSearchData(formData?.[initialAttribute]);
                                setPage(1);
                                handleFocus({
                                    setOpenInfiniteScroll,
                                });
                            }}
                            value={formData?.[initialAttribute] || ''}
                            height={height}
                            onChange={(text) => {
                                ///https://stackoverflow.com/a/69584798/18038473
                                setFormData((prev) => ({ ...prev, supplier_name: text.target.value }));
                                debounceSearchDataName(text);
                            }}
                        />
                    ) : (
                        <Skeleton width="100%" height="48px" />
                    )}
                    <InputRightElement height={height} right="12px">
                        <Box className="divider_input_infinite_scroll" />
                        {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                    </InputRightElement>
                </InputGroup>
                <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                    <MenuList mt="50px" zIndex={20}>
                        {/* https://stackoverflow.com/a/73260330 */}
                        <div id="scrollableDiv" style={{ width: widthDropDownContainer }}>
                            <InfiniteScroll
                                className="scroll-box-container"
                                height={data?.length > 1 ? '230px' : 'auto'}
                                dataLength={data.length}
                                next={() => getMoreData({ hasMore: true, loadingOnUserTyping: false })}
                                hasMore={data.length === totalData ? false : true}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    data.length < 9 ? null : <Text className="loading_fetch_next_page">Loading...</Text>
                                }
                                endMessage={null}
                            >
                                {data.length === 0 && !loadingSearch && (
                                    <Text className="no_data_infinite_scroll">Tidak Bisa Menemukan "{searchData}"</Text>
                                )}
                                {loadingUserTyping ? (
                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                ) : (
                                    data?.map((item, index) => {
                                        return (
                                            <Box
                                                onClick={() => handleClickDataName(item)}
                                                className="list_item_infinite"
                                                key={index}
                                            >
                                                {item?.name}
                                            </Box>
                                        );
                                    })
                                )}
                            </InfiniteScroll>
                        </div>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
}

export default InfiniteDataSelect;
