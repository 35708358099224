import { useContext } from 'react';
import { Flex, Box, useToast } from '@chakra-ui/react';
import { tableList } from '@pagesPhase2/supplierStock/const';
import { SupplierStockContext } from '@pagesPhase2/supplierStock';
import './styles.scss';

const TableColumn = ({ dataItem, onChangeModalState }) => {
    const toast = useToast();
    const { onSelectedValue, formData, setFormData } = useContext(SupplierStockContext);
    return (
        <Flex className="table-column table-column__container__supplier_stock">
            {tableList.map((item, idx) => {
                return (
                    <Box
                        background={dataItem?.materialConfig?.is_mto === true ? '#E6FBEE' : 'white'}
                        key={item.name}
                        w={item.width}
                        flex={item.flex}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: dataItem[item.value] !== null ? dataItem[item.value] : '-',
                            dataItem,
                            formData,
                            setFormData,
                            onChangeModalState,
                            onSelectedValue,
                            toast,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;
