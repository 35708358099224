import Axios from '@services/axios';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';
import Cookies from 'universal-cookie';

export const onDownloadDataRACI = async ({ dispatch }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);

    dispatch(
        onDownloadGlobalHandler({
            name: 'Material Configuration RACI',
            type: 'loading',
            id: downloadId,
            title: 'Menyiapkan dowload data Material Configuration RACI',
        })
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/raci/file/download`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'download_material_configuration_raci';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        dispatch(
            onDownloadGlobalHandler({
                name: 'Material Configuration RACI',
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: 'Material Configuration RACI',
                type: 'error',
                title: 'Dowload data Material Configuration RACI gagal',
                id: downloadId,
            })
        );
    }
};
