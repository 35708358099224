import { useSelector } from 'react-redux';

function useGetFeatureBySediaan() {
    const { userProfile } = useSelector((reducer) => reducer.accessPermissionSlice);
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);
    return {
        userProfile,
        selectedMaterial,
        hasAccess: userProfile?.sediaans?.find((item) => item?.id === Number(selectedMaterial?.id)),
    };
}

export default useGetFeatureBySediaan;
