import { Box, Text } from '@chakra-ui/react';
import React from 'react';

import './styles.scss';

function LoadingSliceUpload() {
    return (
        <Box className="parent-loading-slice">
            <Box className="child-loading-slice">
                <Text className="text-loading-slice">Memuat.....</Text>
            </Box>
        </Box>
    );
}

export default LoadingSliceUpload;
