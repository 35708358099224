import React, { useContext, useState, useEffect, useMemo } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { FilterIcon } from './icons';
import ModalMultiple from './modal';
import { initalFormValue } from './const';
import {
    fetchFilterRekapDeliveryInfinite,
    handleApplyFilter,
    handleCloseModal,
    findBadgeSelectedFilter,
} from './function';
import { TableRekapDeliveryContext } from '../../..';
import { debounce, isEmpty } from 'lodash';
import './styles.scss';

function ModalFilterMultipleRekapDelivery() {
    const { setFilter, storeMultipleFilter, setStoreMutlipleFilter } = useContext(TableRekapDeliveryContext);
    const [dataRekap, setDataRekap] = useState([]);
    const [openModalFilter, setOpenModalFilter] = useState(false);
    const [formData, setFormData] = useState(initalFormValue);
    const [selectedDataItem, setSelectedDataItem] = useState([]);
    const [dataChoosed, setDataChoosed] = useState([]);
    const [search, setSearch] = useState('');
    const [selectDate, setSelectDate] = useState(null);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(1);
    const [refetch, setRefetch] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [debounceValueNoPO, setDebounceValueNoPO] = useState('');
    const [loadingUserTyping, setLoadingUserTyping] = useState(false);
    useEffect(() => {
        if (openModalFilter) {
            fetchFilterRekapDeliveryInfinite({
                page: page,
                dataRekap,
                setDataRekap,
                setLoadingSearch,
                setPage,
                setTotalData,
                search,
                setLoadingUserTyping,
            });
        }
        if (!isEmpty(storeMultipleFilter)) {
            setFormData(storeMultipleFilter?.formData);
            setSelectedDataItem(storeMultipleFilter?.filterSelected);
        }
    }, [refetch, openModalFilter]);

    const getMoreData = ({ moreData, loadingOnUserTyping }) => {
        fetchFilterRekapDeliveryInfinite({
            page,
            setDataRekap,
            dataRekap,
            setPage,
            setTotalData,
            moreData: Boolean(moreData),
            search: debounceValueNoPO,
            setLoadingSearch,
            loadingOnUserTyping,
            setLoadingUserTyping,
        });
    };
    const handleChangeDebounceNoPO = (text) => {
        setLoadingSearch(true);
        setTotalData(1);
        setDebounceValueNoPO(text.target.value);
        setFormData((prev) => {
            return {
                ...prev,
                no_po: text.target.value,
            };
        });
        setPage((prev) => {
            return 1;
        });
    };
    const debounceSearchNoPO = useMemo(() => debounce(handleChangeDebounceNoPO, 500), []);
    const onSearchHandler = () => {
        fetchFilterRekapDeliveryInfinite({
            page: 1,
            setDataRekap,
            dataRekap,
            setPage,
            setTotalData,
            search,
            setLoadingSearch,
        });
    };
    const handleResetFilter = () => {
        setFormData(initalFormValue);
        setSelectDate(null);
        setSearch('');
    };
    const badgeFilterSelected = findBadgeSelectedFilter(selectedDataItem);
    return (
        <Box className="multiple-filter-rekap-delivery">
            <ModalMultiple
                outlineLabel="Reset Filter"
                onSearchHandler={onSearchHandler}
                getMoreData={getMoreData}
                loadingUserTyping={loadingUserTyping}
                debounceSearchNoPO={debounceSearchNoPO}
                debounceValueNoPO={debounceValueNoPO}
                setDebounceValueNoPO={setDebounceValueNoPO}
                setPage={setPage}
                totalData={totalData}
                search={search}
                setSearch={setSearch}
                loadingSearch={loadingSearch}
                dataRekap={dataRekap}
                setDataRekap={setDataRekap}
                isOpen={openModalFilter}
                dataChoosed={dataChoosed}
                setDataChoosed={setDataChoosed}
                selectDate={selectDate}
                setSelectDate={setSelectDate}
                formData={formData}
                setFormData={setFormData}
                selectedDataItem={selectedDataItem}
                value={formData}
                setSelectedDataItem={setSelectedDataItem}
                handleResetFilter={handleResetFilter}
                handleApplyFilter={() =>
                    handleApplyFilter({
                        formData,
                        selectedDataItem,
                        setFormData,
                        setSelectedDataItem,
                        setFilter,
                        setStoreMutlipleFilter,
                        setPage,
                        setSearch,
                        setRefetch,
                        refetch,
                        setOpenModalFilter,
                    })
                }
                onClose={() => handleCloseModal({ setFormData, selectedDataItem, setOpenModalFilter, setPage, setSearch, setSelectDate })}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`multiple-icon-filter ${
                    badgeFilterSelected?.length > 0 ? 'has-filter-multiple-selected' : 'default-border-icon-filter'
                }`}
            >
                <Box className={`multiple-icon-filter`}>
                    <FilterIcon color={badgeFilterSelected?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {badgeFilterSelected?.length > 0 && (
                    <Text className="btn-filter-multiple-badge">{badgeFilterSelected?.length}</Text>
                )}
            </Box>
        </Box>
    );
}

export default ModalFilterMultipleRekapDelivery;
