import { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import Pagination from '@components/pagination';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import ModalDownload from '@components/modalDownload';
import { tableList } from './const';
import { numToArr } from '@helpers/functions';
import { fetchDataOrderManagement, onDownloadOrderManagement } from './function';
import './styles.scss';
import SectionOrderConfirmation from './sections/sectionOrderConfirmation';
import SectionOrderDelivery from './sections/sectionOrderDelivery';

export const OrderManagementContext = createContext();

const OrderManagement = () => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingDownload, setIsLoadingDownload] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [resumeOrder, setResumeOrder] = useState({});
    const [filter, setFilter] = useState({
        itemCode: [],
        trade_name: [],
        status_id: '',
        kanban_card: '',
        order_card: '',
    });
    const [section, setSection] = useState('main');
    const [selectedPage, setSelectedPage] = useState('table');
    const [sort, setSort] = useState('');

    const goBack = () => setSelectedPage('table');

    const handlePageClick = ({ selected }) => setPage(selected + 1);
    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);
    useEffect(() => {
        if (jenisMaterial?.id) {
            fetchDataOrderManagement({
                page,
                filter,
                setOrders,
                setIsLoading,
                setTotalData,
                setTotalPage,
                selectedPerPage,
                jenisMaterial,
                supplier,
                setResumeOrder,
                sort,
            });
        }
    }, [page, filter, selectedPerPage, jenisMaterial, supplier, sort, selectedPage]);

    const onDownloadOM = () => {
        onDownloadOrderManagement({ dispatch, supplier, jenisMaterial, filter });
    };

    return (
        <OrderManagementContext.Provider
            value={{ isLoadingDownload, setIsLoadingDownload, setFilter, setPage, setSort }}
        >
            <Box className="order-management__container">
                <SectionHead
                    setSelectedPage={setSelectedPage}
                    section={section}
                    setSection={setSection}
                    onDownload={onDownloadOM}
                />
                {section?.includes('main') && (
                    <>
                        {isLoading && (
                            <TableSkeleton
                                tableList={tableList}
                                count={numToArr(10)}
                                tableHeadHeight="50px"
                                tablColumnHeight="70px"
                            />
                        )}
                        {!isLoading && orders.length > 0 && <SectionTable orders={orders} />}
                        {!isLoading && !orders.length && (
                            <EmptyState
                                description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                                text="Tidak ada data yg di tampilkan"
                            />
                        )}
                        <Pagination
                            onChangeLimit={() => {
                                setPage(1);
                            }}
                            labelOrder={'Resume Order'}
                            labelPallet={'Pallet'}
                            labelKoli={'Koli'}
                            labelPcs={'Pcs'}
                            valuePallet={`: ${resumeOrder?.totalPallet || '-'}`}
                            valueKoli={`: ${resumeOrder?.totalKoli || '-'}`}
                            valuePcs={`: ${resumeOrder?.totalPcs || '-'}`}
                            page={page}
                            totalData={totalData}
                            totalPage={totalPage}
                            selectedPerPage={selectedPerPage}
                            setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                            onPageChange={handlePageClick}
                        />
                    </>
                )}
                {/*======== Modal Download ========*/}
                <ModalDownload
                    isOpen={isLoadingDownload}
                    onClose={() => setIsLoadingDownload(false)}
                    description={`Menyiapkan dowload data Order Management table`}
                />

                {selectedPage === 'order-confirmation' && <SectionOrderConfirmation goBack={goBack} />}
                {selectedPage === 'order-delivery' && <SectionOrderDelivery goBack={goBack} />}
            </Box>
        </OrderManagementContext.Provider>
    );
};

export default OrderManagement;
