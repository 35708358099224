import { useContext } from 'react';
import { Flex, Box, Text, IconButton, Tooltip } from '@chakra-ui/react';
import { OrderManagementContext } from '@pagesPhase2/orderManagement';
import { handleClickSortTable } from '@helpers/functions';
import { tableList } from '../../const';
import { SortHeaderIcon } from '@pagesPhase2/orderManagement/icons';
import './styles.scss';

const TableHead = () => {
    const { setSort } = useContext(OrderManagementContext);
    return (
        <>
            <Flex className="table-head-order-management__container">
                {tableList.map((head, idx, it) => {
                    if (head.children) {
                        return (
                            <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                                <Flex className="table-head__parent" pl="10px" w="fit-content">
                                    {head.name}
                                    <Box position="relative">
                                        {head.sortValue && (
                                            <Box
                                                className={`${
                                                    head.sortValue === 'kanban_card'
                                                        ? 'icon-sortvalue-kanban__card'
                                                        : 'default-icon-sortvalue'
                                                }`}
                                                onClick={() => handleClickSortTable({ sortItem: head, setSort })}
                                            >
                                                <Tooltip hasArrow className="text-tooltip-label" label={head.label}>
                                                    <IconButton
                                                        zIndex={1}
                                                        minW={3}
                                                        h={4}
                                                        pl={3}
                                                        icon={<SortHeaderIcon />}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        )}
                                    </Box>
                                </Flex>
                                <Flex justify="space-between" w="100%">
                                    {head.children.map((child, index) => {
                                        return (
                                            <Box key={index} className="table-head__child-container">
                                                <Box className="table-head__child">
                                                    <Text>{child}</Text>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Flex>
                            </Box>
                        );
                    } else {
                        return <></>;
                    }
                })}
            </Flex>
        </>
    );
};

export default TableHead;
