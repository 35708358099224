import { Box, Text, Tooltip } from '@chakra-ui/react';
import { isAllowed } from '@helpers/roleAccess';
import { handleCopyToClipboard } from '@helpers/functions';
import Axios from '@services/axios';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import EditableColumn from './components/editableColumn';
import EditableNotes from './components/editableNotes';
import EditableStatus from './components/editableStatus';
import { tableList } from './const';
import './styles.scss';

export const onDownloadDemandAnticipation = async ({ dispatch, filter, jenisMaterial, supplier }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);
    const { itemCode, trade_name } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );
    dispatch(
        onDownloadGlobalHandler({
            name: 'demand anticipation',
            type: 'loading',
            id: downloadId,
            title: 'Menyiapkan dowload data Demand Anticipation table',
        })
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/demand-anticipation/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });

        const fileName = 'download_data_demand_anticipation';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        dispatch(
            onDownloadGlobalHandler({
                name: 'demand anticipation',
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: 'demand anticipation',
                type: 'error',
                title: 'Dowload data Demand Anticipation table gagal',
                id: downloadId,
            })
        );
    }
};

const onFormatTable = ({ materials }) => {
    let formatTable = [
        {
            name: 'no',
            value: 'index',
            width: 70,
            children: ['-'],
            component: ({ index, numbers }) => <Text className="column-text">{numbers[index] + 1}</Text>,
        },
        {
            name: 'kode item',
            value: 'kode_item',
            width: 140,
            children: ['-'],
            component: ({ value, toast }) => (
                <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor='pointer'>
                    <Text className="column-text">{value}</Text>
                </Box>
            ),
        },
        {
            name: 'nama item',
            width: 288,
            value: 'trade_name',
            children: ['-'],
            component: ({ value }) => (
                <Tooltip
                    label={value}
                    fontStyle={'normal'}
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'left'}
                    color="white"
                >
                    <Text className="column-text text-ellipsis">{value}</Text>
                </Tooltip>
            ),
        },
        {
            name: 'supplier',
            value: 'supplier_name',
            width: 180,
            children: ['-'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'UOM',
            value: 'uom',
            width: 75,
            children: ['-'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'baseline',
            value: 'baseline_pcs',
            width: 79,
            children: ['pcs'],
            component: ({ value }) => <Text className="column-text">{value}</Text>,
        },
        {
            name: 'status',
            width: 150,
            value: 'status',
            children: ['act/npd'],
            component: ({ value, dataItem, id }) => (
                <Box className="td-status-container">
                    {Boolean(dataItem?.demand_anticipation_id) &&
                    isAllowed({
                        pageName: 'Demand Anticipation',
                        permissionName: 'Edit Demand Anticipation',
                    }) ? (
                        <EditableStatus dataItem={dataItem} id={id} value={value} />
                    ) : (
                        <Text className="column-text">{value || '-'}</Text>
                    )}
                </Box>
            ),
        },
        {
            name: 'notes',
            width: 250,
            value: 'notes',
            children: ['Free Text'],
            component: ({ value, dataItem }) => <EditableNotes value={value} dataItem={dataItem} />,
        },
        {
            name: 'addtional order',
            width: 120,
            value: 'additional_order',
            children: ['pcs'],
            component: ({ value, dataItem }) => (
                <EditableColumn
                    currentValue={value}
                    demand_anticipation_id={dataItem?.demand_anticipation_id}
                    id={dataItem?.id}
                    isAdditional={true}
                />
            ),
        },
    ];

    const week = [];
    const month = [];
    for (let i = 0; i < materials.length; i++) {
        if (materials[i]?.demandPerWeek?.length > 0) {
            for (let j = 0; j < materials[i].demandPerWeek.length; j++) {
                week.push(materials[i].demandPerWeek[j]);
            }
        }
    }

    for (let i = 0; i < materials.length; i++) {
        if (materials[i]?.demandPerMonth?.length > 0) {
            for (let j = 0; j < materials[i].demandPerMonth.length; j++) {
                month.push(materials[i].demandPerMonth[j]);
            }
        }
    }

    if (month?.length) {
        const uniqueValues = new Set();
        const resultArray = [];
        for (const item of month) {
            const uniqueKey = `${item.month}_${item.year}`;
            if (!uniqueValues.has(uniqueKey)) {
                uniqueValues.add(uniqueKey);
                resultArray.push(item);
            }
        }
        const dMonth = resultArray.sort((a, b) => a.year - b.year || a.month - b.month);
        for (let i = 0; i < dMonth?.length; i++) {
            const obj = {
                name: `${dMonth[i].month}`,
                year: `${dMonth[i].year}`,
                width: 40,
                value: 'value_w',
                children: ['pcs'],
                component: ({ demandPerMonth }) => (
                    <Text className="column-text">
                        {findMonthYear(dMonth[i].month, dMonth[i].year, demandPerMonth) || '-'}
                    </Text>
                ),
            };
            formatTable.push(obj);
        }
    } else if (week?.length) {
        const uniqueValues = new Set();
        const resultArray = [];
        for (const item of week) {
            const uniqueKey = `${item.week_n}_${item.year}`;
            if (!uniqueValues.has(uniqueKey)) {
                uniqueValues.add(uniqueKey);
                resultArray.push(item);
            }
        }
        const dWeek = resultArray.sort((a, b) => a.year - b.year || a.week_n - b.week_n);
        for (let i = 0; i < dWeek?.length; i++) {
            const obj = {
                name: `W${dWeek[i].week_n}`,
                year: `${dWeek[i].year}`,
                width: 40,
                value: 'value_w',
                children: ['pcs'],
                component: ({ demandPerWeek, dataItem }) => (
                    <EditableColumn
                        currentWeek={dWeek[i].week_n}
                        currentYear={dWeek[i].year}
                        currentValue={findWeekYear(dWeek[i].week_n, dWeek[i].year, demandPerWeek)}
                        demand_anticipation_id={dataItem?.demand_anticipation_id}
                        id={dataItem?.id}
                    />
                ),
            };
            formatTable.push(obj);
        }
    } else {
        formatTable = tableList;
    }

    return formatTable;
};

export const fetchDataDemandAnticipation = async ({
    filter,
    page,
    setItems,
    setTotalData,
    setTotalPage,
    setIsLoading,
    selectedPerPage,
    setTable,
    jenisMaterial,
    supplier,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const { itemCode, trade_name, sortBy, status } = filter;

    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    let supplierFilter = supplier?.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            limit: selectedPerPage,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            showing: sortBy,
            status_id: status,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/demand-anticipation/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { materials, totalData, totalPage } = data.data;

        const formatTable = onFormatTable({ materials });

        setTable(formatTable);
        setTotalPage(totalPage);
        setTotalData(totalData);
        setItems(materials);
    } catch (error) {
        console.log(error);
        setTotalData(0);
        setTotalPage(0);
        setItems([]);
    } finally {
        setIsLoading(false);
    }
};

export const findWeekYear = (week, year, demandArray) =>
    demandArray?.find((item) => item.week_n === week && item.year === year)?.demand || '-';

export const findMonthYear = (month, year, demandArray) =>
    demandArray?.find((item) => item.month === month && item.year === year)?.demand || '-';
    
export const currentYearDemand = new Date().getFullYear();
export const pastYearDemand = currentYearDemand - 1;
