import React from 'react';
import { TableOrderConfirmationContext } from '../..';
import { Box, Tooltip, useToast } from '@chakra-ui/react';

import './styles.scss';
import { handleChange } from './function';

function Index({ item, value, errorHighlight = { has_eta_error: null, has_konfirmasi_pcs_error: null } }) {
    const { isEditColumn, setItemEdited } = React.useContext(TableOrderConfirmationContext);
    const toast = useToast();
    if (!isEditColumn) return null;
    return (
        <Tooltip hasArrow bg="red.500" label={errorHighlight?.has_konfirmasi_pcs_error}>
            <Box className="container-edit-column-order-confirmation">
                <input
                    value={value}
                    onChange={(i) =>
                        handleChange({ valueOnchange: i.target.value, selectedItem: item, setItemEdited, toast })
                    }
                    className="input-column"
                    type="number"
                />
            </Box>
        </Tooltip>
    );
}

export default Index;
