import React from 'react';
import { Box, Input, InputGroup, InputRightElement, Menu, MenuList, Spinner, Text, useToast } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { fetchDataInfinite, handleFocus } from './function';
import { debounce } from 'lodash';

import './styles.scss';

function InfiniteDataSelectNoPO({
    placeholderInput = 'text here',
    height = '48px',
    width = '100%',
    widthDropDownContainer = '706px',
    api = '/',
    apiParams = {},
    setFormData,
    formData,
    initialAttribute,
    dataResponse,
    searchData,
    setSearchData,
}) {
    const toast = useToast();
    const [data, setData] = React.useState([]);
    const [totalData, setTotalData] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [loadingUserTyping, setLoadingUserTyping] = React.useState(false);
    const [openInfiniteScroll, setOpenInfiniteScroll] = React.useState(false);
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const getMoreData = ({ hasMore, loadingOnUserTyping }) => {
        fetchDataInfinite({
            setLoadingSearch,
            setLoadingUserTyping,
            page,
            moreData: Boolean(hasMore),
            setTotalData,
            setData,
            setPage,
            searchDataName: searchData,
            toast,
            loadingOnUserTyping,
            api,
            apiParams,
            dataResponse,
        });
    };
    const handleClickDataName = (item) => {
        const { name, amount_total } = item || {};
        setFormData((prev) => ({ ...prev, no_po: name, qty_ospo: amount_total }));
        setSearchData(name);
        setOpenInfiniteScroll(false);
    };

    const handleChangeDebounceDataName = (text) => {
        setTotalData(1);
        setSearchData(text.target.value);
        setPage((prev) => {
            return 1;
        });
    };

    const debounceSearchDataName = React.useMemo(() => debounce(handleChangeDebounceDataName, 500), []);

    React.useEffect(() => {
        if (openInfiniteScroll) {
            getMoreData({
                hasMore: false,
                loadingOnUserTyping: true,
            });
        }
    }, [searchData, openInfiniteScroll]);
    return (
        <Box className="component-infinite-data-select-no-po">
            <Box className="infinite-scroll-container" position="relative">
                <InputGroup>
                    <Input
                        placeholder={placeholderInput}
                        className="input-data"
                        width={width}
                        onFocus={() => {
                            setSearchData(formData?.[initialAttribute]);
                            setPage(1);
                            handleFocus({
                                setOpenInfiniteScroll,
                            });
                        }}
                        value={formData?.[initialAttribute] || ''}
                        height={height}
                        onChange={(text) => {
                            ///https://stackoverflow.com/a/69584798/18038473
                            setFormData((prev) => ({ ...prev, no_po: text.target.value }));
                            debounceSearchDataName(text);
                        }}
                    />
                    <InputRightElement height={height} right="12px">
                        <Box className="divider_input_infinite_scroll" />
                        {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                    </InputRightElement>
                </InputGroup>
                <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                    <MenuList mt="50px" zIndex={20}>
                        {/* https://stackoverflow.com/a/73260330 */}
                        <div id="scrollableDiv" style={{ width: widthDropDownContainer }}>
                            <InfiniteScroll
                                className="scroll-box-container"
                                height={data.length > 1 ? '230px' : 'auto'}
                                dataLength={data.length}
                                next={() => getMoreData({ hasMore: true, loadingOnUserTyping: false })}
                                hasMore={data.length === totalData ? false : true}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    data.length < 9 ? null : <Text className="loading_fetch_next_page">Loading...</Text>
                                }
                                endMessage={null}
                            >
                                {data.length === 0 && !loadingSearch && (
                                    <Text className="no_data_infinite_scroll">Tidak Bisa Menemukan "{searchData}"</Text>
                                )}
                                {loadingUserTyping ? (
                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                ) : (
                                    data?.map((item, index) => {
                                        return (
                                            <Box
                                                onClick={() => handleClickDataName(item)}
                                                className="list_item_infinite"
                                                key={index}
                                            >
                                                {item?.name}
                                            </Box>
                                        );
                                    })
                                )}
                            </InfiniteScroll>
                        </div>
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
}

export default InfiniteDataSelectNoPO;
