import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const onSubmit = async ({
    setDisabledLoadingMain,
    keyAttribute,
    value,
    updatedValue,
    setIsLoading,
    setHasUpdated,
    id,
    setUpdatedValue,
    setRefetch,
    toast,
    setIsFocus,
}) => {
    setDisabledLoadingMain(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    let data = {
        [keyAttribute]: Number(value),
    };

    try {
        if (value !== updatedValue) {
            setIsLoading(true);
            setHasUpdated(false);
            await Axios({
                method: 'PATCH',
                url: `/stock-supplier/${id}`,
                headers: { Authorization: 'Bearer ' + userToken },
                data,
            }).then(() => {
                toast({
                    position: 'center-top',
                    description: 'Berhasil Meng-Update Data',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            });

            setUpdatedValue(value);
            setHasUpdated(true);
            setRefetch((prev) => !prev);
        }
    } catch (error) {
        toast({
            title: 'Error',
            description: error?.response?.data?.errors?.join(),
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
    } finally {
        setIsLoading(false);
        setIsFocus(false);
    }
};

export const onClickOutside = ({
    setDisabledLoadingMain,
    keyAttribute,
    value,
    updatedValue,
    setIsLoading,
    setHasUpdated,
    id,
    setUpdatedValue,
    setRefetch,
    toast,
    setIsFocus,
    isFocus,
}) => {
    if (isFocus && value !== updatedValue) {
        onSubmit({
            setDisabledLoadingMain,
            keyAttribute,
            value,
            updatedValue,
            setIsLoading,
            setHasUpdated,
            id,
            setUpdatedValue,
            setRefetch,
            toast,
            setIsFocus,
        });
    }

    if (isFocus) {
        setIsFocus(false);
    }
};
