import { isEmptyString } from '@helpers/functions';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import { read, utils } from 'xlsx';
import { columnConfig } from './const';

export const onChangeFile = ({ files, setPreviewData, setState, setFile, setDisabledSaveItem, toast }) => {
    if (files) {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e.target.result;
            let isErrorTemplateFile = false;
            const workbook = read(data, { type: 'array' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            worksheet['!ref'] = 'A1:AI1000';
            /// https://stackoverflow.com/a/54852654/18038473
            const json = utils.sheet_to_json(worksheet, { defval: null });
            if (Boolean(json?.length === 0)) {
                setDisabledSaveItem(true);
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, setidaknya terdapat 1 data',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
                setState('upload');
                return;
            }

            let jsonAfterCompile = [];
            for (let indexItem = 0; indexItem < json.length; indexItem++) {
                const keyAttribute = Object.keys(json[indexItem] || {});
                let newKey = {};
                for (let indexKey = 0; indexKey < keyAttribute?.length; indexKey++) {
                    if (!keyAttribute[indexKey]?.toLowerCase()?.includes('empty')) {
                        /// PENGECEKAN BILA FORMAT TEMPLATE TIDAK SESUAI DENGAN YANG DIHARAPKAN
                        if (!Boolean(columnConfig.find((item) => item.columnName === keyAttribute[indexKey]))) {
                            isErrorTemplateFile = true;
                        }
                        newKey[`${keyAttribute[indexKey]}`] = {
                            value: json[indexItem][keyAttribute[indexKey]],
                            isError: false,
                            messageError: null,
                        };
                    }
                }
                jsonAfterCompile.push(newKey);
            }
            if (isErrorTemplateFile) {
                toast({
                    title: 'Error !',
                    description: `File tidak bisa diupload, sepertinya file "${files?.name}" memiliki format yang salah. Cek kembali!`,
                    status: 'warning',
                    duration: 8000,
                    isClosable: true,
                    position: 'top-center',
                });
                setState('upload');
                isErrorTemplateFile = false;
                return;
            }
            let isErrorFile = false;
            for (let indexItem = 0; indexItem < jsonAfterCompile.length; indexItem++) {
                for (let indexAttribute = 0; indexAttribute < columnConfig.length; indexAttribute++) {
                    const value = jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName]?.value;
                    if (isEmptyString(value) && columnConfig[indexAttribute]?.isRequired) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].isError = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].messageError =
                            'Field ini tidak boleh kosong';
                    }
                    /// JIKA FOTMAT VALUE TIDAK SESUAI (HARUS NUMBER)
                    if (
                        !isEmptyString(value) &&
                        columnConfig[indexAttribute]?.type !== 'any' &&
                        typeof value !== columnConfig[indexAttribute]?.type
                    ) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].isError = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].messageError =
                            'Harus bertipe number';
                    }
                    /// JIKA FOTMAT VALUE TIDAK SESUAI (HARUS NUMBER)
                    if (!isEmptyString(value) && columnConfig[indexAttribute]?.type !== 'any' && value < 0) {
                        isErrorFile = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].isError = true;
                        jsonAfterCompile[indexItem][columnConfig[indexAttribute]?.columnName].messageError =
                            'Tidak boleh kurang dari 0';
                    }
                }
            }
            setPreviewData(() => {
                jsonAfterCompile?.sort((i) => {
                    let sortKey = 0;
                    for (const key in i) {
                        if (i[key]?.isError) {
                            sortKey = -1;
                        }
                    }
                    return sortKey;
                });
                return jsonAfterCompile;
            });
            setDisabledSaveItem(isErrorFile);
            setState('table-view');
            if (isErrorFile) {
                toast({
                    title: 'Error !',
                    description: 'File tidak bisa diupload, cek kembali!',
                    status: 'warning',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            }
            setFile(files);
        };
        reader.readAsArrayBuffer(files);
    }
};
export const handleSubmitUpload = async ({
    file,
    setSubmitSuccess,
    setConfirm,
    setIsLoadingUpload,
    setSubmitError,
    previewData,
    setPreviewData,
    setDisabledSaveItem,
}) => {
    setIsLoadingUpload(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const formData = new FormData();
    formData.append('file', file);
    try {
        await Axios({
            method: 'post',
            url: '/stock-supplier/upload',
            data: formData,
            headers: { Authorization: 'Bearer ' + userToken },
        })
            .then((res) => {
                let rowError =
                    res?.data?.data?.errors?.map((item) => {
                        const isErrorSupplierRef = Boolean(item?.message?.toLowerCase()?.includes('nama item'));
                        const isErrorKodeItem = Boolean(item?.message?.toLowerCase()?.includes('kode item'));
                        const isErrorSohSupplier = Boolean(item?.message?.toLowerCase()?.includes('soh supplier'));
                        const isErrorOnProcess = Boolean(item?.message?.toLowerCase()?.includes('on process'));
                        const isErrorUnauthorized = Boolean(item?.message?.toLowerCase()?.includes('unauthorized'));

                        return {
                            ...item,
                            columnName:
                                isErrorSupplierRef && isErrorKodeItem
                                    ? ['Nama Item', 'Kode Item']
                                    : isErrorSohSupplier && isErrorOnProcess
                                    ? ['SOH Supplier', 'On Process']
                                    : isErrorSupplierRef
                                    ? ['Nama Item']
                                    : isErrorKodeItem || isErrorUnauthorized
                                    ? ['Kode Item']
                                    : isErrorSohSupplier
                                    ? ['SOH Supplier']
                                    : isErrorOnProcess
                                    ? ['On Process']
                                    : [''],
                        };
                    }) || [];
                let clonePreviewData = [...previewData];
                const errorCompilation = [];
                rowError?.map((row) => {
                    errorCompilation.push(Number(row?.row));
                    row?.columnName?.map((col) => {
                        const value = clonePreviewData[Number(row?.row) - 1][col].value;
                        clonePreviewData[Number(row?.row) - 1][col].isError = true;
                        clonePreviewData[Number(row?.row) - 1][col].messageError = row?.message;
                        if (typeof value === 'number' && value > 0) {
                            clonePreviewData[Number(row?.row) - 1][col].isError = false;
                            clonePreviewData[Number(row?.row) - 1][col].messageError = null;
                        }
                    });
                    return row;
                });
                setPreviewData(() => {
                    clonePreviewData?.sort((i) => {
                        let sortKey = 0;
                        for (const key in i) {
                            if (i[key]?.isError) {
                                sortKey = -1;
                            }
                        }
                        return sortKey;
                    });
                    return clonePreviewData;
                });
                if (rowError?.length === clonePreviewData?.length) {
                    setDisabledSaveItem(true);
                    setSubmitError((prev) => ({
                        ...prev,
                        open: true,
                        type: 'then',
                        message: rowError,
                    }));
                } else {
                    setDisabledSaveItem(true);
                    setSubmitSuccess(() => {
                        const isSuccessWithNotes = Boolean(rowError?.length > 0);
                        return {
                            isOpen: true,
                            message: isSuccessWithNotes
                                ? 'Ada baris item yang gagal dibuat. Tutup modal ini untuk melihat detail.'
                                : '',
                        };
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setDisabledSaveItem(true);
                setSubmitError((prev) => ({
                    ...prev,
                    open: true,
                    type: 'catch',
                    message: err?.response?.data,
                }));
            })
            .finally(() => {
                setIsLoadingUpload(false);
                setConfirm(false);
            });
    } catch (error) {
        console.log(error);
    }
};
export const onDownloadTemplateUpload = async ({ setIsLoading, toast }) => {
    try {
        const cookies = new Cookies();
        const userToken = cookies.get('userToken');
        setIsLoading(true);
        const { data } = await Axios({
            method: 'GET',
            url: '/stock-supplier/template',
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });
        const fileName = 'template_upload_supplier_stock';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();
        await toast({
            position: 'center-top',
            description: 'Berhasil Download Template',
            status: 'success',
            duration: 5000,
            isClosable: true,
        });
    } catch (error) {
        const message = error?.response?.data?.errors?.length ? error?.response?.data?.errors[0]?.message : null;
        console.log(error);
        toast({
            position: 'top-center',
            description: message || 'Something wrong!',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    } finally {
        setIsLoading(false);
    }
};

export const AutomaticWidthTable = ({ previewData }) => {
    const currentData = previewData?.map((data) => {
        return Object.keys(data || {})?.map((item) => {
            const widthCell = item?.length < data?.[item]?.length ? data?.[item]?.length : item?.length;
            return {
                id: item,
                name: item,
                widthCell: widthCell,
            };
        });
    });
    const findlongestWidth = currentData
        .map((item) => ({ ...item, totalCellWidth: item?.map((value) => value.widthCell).reduce((a, b) => a + b) }))
        .sort((a, b) => b?.totalCellWidth - a?.totalCellWidth);
    let dataResult = [];
    const length = Object.keys(previewData?.[0] || {})?.length;
    for (let index = 0; index < length; index++) {
        dataResult.push(findlongestWidth?.[0][index]);
    }
    return dataResult;
};
