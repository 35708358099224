import MainDashboard from '@pages/mainDashboard';
import Login from '@pages/login';
import NotFound from '@pages/notFound';
import OrderPlanning from '@pages/orderPlanning';
import SkuConfiguration from '@pages/skuConfiguration';
import UserManagement from '@pages/userManagement';
import ErrorPage from '@pages/errorPage';
import StreamlineManagement from '@pages/streamlineManagement';
import AssetManagement from '@pages/assetManagement';
import MaterialConfiguration from '@pagesPhase2/materialConfiguration';
import DemandAnticipation from '@pagesPhase2/demandAnticipation';
import RawDataOrder from '@pagesPhase2/rawDataOrder';
import SupplierStock from '@pagesPhase2/supplierStock';
import RecapOrder from '@pages/recapOrder';
import OrderManagement from '@pagesPhase2/orderManagement';
import LobbyPage from '@pages/lobbyPage';
import UploadOrderPlanning from '@pages/orderPlanning/sections/sectionUpload';
import SectionAddnewItemSet from '@pagesPhase2/materialConfiguration/section/sectionAddNewItemSet';
import SectionAddnewItem from '@pagesPhase2/materialConfiguration/section/sectionAddNewItem';
import SectionOrderConfirmation from '@pagesPhase2/orderManagement/sections/sectionOrderConfirmation';
import SectionArchiveList from '@pagesPhase2/materialConfiguration/section/sectionArchiveList';
import SectionOrderDelivery from '@pagesPhase2/orderManagement/sections/sectionOrderDelivery';
import SectionRekapDelvery from '@pagesPhase2/rawDataOrder/section/sectionRekapDelivery';
import UploadMaterialItem from '@pagesPhase2/materialConfiguration/section/sectionUploadItem';
import AddMoreOrder from '@pagesPhase2/rawDataOrder/section/sectionAddMoreOrder';
import UploadSupplierStockItem from '@pagesPhase2/supplierStock/section/sectionUploadItem';
import UploadDemandAnticipationItem from '@pagesPhase2/demandAnticipation/section/sectionUploadItem';

export const routes = [
    {
        path: '/',
        element: <MainDashboard />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/lobby',
        element: <LobbyPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/login',
        element: <Login />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/order-planning',
        element: <OrderPlanning />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/order-planning/upload',
        element: <UploadOrderPlanning />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/order-management',
        element: <OrderManagement />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/sku-configuration',
        element: <SkuConfiguration />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/rekap-order',
        element: <RecapOrder />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/user-management',
        element: <UserManagement />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/streamline-management',
        element: <StreamlineManagement />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/asset-management',
        element: <AssetManagement />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/material-configuration',
        element: <MaterialConfiguration />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/demand-anticipation',
        element: <DemandAnticipation />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/demand-anticipation/upload-item',
        element: <UploadDemandAnticipationItem />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/raw-data-order',
        element: <RawDataOrder />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/raw-data-order/table-rekap-delivery',
        element: <SectionRekapDelvery />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/raw-data-order/add-more-order',
        element: <AddMoreOrder />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/raw-data-order/add-more-order/:id',
        element: <AddMoreOrder />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/supplier-stock',
        element: <SupplierStock />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/supplier-stock/upload-item',
        element: <UploadSupplierStockItem />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/add-new-item-material-config/:id',
        element: <SectionAddnewItem />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/add-new-item-material-config',
        element: <SectionAddnewItem />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/material-configuration/add-new-item-set',
        element: <SectionAddnewItemSet />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/material-configuration/upload-item',
        element: <UploadMaterialItem />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/order-management/table-order-confirmation',
        element: <SectionOrderConfirmation />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/order-management/table-order-delivery',
        element: <SectionOrderDelivery />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '/material-configuration/archive-list',
        element: <SectionArchiveList />,
        // errorElement: <ErrorPage />,
    },
    {
        path: '*',
        element: <NotFound />,
        errorElement: <ErrorPage />,
    },
];
