import { useState } from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';

import { SearchIcon } from '@pages/orderPlanning/icons';
import { ArrowRightIcon } from '@asset/icons';
import FieldMenu from '@components/fieldMenu';

import './styles.scss';
import { AddIcon } from '@pages/streamlineManagement/icons';
import { isAllowed } from '@helpers/roleAccess';

const SectionHead = ({ onChangeModalState, modalAttribute, onSearchHandler, filter, setFilter }) => {
    const { zoneList } = modalAttribute;

    const [searchKey, setSearchKey] = useState('');

    return (
        <Flex className="section-head__container__streamline-management" px="16px" pt="30px">
            <Flex>
                <Flex className="section-search__container__streamline-management" mr="16px">
                    <input
                        className="search-input"
                        placeholder="Cari streamline, line timbang........."
                        onChange={(e) => setSearchKey(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchHandler({ value: searchKey });
                            }
                        }}
                    />
                    <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchKey })}>
                        <SearchIcon />
                    </Box>
                </Flex>

                {zoneList && (
                    <FieldMenu
                        onClick={(value) => setFilter({ ...filter, zone: value })}
                        buttonName={filter?.zone?.name || 'Pilih Zona Streamline'}
                        menuList={[...zoneList, { name: 'All' }]}
                        keyName="name"
                        menuClassName="global-filter__component"
                        menuButtonClassName="global-filter-button__container"
                        icon={<ArrowRightIcon />}
                    />
                )}
            </Flex>
            {isAllowed({
                pageName: 'Streamline Management',
                permissionName: 'Add new streamline',
            }) && (
                <>
                    {zoneList ? (
                        <Box
                            className="button-add-streamline__container"
                            experimental_spaceX={2}
                            onClick={() => onChangeModalState({ value: 'modal-create' })}
                        >
                            <p>Add New Streamline</p>
                            <AddIcon />
                        </Box>
                    ) : (
                        <Skeleton w="166px" h="37px" borderRadius={4} />
                    )}
                </>
            )}
        </Flex>
    );
};

export default SectionHead;
