import { Box } from '@chakra-ui/react';
import { useState, createContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionHead from './section/sectionHead';
import SectionTable from './section/sectionTable';
import Pagination from '@components/pagination';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import { fetchRawDataOrder, onDownloadRawDataOrder } from './function';
import { tableList } from './const';
import { numToArr } from '@helpers/functions';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';

export const RawDataOrderContext = createContext({});

const RawDataOrder = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [section, setSection] = useState('main');
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [], eta_confirm_date: '', no_PO: '' });
    const [storeFilter, setStoreFilter] = useState({})
    const [rawData, setRawData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const handlePageClick = ({ selected }) => setPage(selected + 1);

    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);

    const handleEditRDO = (selectedData) => {
        navigate(`/raw-data-order/add-more-order/${selectedData?.id}`, {
            state: selectedData,
        });
    };

    const onDownloadData = () => {
        onDownloadRawDataOrder({
            jenisMaterial,
            dispatch,
            format_file: 'raw-data-order',
            fileName: 'download_raw_data_order',
            name: 'raw data order',
            title: 'Menyiapkan dowload Raw Data Order table',
            titleErr: 'Dowload Raw Data Order table gagal',
            filter,
            date: filter.eta_confirm_date,
            dateKey: 'eta_konfirmasi'
        });
    }

    useEffect(() => {
        if (jenisMaterial?.id) {
            fetchRawDataOrder({
                page,
                filter,
                setRawData,
                setIsLoading,
                setTotalData,
                setTotalPage,
                selectedPerPage,
                jenisMaterial,
                supplier,
            });
        }
    }, [page, selectedPerPage, filter, jenisMaterial, supplier]);
    return (
        <RawDataOrderContext.Provider
            value={{ filter, setFilter, storeFilter, setStoreFilter}}
        >
            {section?.includes('main') && (
                <Box className="raw-data-order__container">
                    <SectionHead section={section} setSection={setSection} onDownloadData={onDownloadData} />
                    {isLoading && (
                        <TableSkeleton
                            tableList={tableList}
                            count={numToArr(10)}
                            tableHeadHeight="50px"
                            tablColumnHeight="70px"
                        />
                    )}
                    {!isLoading && rawData.length > 0 && <SectionTable rawData={rawData} onEdit={handleEditRDO} />}
                    {!isLoading && !rawData.length && (
                        <EmptyState
                            description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                            text="Tidak ada data yg di tampilkan"
                        />
                    )}
                    <Pagination
                        onChangeLimit={() => {
                            setPage(1);
                        }}
                        page={page}
                        totalData={totalData}
                        totalPage={totalPage}
                        selectedPerPage={selectedPerPage}
                        setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                        onPageChange={handlePageClick}
                    />
                </Box>
            )}
        </RawDataOrderContext.Provider>
    );
};

export default RawDataOrder;
