import React, { useEffect } from 'react';
import { Box } from '@chakra-ui/layout';

import InputBatchBookingModal from './components/inputBatchBookingModal';
import TableSkeleton from '@components/tableSkeleton';
import { numToArr } from '@helpers/functions';
import EmptyState from '@components/emptyState';

import SectionHead from './sections/sectionHead';
import SectionTable from './sections/sectionTable';
import SectionFooter from './sections/sectionFooter';
import ModalCreateConfirmMaterial from './components/materialModal';
import ModalCustom from '@components/modalCustom';
import { IconWarning } from './icons';
import { fetchOrderPlanningHandler } from './function';

import './styles.scss';
import { tableList } from './const';
import { useSelector } from 'react-redux';

export const OrderPlanningContext = React.createContext();

const OrderPlanning = () => {
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);

    const [showCreateOrderPlanning, setIsShowCreateOrderPlanningModal] = React.useState(false);
    const [showInputBatchBooking, setShowInputBatchBooking] = React.useState(false);
    const [showPauseModal, setShowPauseModal] = React.useState(false);
    const [selectedOrderPlanning, setSelecteOrderPlaning] = React.useState([]);

    const [orderPlanning, setOrderPlanning] = React.useState([]);
    const [totalData, setTotalData] = React.useState(0);
    const [totalPage, setTotalPage] = React.useState(0);
    const [sortName, setSortName] = React.useState({ sort: 'nama_item', typeBol: true });
    const [page, setPage] = React.useState(1);
    const [isLoading, setIsLoading] = React.useState(true);
    const [searchQuery, setSearchQuery] = React.useState('');

    const togglerCreateOrderPlanning = () => setIsShowCreateOrderPlanningModal(!showCreateOrderPlanning);
    const togglerInputBatchBooking = () => setShowInputBatchBooking(!showInputBatchBooking);
    const togglerShowPauseModal = () => setShowPauseModal(!showPauseModal);

    const onSelectedOrderPlanning = ({ value }) => setSelecteOrderPlaning(value);

    const onSearchHandler = ({ value }) => setSearchQuery(value);

    const onResetFilter = () => {
        setPage(1);
        setSearchQuery('');
    };

    const handlePageClick = ({ selected }) => setPage(selected + 1);

    const reFetchOrderPlanningHandler = () => {
        page === 1 && searchQuery === ''
            ? fetchOrderPlanningHandler({
                  selectedMaterial,
                  searchQuery,
                  setOrderPlanning,
                  setTotalData,
                  setTotalPage,
                  setIsLoading,
                  sortName,
                  page,
              })
            : onResetFilter();
    };

    useEffect(() => {
        fetchOrderPlanningHandler({
            selectedMaterial,
            searchQuery,
            setOrderPlanning,
            setTotalPage,
            setTotalData,
            setIsLoading,
            page,
            sortName,
        });
    }, [page, searchQuery, setOrderPlanning, selectedMaterial, sortName]);

    return (
        <OrderPlanningContext.Provider
            value={{
                togglerCreateOrderPlanning,
                togglerInputBatchBooking,
                togglerShowPauseModal,
                onSelectedOrderPlanning,
                setOrderPlanning,
            }}
        >
            <Box className="order-planning__container">
                <SectionHead onSearchHandler={onSearchHandler} />

                {!isLoading && <SectionTable setSortName={setSortName} orderPlanning={orderPlanning} />}
                {!isLoading && orderPlanning?.length === 0 && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan, silakan memilih kategori yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}

                {isLoading && (
                    <TableSkeleton
                        tableList={tableList}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="46px"
                    />
                )}

                <SectionFooter
                    page={page}
                    totalData={totalData}
                    totalPage={totalPage}
                    handlePageClick={handlePageClick}
                    count={orderPlanning?.length}
                />

                {/* -------- CREATE MATERIAL -------- */}
                <ModalCreateConfirmMaterial
                    isOpen={showCreateOrderPlanning}
                    onClose={togglerCreateOrderPlanning}
                    orderPlanning={orderPlanning}
                    selectedOrderPlanning={selectedOrderPlanning}
                    reFetchOrderPlanningHandler={reFetchOrderPlanningHandler}
                />

                {/* -------- INPUT BATCH BOOKING -------- */}

                <InputBatchBookingModal
                    isOpen={showInputBatchBooking}
                    onClose={togglerInputBatchBooking}
                    orderPlanning={orderPlanning}
                    selectedOrderPlanning={selectedOrderPlanning}
                    reFetchOrderPlanningHandler={reFetchOrderPlanningHandler}
                />

                {/** ------------ PAUSE BATCH BOOKING ------- */}
                <ModalCustom
                    icon={IconWarning()}
                    isOpen={showPauseModal}
                    onClose={togglerShowPauseModal}
                    reFetchOrderPlanningHandler={reFetchOrderPlanningHandler}
                    title="Pause Produksi"
                    confirmText="Pause"
                    confirmColor="#2D2D2D"
                    confirmBg="white"
                    cancelColor="#FFFFFF"
                    cancelBg="#2D2D2D"
                    cancelText="Cancel"
                    descriptionText="Apakah anda yakin ingin  memberhentikan produksi yang sedang berlangsung ?"
                />
            </Box>
        </OrderPlanningContext.Provider>
    );
};

export default OrderPlanning;
