import * as ExcelJS from 'exceljs';
import Axios from '@services/axios';
import Cookies from 'universal-cookie';
import queryString from 'query-string';
import { onDownloadGlobalHandler } from '@store/list/downloadManagement';

export const fetchDataOrderManagement = async ({
    page,
    filter,
    setOrders,
    setIsLoading,
    setTotalData,
    setTotalPage,
    selectedPerPage,
    jenisMaterial,
    supplier,
    setResumeOrder,
    sort
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    setIsLoading(true);
    const supplierFilter = supplier?.join(';');
    const { itemCode, trade_name, status_id, kanban_card, order_card } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    const filterStringify = queryString.stringify(
        {
            page,
            status_id,
            kanban_card,
            order_card,
            limit: selectedPerPage,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            sort
        },
        { skipEmptyString: true }
    );
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/order-management/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const { orderManagement, resumeOrder, totalData, totalPage } = data.data;
        setOrders(orderManagement);
        setTotalData(totalData);
        setTotalPage(totalPage);
        setResumeOrder(resumeOrder);
    } catch (error) {
        console.log(error);
        setOrders([]);
        setTotalData(0);
        setTotalPage(0);
        setResumeOrder([]);
    } finally {
        setIsLoading(false);
    }
};

export const onDownloadOrderManagement = async ({ dispatch, supplier, jenisMaterial, filter }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const downloadId = Date.now() * Math.floor(Math.random() * 100000);

    dispatch(
        onDownloadGlobalHandler({
            name: 'order management',
            type: 'loading',
            id: downloadId,
            title: 'Menyiapkan dowload data Order Management table',
        })
    );
    const supplierFilter = supplier?.join(';');
    const { itemCode, trade_name, status_id, kanban_card, order_card } = filter;
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    const filterStringify = queryString.stringify(
        {
            status_id,
            kanban_card,
            order_card,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
        },
        { skipEmptyString: true }
    );

    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/order-management/download?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
            responseType: 'blob',
        });

        const fileName = 'download_data_order_management';
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.click();

        dispatch(
            onDownloadGlobalHandler({
                name: 'order management',
                type: 'done',
                id: downloadId,
            })
        );
    } catch (error) {
        console.log(error);
        dispatch(
            onDownloadGlobalHandler({
                name: 'order management',
                type: 'error',
                title: 'Dowload data Order Management table gagal',
                id: downloadId,
            })
        );
    }
};

export const getColorHandler = ({ status }) => {
    switch (status) {
        case 'Urgent':
            return '#FDBDC3';
        case 'Order':
            return '#CCE2FF';
        case 'Ok':
            return '#D9F2E3';
        default:
            return '#0000';
    }
};

export const getColorTextHandler = ({ status }) => {
    switch (status) {
        case 'Urgent':
            return '#EA4F4F';
        case 'Order':
            return '#004199';
        case 'Ok':
            return '#267346';
        default:
            return '#0000';
    }
};

export const numberToFixedOM = (num) => {
    if (typeof num === 'number' || num === 0) {
        return num % 1 !== 0 ? num.toFixed(2) : num;
    } else {
        return '-';
    }

};



const onDataFormattedExcel = ({ data = [] }) => {
    const newData = [];
    const headers = [];
    data.forEach((v) => {
        newData.push({
            STATUS: v.status || '-',
            'KODE ITEM': v.kode_item || '-',
            'NAMA ITEM': v.nama_item || '-',
            'KANBAN CARD': v.kanban_card || '-',
            'ORDER CARD': v.order_card || '-',
            'BASELINE PER DAY': v.baseline_perday || '-',
            ROP: v.rop || '-',
            'MAX STOCK': v.max_stock || '-',
            OSPO: v.ospo || '-',
            'OSPO UPDATE': v.ospo_update || '-',
            'OSPO PTI': v.ospo_pti || '-',
            'ORDER ( PCS )': v.order_pcs || '-',
            'ORDER ( KANBAN )': v.order_kanban || '-',
            'IN PREPARATION ( PCS )': v.preparation_pcs || '-',
            'IN PREPARATION ( KANBAN )': v.preparation_kanban || '-',
            'ON DELIVERY ( PCS )': v.delivery_pcs || '-',
            'ON DELIVERY ( KANBAN )': v.delivery_kanban || '-',
            'QUALITY CHECKING ( PCS )': v.quality_checking_pcs || '-',
            'QUALITY CHECKING ( KANBAN )': v.quality_checking_kanban || '-',
        });
    });

    return { newData, headers };
};

export const onExportExcelOrderManagement = ({ setIsLoadingDownload }) => {
    setIsLoadingDownload(true);
    const dummyDatas = [
        {
            status: 'OK',
            kode_item: 12345678,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            kanban_card: 12345,
            order_card: 12345,
            baseline_perday: '150,000',
            rop: '220,000',
            max_stock: '402,000',
            ospo: '800,000',
            ospo_update: '620,000',
            ospo_pti: '620,000',
            order_pcs: '620,000',
            order_kanban: 42,
            preparation_pcs: '800,000',
            preparation_kanban: 'PO000123',
            delivery_pcs: '800,000',
            delivery_kanban: 8,
            quality_checking_pcs: '800,000',
            quality_checking_kanban: 8,
        },
        {
            status: 'OK',
            kode_item: 12345678,
            nama_item: 'Godet Two Way Cake 5,4 cm Bulat SM (pc(s))',
            kanban_card: 12345,
            order_card: 12345,
            baseline_perday: '150,000',
            rop: '220,000',
            max_stock: '402,000',
            ospo: '800,000',
            ospo_update: '620,000',
            ospo_pti: '620,000',
            order_pcs: '620,000',
            order_kanban: 42,
            preparation_pcs: '800,000',
            preparation_kanban: 'PO000123',
            delivery_pcs: '800,000',
            delivery_kanban: 8,
            quality_checking_pcs: '800,000',
            quality_checking_kanban: 8,
        },
    ];

    const { newData, headers } = onDataFormattedExcel({ data: dummyDatas });
    Object.keys(newData[0]).forEach((v) => {
        headers.push(v);
    });
    // INITILIZE EXCELJS
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    worksheet.addRow(headers);

    // looping for value excel
    newData.forEach((item, _idx) => {
        worksheet.addRow(Object.values(item));
    });
    // set width for each column
    for (let i = 1; i <= worksheet.columnCount; i++) {
        worksheet.getColumn(i).width = 52;
    }

    // set bold for header
    const selectedRows = [];
    worksheet.eachRow((row, rowNumber) => {
        if ([1].indexOf(rowNumber) !== -1) {
            selectedRows.push(row);
        }
    });

    selectedRows.forEach((row) => {
        row.eachCell((cell) => {
            cell.font = {
                bold: true,
            };
            cell.alignment = { horizontal: 'center' };
        });
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `order_management_${new Date().toDateString()}.xlsx`;
        link.click();
        setIsLoadingDownload(false);
    });
};
