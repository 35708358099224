import { Box } from '@chakra-ui/react';
import TableHead from '../../component/tableHead';
import TableColumn from '../../component/tableColumn';
import './styles.scss';

const SectionTable = ({ users, roleList }) => {
    return (
        <Box className="section-table__container__user-management">
            <TableHead roleList={roleList} />
            <Box className="columns-container" bg="white">
                {users?.map((user, idx) => (
                    <TableColumn key={idx} user={user} />
                ))}
            </Box>
        </Box>
    );
};

export default SectionTable;
