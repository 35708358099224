import { Flex, Box, Text } from '@chakra-ui/react';
import { tableListOrderConfirmation } from '../../const';
import './styles.scss';
import React from 'react';
import { TableOrderConfirmationContext } from '../..';

const TableHead = () => {
    const { isEditColumn } = React.useContext(TableOrderConfirmationContext);
    return (
        <Flex className="table-head__container__order_confirmation">
            {tableListOrderConfirmation.map((head) => {
                if (head.children) {
                    return (
                        <Box className="table-head__item" flexDirection="column" width={head.width} key={head.name}>
                            <Flex className="table-head__parent" w="fit-content" pl="6px">
                                {head.name}
                            </Flex>
                            <Flex justify="space-between" w="100%">
                                {head.children.map((child, index) => {
                                    return (
                                        <Box key={index} className="table-head__child-container">
                                            <Box className="table-head__child" display="flex" justifyContent="center">
                                                <Text>{child}</Text>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Flex>
                        </Box>
                    );
                } else {
                    return <></>;
                }
            })}

            {isEditColumn && (
                <Box className="table-head__item_action_btn">
                    <Text className="text-action">Action</Text>
                </Box>
            )}
        </Flex>
    );
};

export default TableHead;
