import { Flex, Box, Button, useToast } from '@chakra-ui/react';
import { tableListOrderConfirmation } from '../../const';
import './styles.scss';
import { TableOrderConfirmationContext } from '../..';
import { handleReset } from './function.js';
import React from 'react';

const TableColumn = ({ dataOrderConfirmation }) => {
    const toast = useToast();
    const { isEditColumn, itemEdited, setItems, setItemEdited } = React.useContext(TableOrderConfirmationContext);
    const hasEdited = itemEdited?.find((item) => item?.id === dataOrderConfirmation?.id);

    return (
        <Flex className="table-column table-column__container__order_confirmation">
            {tableListOrderConfirmation.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: dataOrderConfirmation[item.value] !== null ? dataOrderConfirmation[item.value] : '-',
                            id: dataOrderConfirmation?.id,
                            item: dataOrderConfirmation,
                            isEditColumn,
                            toast,
                            hasEdited,
                        })}
                    </Box>
                );
            })}
            {isEditColumn && (
                <Flex className="action-btn">
                    <Button
                        isDisabled={!Boolean(hasEdited)}
                        className="reset-btn"
                        onClick={() =>
                            handleReset({ setItems, itemEdited, selectedItem: dataOrderConfirmation, setItemEdited })
                        }
                    >
                        Reset
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default TableColumn;
