export const NoteIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 5.5C6 5.22386 6.22386 5 6.5 5H11.5L14 7.5V14.5C14 14.7761 13.7761 15 13.5 15H6.5C6.22386 15 6 14.7761 6 14.5V5.5Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path d="M8 9H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 11H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="10" cy="10" r="9.5" stroke="#1746A2" />
    </svg>
);

export const EditDateIcon = ({ size = '20' }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.33136 14.875L7.45262 14.8751L15.2308 7.0969L13.1095 4.97559L5.3313 12.7538L5.33136 14.875Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path
            d="M10.988 7.09668L13.1094 9.218"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="10" cy="10" r="9.5" stroke="#1746A2" />
    </svg>
);

export const CalendarIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12 1.5V4.5" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 1.5V4.5" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.25 7.5H15.75" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const SortHeaderIcon = () => (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 7H0L3 10V0H2V7ZM5 1.5V10H6V3H8L5 0V1.5Z" fill="black" />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);
