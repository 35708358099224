import { useSelector } from 'react-redux';
import { useState, createContext, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import SectionTable from '../sectionOrderDelivery/sections/sectionTable';
import SectionHead from '../sectionOrderDelivery/sections/sectionHead';
import TableSkeleton from '@components/tableSkeleton';
import Pagination from '@components/pagination';
import ModalSuccess from '@components/modalSuccess';
import EmptyState from '@components/emptyState';
import { tableListOrderDelivery } from './const';
import { fetchDataOrderDelivery, handleReplaceItem } from './function';
import { isEmptyString, numToArr } from '@helpers/functions';
import './styles.scss';

export const tableOrderDeliveryContext = createContext();

const SectionOrderDelivery = ({ goBack }) => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [], inputDate: '' });
    const [orderDelivery, setOrderDelivery] = useState([]);
    const [itemEdited, setItemEdited] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [refetch, setRefetch] = useState(0);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);

    useEffect(() => {
        if (Boolean(jenisMaterial?.id) && !isEmptyString(filter.inputDate)) {
            fetchDataOrderDelivery({
                setOrderDelivery,
                setIsLoading,
                setTotalData,
                setTotalPage,
                selectedPerPage,
                jenisMaterial,
                supplier,
                setPage,
                filter,
                page,
                refetch,
                itemEdited,
                isEdit,
            });
        }
    }, [jenisMaterial, supplier, selectedPerPage, page, filter, refetch]);

    useEffect(() => {
        if (Boolean(itemEdited?.toString())) {
            handleReplaceItem({ itemEdited, setOrderDelivery });
        }
    }, [itemEdited, page, isLoading, selectedPerPage]);

    console.log(orderDelivery, '<<<');
    return (
        <tableOrderDeliveryContext.Provider
            value={{
                totalData,
                filter,
                setFilter,
                isEdit,
                setIsEdit,
                orderDelivery,
                setOrderDelivery,
                setRefetch,
                itemEdited,
                setItemEdited,
            }}
        >
            <Box className="section-table-order-delivery__configuration">
                <SectionHead goBack={goBack} />
                {!isLoading && orderDelivery.length > 0 && <SectionTable orderDelivery={orderDelivery} />}
                {!isLoading && !orderDelivery.length && (
                    <EmptyState
                        description={`Maaf, tidak ada data yang bisa ditamplikan,silakan memilih ${
                            isEmptyString(filter.inputDate)
                                ? 'tanggal ETA terlebih dahulu.'
                                : 'tanggal ETA atau Role yang lain.'
                        }`}
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                {isLoading && (
                    <TableSkeleton
                        tableList={tableListOrderDelivery}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                <Box className="pagination-footer">
                    <Pagination
                        onChangeLimit={() => {
                            setPage(1);
                        }}
                        page={page}
                        totalPage={totalPage}
                        totalData={totalData}
                        selectedPerPage={selectedPerPage}
                        setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                        onPageChange={handlePageClick}
                    />
                </Box>
                <ModalSuccess
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                    title="File Berhasil Diupload"
                    labelButton="Kembali"
                />
            </Box>
        </tableOrderDeliveryContext.Provider>
    );
};

export default SectionOrderDelivery;
