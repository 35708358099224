import React, { useEffect, useState } from 'react';
import { Box, Popover, PopoverContent, PopoverTrigger, Button, Flex, Text, Image } from '@chakra-ui/react';
import FieldMenu from '@components/fieldMenu';
import { dateDummy } from '@pages/recapOrder/const';
import { SearchIcon, ArrowTopIcon, CalendarIcon, PdfIcon } from '@pages/recapOrder/icons';
import { formatDatePicker } from './function';
import imageIcon from './CsvIcon.png';
import SectionFilterStreamline from './sectionFilterStreamline';
import DatePicker from 'react-date-picker';
import { isAllowed } from '@helpers/roleAccess';
import useGetFeatureBySediaan from 'hooks/useGetFeatureBySediaan';

import './styles.scss';

const SectionHead = ({ onSearchHandler, setFilter, filter }) => {
    /// access download exel
    const { hasAccess, selectedMaterial, userProfile } = useGetFeatureBySediaan();
    ///
    const hasAccessDownloadExel = React.useMemo(() => {
        const inputProblemAccess = userProfile?.role === 'PPC';
        const isBedaPlant =
            !isAllowed({
                pageName: 'Main Dashboard',
                permissionName: 'Input kendala',
            }) || hasAccess;
        return inputProblemAccess
            ? isBedaPlant
            : isAllowed({
                  pageName: 'Main Dashboard',
                  permissionName: 'Input kendala',
              });
    }, [selectedMaterial?.id]);
    const [searchKey, setSearchKey] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fullDate, setFullDate] = useState('');
    useEffect(() => {
        if (startDate !== null) {
            setFullDate(formatDatePicker(startDate));
        }
        if (endDate !== null && startDate != null) {
            const date = formatDatePicker(startDate) + ' - ' + formatDatePicker(endDate);
            setFullDate(date);
        }
    }, [startDate, endDate]);
    return (
        <>
            <Flex className="section-head__container__order-recap">
                <Box display={'flex'} alignItems={'center'}>
                    <Box className="section-left section-search__container__order-recap">
                        <input
                            className="search-input"
                            placeholder="Cari nama dan email...."
                            onChange={(e) => setSearchKey(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    onSearchHandler({ value: searchKey });
                                }
                            }}
                        />
                        <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchKey })}>
                            <SearchIcon />
                        </Box>
                    </Box>

                    <Flex alignItems={'center'} mr={'10px'}>
                        {dateDummy && (
                            <FieldMenu
                                onClick={(value) => setFilter({ ...filter, date: value })}
                                buttonName={filter?.date?.date || 'Last 7 Days'}
                                menuList={[...dateDummy]}
                                keyName="date"
                                menuClassName="global-filter__component"
                                menuButtonClassName="field-filter-date__container"
                                icon={<ArrowTopIcon />}
                            />
                        )}
                        {/* === Filter By Date === */}
                        <Box
                            className={`filter-date__container ${
                                filter?.date?.date !== 'Custome date' && 'active-display'
                            }`}
                            mx={'12px'}
                        >
                            {filter?.date?.date === 'Custome date' && (
                                <>
                                    <Box className="filter-date-input">
                                        <Flex>
                                            {fullDate === '' ? (
                                                <Text color={'#D7D7D7'}>Filter by date</Text>
                                            ) : (
                                                <Text color={'#2D2D2D'}>{`${fullDate}`}</Text>
                                            )}
                                        </Flex>
                                    </Box>
                                    {/* === Filter By Date === */}
                                    <Box>
                                        <Popover position={'relative'}>
                                            <PopoverTrigger>
                                                <Button className="btn-icon">
                                                    <Box>
                                                        <CalendarIcon />
                                                    </Box>
                                                </Button>
                                            </PopoverTrigger>
                                            <PopoverContent className="calendar-content">
                                                {/* ===== Date Picker Start Date ===== */}
                                                <Box>
                                                    <Text className="text-content">Start date</Text>
                                                    <DatePicker
                                                        value={startDate}
                                                        onChange={setStartDate}
                                                        className="date-picker-content"
                                                        clearIcon={null}
                                                        calendarIcon={<CalendarIcon />}
                                                        calendarClassName="calendar-first"
                                                        format="dd/MM/yyyy"
                                                        dayPlaceholder="DD"
                                                        monthPlaceholder="MM"
                                                        yearPlaceholder="YYYY"
                                                    />
                                                    {/* ===== Date Picker End Date ===== */}
                                                    <Text className="text-content" mt={'20px'}>
                                                        End date
                                                    </Text>
                                                    <DatePicker
                                                        value={endDate}
                                                        onChange={setEndDate}
                                                        className="date-picker-content"
                                                        clearIcon={null}
                                                        calendarIcon={<CalendarIcon />}
                                                        calendarClassName="calendar-second"
                                                        format="dd/MM/yyyy"
                                                        dayPlaceholder="DD"
                                                        monthPlaceholder="MM"
                                                        yearPlaceholder="YYYY"
                                                    />
                                                </Box>
                                                <Flex mt={'38px'} justifyContent={'flex-end'}>
                                                    <Button className="btn-reset">reset</Button>
                                                    <Button className="btn-apply">apply</Button>
                                                </Flex>
                                            </PopoverContent>
                                        </Popover>
                                    </Box>
                                </>
                            )}
                        </Box>
                        {/* === Content Filter Streamline === */}
                        {isAllowed({ pageName: 'Rekap Order', permissionName: 'Filtering streamline' }) && (
                            <Box marginLeft={filter?.date?.date !== 'Custome date' && '12px'}>
                                <SectionFilterStreamline />
                            </Box>
                        )}
                    </Flex>
                </Box>

                <Box className="section-right">
                    <Popover>
                        <PopoverTrigger cursor={'pointer'}>
                            <Button className="button-download">
                                <Text pr={'12px'}>Download</Text>
                                <ArrowTopIcon />
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent w={'110px'}>
                            <Box className="menu-item" borderTopRadius={'4px'}>
                                <PdfIcon />
                                <Text className="menu-item-text">PDF</Text>
                            </Box>
                            {isAllowed({ pageName: 'Rekap Order', permissionName: 'Export as Excel' }) &&
                            hasAccessDownloadExel ? (
                                <Box className="menu-item" borderBottomRadius={'4px'}>
                                    <Image src={imageIcon} w={'16px'} h={'16px'} />
                                    <Text className="menu-item-text">CSV</Text>
                                </Box>
                            ) : null}
                        </PopoverContent>
                    </Popover>
                </Box>
            </Flex>
        </>
    );
};

export default SectionHead;
