import { useState, createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@chakra-ui/react';
import SectionHead from '../sectionRekapDelivery/sections/sectionHead';
import SectionTable from '../sectionRekapDelivery/sections/sectionTable';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import { tableListRekapDelivery } from './const';
import Pagination from '@components/pagination';
import { numToArr } from '@helpers/functions';
import { fetchDataDeliveryRecap } from './function';
import './styles.scss';
import { onDownloadRawDataOrder } from '@pagesPhase2/rawDataOrder/function';

export const TableRekapDeliveryContext = createContext();

const SectionRekapDelvery = () => {
    const dispatch = useDispatch();
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [deliveryRecap, setDeliveryRecap] = useState([]);
    const [refetch, setRefetch] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [filter, setFilter] = useState({ itemCode: [], trade_name: [], eta_update_date: '', no_PO: '' });
    const [storeMultipleFilter, setStoreMutlipleFilter] = useState({})
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const handlePageClick = ({ selected }) => setPage(selected + 1);

    useEffect(() => {
        if (Boolean(supplier?.length !== 0) || jenisMaterial?.id) {
            setPage(1);
        }
    }, [supplier?.length, jenisMaterial?.id]);

    const onDownloadRekapDelivery = () => {
        onDownloadRawDataOrder({
            jenisMaterial,
            dispatch,
            format_file: 'rekap-delivery',
            fileName: 'download_rekap_delivery',
            name: 'rekap delivery',
            title: 'Menyiapkan dowload Rekap Delivery table',
            titleErr: 'Dowload Rekap Delivery table gagal',
            filter,
            date: filter.eta_update_date,
            dateKey: 'eta_update'
        });
    }

    useEffect(() => {
        if (jenisMaterial?.id) {
            fetchDataDeliveryRecap({
                filter,
                page,
                setDeliveryRecap,
                setTotalData,
                setTotalPage,
                setIsLoading,
                selectedPerPage,
                jenisMaterial,
                supplier,
            });
        }
    }, [page, filter, selectedPerPage, refetch, jenisMaterial, supplier]);
    return (
        <TableRekapDeliveryContext.Provider
            value={{ filter, setFilter, setPage, refetch, setRefetch, storeMultipleFilter, setStoreMutlipleFilter }}
        >
            <Box className="section-table-rekap-delivery__configuration">
                <SectionHead onDownload={onDownloadRekapDelivery} />
                {!isLoading && deliveryRecap.length > 0 && (<SectionTable deliveryRecap={deliveryRecap} />)}
                {!isLoading && !deliveryRecap.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                {isLoading && (
                    <TableSkeleton
                        tableList={tableListRekapDelivery}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                <Pagination
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />
            </Box>
        </TableRekapDeliveryContext.Provider>
    );
};

export default SectionRekapDelvery;
