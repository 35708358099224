import { useEffect, useState } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Flex,
    Checkbox,
    Spinner,
} from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import { SearchIcon, CloseIcon as CloseIconBlue } from './icons';
import { handleCheckboxChange, fetchCodeItemInfinite } from './function';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import './styles.scss';

function Index({
    isOpen,
    onClose,
    codeItem,
    setCodeItem,
    codeItemChoosed,
    setCodeItemChoosed,
    handleApplyFilter,
    handleResetFilter,
    outlineLabel,
    modalTitle,
    labelSearch,
    labelSelectedData,
    loadingSearch,
    setLoadingSearch,
    refetch,
    search,
    setSearch,
    page,
    setPage,
}) {
    const { supplierRef, jenisMaterial } = useSelector((reducer) => reducer.globalFilterSlice);
    const [totalData, setTotalData] = useState(0);

    useEffect(() => {
        if (isOpen) {
            fetchCodeItemInfinite({
                page: supplierRef && jenisMaterial ? 1 : page,
                setCodeItem,
                codeItem,
                setPage,
                setTotalData,
                search,
                codeItemChoosed,
                setLoadingSearch,
                supplierRef,
                jenisMaterial,
            });
        }
    }, [refetch, supplierRef, jenisMaterial, isOpen]);

    const getMoreData = ({ moreData }) => {
        fetchCodeItemInfinite({
            page,
            setCodeItem,
            codeItem,
            setPage,
            setTotalData,
            moreData: Boolean(moreData),
            search,
            codeItemChoosed,
            setLoadingSearch,
            supplierRef,
            jenisMaterial,
        });
    };

    const onSearchHandler = () => {
        fetchCodeItemInfinite({
            page: 1,
            setCodeItem,
            codeItem,
            setPage,
            setTotalData,
            search,
            codeItemChoosed,
            setLoadingSearch,
            supplierRef,
            jenisMaterial,
        });
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box className="modal-container-filter-item-code-component">
                <ModalOverlay />
                <ModalContent maxW="544px">
                    <Flex className="modal-filter-name-header">
                        <Text className="modal-header-text">{modalTitle}</Text>

                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Text className="label-input">{labelSearch}</Text>
                        <Box className="box-selected-item">
                            {/* stackoverflow start */}
                            {/* https://stackoverflow.com/a/45014215 */}
                            {/* issue : https://stackoverflow.com/questions/45014094/how-do-i-fix-expected-to-return-a-value-at-the-end-of-arrow-function-warning */}
                            {/* answer by Kris Selbekk : https://stackoverflow.com/users/1064572/kris-selbekk */}
                            {codeItemChoosed
                                ?.filter((item) => item.checked)
                                ?.map((item, index) => {
                                    return (
                                        <Flex key={index} className="box-card-selected-item">
                                            <Text className="text-selected-item">{item?.kode_item}</Text>
                                            <Box
                                                cursor="pointer"
                                                onClick={() =>
                                                    handleCheckboxChange({
                                                        checked: false,
                                                        selectedData: item,
                                                        codeItem,
                                                        setCodeItem,
                                                        codeItemChoosed,
                                                        setCodeItemChoosed,
                                                    })
                                                }
                                            >
                                                <CloseIconBlue />
                                            </Box>
                                        </Flex>
                                    );
                                })}
                            {/* stackoverflow end */}
                        </Box>
                        <Text className="label-input">{labelSelectedData}</Text>

                        <Box position="relative" mt="16px">
                            <input
                                className="input-box"
                                placeholder="cari nama item"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchHandler();
                                    }
                                }}
                            />
                            <Box className="search-icon" onClick={onSearchHandler}>
                                {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                            </Box>
                        </Box>
                        <Text className="label-list-item-text">List nama</Text>
                        <Box className="container-list-item">
                            <div id="scrollableDiv">
                                <InfiniteScroll
                                    height={100}
                                    dataLength={codeItem.length}
                                    next={() => getMoreData({ moreData: true })}
                                    hasMore={codeItem.length === totalData ? false : true}
                                    scrollableTarget="scrollableDiv"
                                    loader={
                                        codeItem.length < 9 ? null : (
                                            <Text className="loading_fetch_next_page">Loading...</Text>
                                        )
                                    }
                                    endMessage={
                                        codeItem.length < 1 ? (
                                            <Text className="loading_fetch_next_page">Loading...</Text>
                                        ) : null
                                    }
                                >
                                    {codeItem.length === 0 && !loadingSearch ? (
                                        <Text className="no_data_infinite_scroll">Kode item tidak tersedia</Text>
                                    ) : (
                                        <Box>
                                            {codeItem?.map((item, index) => {
                                                return (
                                                    <Flex key={index} justifyContent="space-between" pb="10px">
                                                        <Text className="list-item-text">
                                                            {item?.kode_item || 'Kode Item'}
                                                        </Text>
                                                        <Checkbox
                                                            isChecked={item.checked}
                                                            onChange={(checked) =>
                                                                handleCheckboxChange({
                                                                    checked: checked.target.checked,
                                                                    selectedData: item,
                                                                    codeItem,
                                                                    setCodeItem,
                                                                    codeItemChoosed,
                                                                    setCodeItemChoosed,
                                                                })
                                                            }
                                                            className="checkbox-custom-style"
                                                        />
                                                    </Flex>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </div>
                        </Box>
                    </ModalBody>

                    <ModalFooter className="modal-footer">
                        <Box className="btn-modal-reset" onClick={handleResetFilter}>
                            <Text className="text-modal-reset">{outlineLabel}</Text>
                        </Box>
                        <Box className="btn-apply-filter" onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;
