import React from 'react';
import { Box, Button, Flex, Text, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-date-picker';
import InfiniteDataSelectNoPO from '../../components/infiniteDataSelectNoPO';
import { CalendarIcon } from '../../icons';
import { isEmptyString } from '@helpers/functions';
import { onSaveNewOrder } from './function';
import './styles.scss';

function Index({ formData, setFormData, isEdit, id }) {
    const toast = useToast();
    const navigate = useNavigate();
    const [searchNoPO, setSearchNoPO] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);

    const onChangeInputDate = (due_date) => {
        setFormData((prev) => ({ ...prev, due_date }));
    };
    const handleChangeQuantityConfirmation = (i) => {
        setFormData((prev) => ({ ...prev, quantity_confirmation: i?.target?.value }));
    };
    return (
        <Box className="form-after-generate-container">
            <Flex gap="73px">
                <Box minWidth="267px" />
                <Box className="form-container">
                    <Box className="item-code-container">
                        <Text className="label-input-item-name">
                            Nama Item<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                        </Text>
                        <input disabled value={formData?.nama_item} className="input-item-name" />
                    </Box>
                    <Box className="no-po-no-ospo-container">
                        <Box>
                            <Text className="label-input-no-po-no-ospo">
                                No. Po<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                            </Text>
                            <InfiniteDataSelectNoPO
                                width="353px"
                                widthDropDownContainer="353px"
                                setFormData={setFormData}
                                formData={formData}
                                placeholderInput="masukan no po"
                                initialAttribute="no_po"
                                api="/po/list"
                                dataResponse="PO"
                                apiParams={{
                                    kode_item: formData?.kode_item,
                                    supplier_name: formData?.supplier_name,
                                    source: 'ODOO',
                                    q: searchNoPO,
                                }}
                                searchData={searchNoPO}
                                setSearchData={setSearchNoPO}
                            />
                        </Box>
                        <Box>
                            <Text className="label-input-no-po-no-ospo">
                                Qty OSPO<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                            </Text>
                            <input disabled value={formData?.qty_ospo} className="input-qty-ospo" />
                        </Box>
                    </Box>
                    <Box className="due-date-container">
                        <Text className="label-input-due-date">
                            Due Date<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                        </Text>
                        <Box className="input-due-date__container">
                            <Box className="calender_due-date">
                                <DatePicker
                                    value={formData?.due_date}
                                    onChange={onChangeInputDate}
                                    className="date-picker-content"
                                    openCalendarOnFocus={false}
                                    clearIcon={null}
                                    calendarIcon={<CalendarIcon />}
                                    calendarClassName="calendar-first"
                                    format="dd/MM/y"
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box className="item-code-container">
                        <Text className="label-input-item-name">
                            Quantity Confirmation<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                        </Text>
                        <input
                            value={formData?.quantity_confirmation}
                            onChange={handleChangeQuantityConfirmation}
                            className="input-item-name"
                            type="number"
                        />
                    </Box>
                    <Button
                        _hover="none"
                        className="btn-submit"
                        isDisabled={
                            isEmptyString(formData?.nama_item) ||
                            isEmptyString(formData?.no_po) ||
                            isEmptyString(formData?.qty_ospo) ||
                            isEmptyString(formData?.due_date) ||
                            isEmptyString(formData?.quantity_confirmation)
                        }
                        onClick={() => {
                            onSaveNewOrder({
                                toast,
                                formData,
                                setIsLoading,
                                isEdit,
                                id,
                                navigate,
                                setFormData,
                            });
                        }}
                        isLoading={isLoading}
                    >
                        Save new order
                    </Button>
                </Box>
            </Flex>
        </Box>
    );
}

export default Index;
