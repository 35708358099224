export const IconCheckSm = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6668 3.5L5.25016 9.91667L2.3335 7" stroke="#1746A2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const IconAddSm = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 2.9165V11.0832" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.9165 7H11.0832" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const CloseIconSm = ({ onClick }) => (
    <svg
        style={{ cursor: 'pointer' }}
        onClick={onClick}
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_773_10231)">
            <rect width="12" height="12" rx="6" fill="#1746A2" />
            <path
                d="M9.5 3.205L8.795 2.5L6 5.295L3.205 2.5L2.5 3.205L5.295 6L2.5 8.795L3.205 9.5L6 6.705L8.795 9.5L9.5 8.795L6.705 6L9.5 3.205Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_773_10231">
                <rect width="12" height="12" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
