import {
    Box,
    Text,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Spinner,
    MenuList,
    Menu,
    Tooltip,
} from '@chakra-ui/react';
import { useState, useEffect, useMemo } from 'react';
import { ArrowUpIconBlack } from '@asset/icons';
import FieldMenu from '@components/fieldMenu';
import { onChangeFormData } from '../../functions';
import { EmptyField } from '@components/fieldMenu';
import CustomButton from '@components/button';
import { fetchDataInfinite, passwordHidden } from './function';
import InfiniteScroll from 'react-infinite-scroll-component';
import { debounce, isEmpty } from 'lodash';
import { EyeOpenIcon, EyeHideIcon } from '@pages/login/section/sectionFieldInput/icons';
import { SearchIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';

import './styles.scss';

function Index({ setFormData, handleSubmit, isSubmitted, formData, jenisMaterial, isEditModal }) {
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(1);
    const [page, setPage] = useState(1);
    const [debounceVal, setDebounceVal] = useState('');
    const [search, setSearch] = useState(formData?.supplier_name?.name || '');
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const getMoreData = (hasMore = false) => {
        if (!Boolean(formData?.jenis_material?.id)) return;
        fetchDataInfinite({
            page,
            setData,
            data,
            setPage,
            setTotalData,
            moreData: hasMore,
            search: debounceVal,
            setLoadingSearch: setLoadingSearch,
            materialCat: formData?.jenis_material,
        });
    };

    const handleChangeDebounce = (text) => {
        setTotalData(1);
        setDebounceVal(text.target.value);
        setPage(() => {
            return 1;
        });
    };
    const debounceSearch = useMemo(() => debounce(handleChangeDebounce, 500), []);
    const handleClickSupplierName = (item) => {
        setSearch(item?.name);
        setFormData((prev) => ({
            ...prev,
            supplier_name: item,
            name: item?.name,
            supplier_ref: item?.ref,
            password: `PTI${item?.ref}`,
        }));
        setOpenInfiniteScroll(false);
    };

    useEffect(() => {
        getMoreData();
    }, [debounceVal, formData?.jenis_material?.id]);
    return (
        <>
            <Box className="modal-item__container_secondForm">
                <Box width="359px">
                    <Text className="label-title">Isi data lengkap</Text>
                    <Text className="label-description">Isi data mu dengan lengkap, untuk pembuatan account baru</Text>
                </Box>
                <Box width="706px">
                    <Box>
                        <Text className="label">
                            Jenis Material<span style={{ color: '#FF0000' }}>*</span>
                        </Text>
                        <Box flex={1}>
                            <FieldMenu
                                onClick={(value) => {
                                    setFormData((prev) => ({ ...prev, jenis_material: value }));
                                }}
                                buttonName={
                                    <span>
                                        {formData?.jenis_material?.name ? (
                                            <span className="selected-role-text">{formData?.jenis_material?.name}</span>
                                        ) : (
                                            <span className="FieldMenu-placeholder">Masukan nama</span>
                                        )}
                                    </span>
                                }
                                isDisabled
                                menuList={jenisMaterial}
                                keyName="name"
                                disabledWidthBox="706px"
                                disabledHeightBox="48px"
                                menuClassName="menu-container__user-management"
                                menuButtonClassName={`menu-button ${
                                    isSubmitted && !formData?.jenis_material?.id && 'menu-button-error'
                                }`}
                                menuListClassName="menuList-container"
                                icon={<ArrowUpIconBlack />}
                            />
                            {isSubmitted && !formData?.jenis_material?.id && (
                                <Text className="required-field__text">*Masukan jenis material</Text>
                            )}
                        </Box>
                    </Box>

                    <Box mt="40px">
                        <Text className="label">
                            Nama Supplier<span style={{ color: '#FF0000' }}>*</span>
                        </Text>
                        <Box position="relative">
                            <InputGroup>
                                <Tooltip
                                    label={
                                        !Boolean(formData?.jenis_material?.id)
                                            ? 'Masukkan jenis material terlebih dahulu'
                                            : ''
                                    }
                                    aria-label="A tooltip"
                                >
                                    <Input
                                        disabled={!Boolean(formData?.jenis_material?.id)}
                                        className={
                                            !Boolean(formData?.jenis_material?.id)
                                                ? 'disabled-input-supplier-name'
                                                : 'enabled-input-supplier-name'
                                        }
                                        onFocus={() => setOpenInfiniteScroll(true)}
                                        value={search}
                                        height="48px"
                                        onChange={(text) => {
                                            ///https://stackoverflow.com/a/69584798/18038473
                                            setSearch(text.target.value);
                                            debounceSearch(text);
                                        }}
                                    />
                                </Tooltip>
                                <InputRightElement height="48px" right="12px">
                                    <Box className="divider_input_infinite_scroll" />
                                    {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                                </InputRightElement>
                            </InputGroup>
                            <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                                <MenuList mt="50px" zIndex={20}>
                                    {/* https://stackoverflow.com/a/73260330 */}
                                    <div id="scrollableDiv" className="scroll-container">
                                        <InfiniteScroll
                                            dataLength={data.length}
                                            next={() => getMoreData(true)}
                                            hasMore={data.length === totalData ? false : true}
                                            scrollableTarget="scrollableDiv"
                                            loader={
                                                data.length === 0 ? (
                                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                                ) : data.length < 9 ? null : (
                                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                                )
                                            }
                                            endMessage={
                                                <Text className="loading_fetch_next_page">
                                                    No more data is displayed
                                                </Text>
                                            }
                                        >
                                            {data.length === 0 && !loadingSearch ? (
                                                <Text className="no_data_infinite_scroll">
                                                    Tidak Bisa Menemukan "{debounceVal}"
                                                </Text>
                                            ) : (
                                                data?.map((item, index) => {
                                                    return (
                                                        <Box
                                                            onClick={() => handleClickSupplierName(item)}
                                                            className="list_item_infinite"
                                                            key={index}
                                                        >
                                                            {item?.name}
                                                        </Box>
                                                    );
                                                })
                                            )}
                                        </InfiniteScroll>
                                    </div>
                                </MenuList>
                            </Menu>
                            {isSubmitted && isEmpty(formData.supplier_name) && (
                                <Text className="required-field__text">*Masukan Nama Supplier</Text>
                            )}
                        </Box>
                    </Box>
                    <Box mt="40px">
                        <Text className="label">
                            Kode Supplier<span style={{ color: '#FF0000' }}>*</span>
                        </Text>
                        <EmptyField
                            disabledWidthBox="706px"
                            disabledHeightBox="48px"
                            buttonName={formData.supplier_ref}
                        />
                    </Box>
                    <Box mt="40px">
                        <Text className="label">
                            Email
                            <span style={{ color: '#FF0000' }}>*</span>
                        </Text>
                        <Box flex={1}>
                            <input
                                className={`input-container ${isSubmitted && !formData.email && 'error-empty__field'}`}
                                onChange={(e) =>
                                    onChangeFormData({
                                        type: 'email',
                                        value: e.target.value,
                                        setFormData,
                                        formData,
                                    })
                                }
                                value={formData.email}
                                placeholder="Masukan alamat email"
                            />
                            {isSubmitted && !formData.email && (
                                <Text className="required-field__text">*Masukan Email</Text>
                            )}
                        </Box>
                    </Box>
                    <Box mt="40px">
                        <Text className="label">
                            Password
                            <span style={{ color: '#FF0000' }}>*</span>
                        </Text>
                        <Box flex={1} position="relative">
                            <EmptyField
                                disabledWidthBox="706px"
                                disabledHeightBox="48px"
                                buttonName={
                                    isShowPassword ? formData.password : passwordHidden({ password: formData.password })
                                }
                            />
                            <Box onClick={() => setIsShowPassword(!isShowPassword)} className="icon-eye__container">
                                {isShowPassword ? <EyeOpenIcon /> : <EyeHideIcon />}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box height="0.5px" bgColor="#D7D7D7" />
            <Flex gap="60px" padding="0px 38px 32px 38px">
                <Box width="359px"></Box>
                <Box width="706px">
                    <CustomButton onClick={handleSubmit} marginTop="32px" float="right">
                        {isEditModal ? ' Update Account' : ' Create Account'}
                    </CustomButton>
                </Box>
            </Flex>
        </>
    );
}

export default Index;
