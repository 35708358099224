import { createSlice } from '@reduxjs/toolkit';

export const globalFilterSlice = createSlice({
    name: 'globalFilterSlice',
    initialState: {
        supplier: [],
        jenisMaterial: {},
        selectedMaterial: {},
        supplierRef: [],
        manualShowGlobalFilterByGivenId: '',
    },
    reducers: {
        resetSupplier: (state) => {
            state.supplier = [];
        },
        onChangeSupplierType: (state, action) => {
            state.supplier = action.payload;
        },
        onChangeJenisMaterialType: (state, action) => {
            console.log(action?.payload, '<<<');
            state.jenisMaterial = action.payload;
        },
        onChangeSelectedMaterial: (state, action) => {
            state.selectedMaterial = action.payload;
        },
        onChangeSupplierRef: (state, action) => {
            state.supplierRef = action.payload;
        },
        onChangeManualShowGlobalFilter: (state, action) => {
            state.manualShowGlobalFilterByGivenId = action.payload;
        },
    },
});

export const {
    resetSupplier,
    onChangeSupplierType,
    onChangeJenisMaterialType,
    onChangeSelectedMaterial,
    onChangeSupplierRef,
    onChangeManualShowGlobalFilter,
} = globalFilterSlice.actions;

export default globalFilterSlice.reducer;
