import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react';
import { FormateDateTime } from '@pages/mainDashboard/functions';
import { FileIconBlueCircleBorder } from '@asset/icons';
import { useState } from 'react';
import KetersediaanModal from './section/modal';

const CardOrderStatus = ({
    streamName = '',
    start = '',
    end = '',
    namaItem = '',
    nomo = '',
    snfg = '',
    colorStatus = '',
    bgHeader = '',
    colorText = '',
    status = [],
    isKetersediaanMaterial,
    selectedOrderPlanning,
    activeTab,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const statusHide = ['Antrian Konfirmasi', 'Pending'];
    const truncateText = (text) => {
        if (text.length > 100) {
            return text.substring(0, 100) + ' ....';
        } else {
            return text;
        }
    };

    return (
        <Box className="card__container">
            <Flex className="section-head__card" bg={bgHeader}>
                <Text className="card-streamline__name">{streamName || '-'}</Text>
                {start && end && (
                    <>
                        {!statusHide.includes(...status) && (
                            <Text className="card-date">{`${FormateDateTime({
                                date: start,
                            })} - ${FormateDateTime({ date: end })}`}</Text>
                        )}
                    </>
                )}
            </Flex>
            <Flex className="section-body__card">
                <Tooltip label={namaItem} aria-label="tooltip">
                    <Text className="card-product__text">{truncateText(namaItem)}</Text>
                </Tooltip>
            </Flex>
            <Flex
                className="snfg-text-container"
                sx={{
                    height: '42px',
                }}
            >
                {!statusHide?.includes(activeTab) && (
                    <Flex>
                        <Box mr="8px">
                            <p className="snfg-text">NOMO</p>
                            <p className="snfg-text-bottom">SNFG</p>
                        </Box>
                        <Box>
                            <p className="snfg-text">: {nomo}</p>
                            <p className="snfg-text-bottom">: {snfg}</p>
                        </Box>
                    </Flex>
                )}
            </Flex>
            <Box className="section-foot__card">
                <Flex className="section-wrap-foot__card" experimental_spaceX={2} justifyContent="space-between">
                    {status.map((v, idx) => {
                        if (v) {
                            return (
                                <Text key={idx} className="card-status" bg={colorStatus} color={colorText}>
                                    {v}
                                </Text>
                            );
                        }
                    })}
                    {isKetersediaanMaterial && (
                        <Button
                            onClick={() => setOpenModal(true)}
                            variant="ghost"
                            sx={{
                                height: '20px',
                                minWidth: '20px',
                                p: 0,
                            }}
                        >
                            <FileIconBlueCircleBorder />
                        </Button>
                    )}
                </Flex>
            </Box>
            <KetersediaanModal
                selectedOrderPlanning={selectedOrderPlanning}
                isOpen={openModal}
                onClose={() => setOpenModal(false)}
            />
        </Box>
    );
};

export default CardOrderStatus;
