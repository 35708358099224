import { formatNumber } from '@helpers/functions';

export const handleChange = ({ valueOnchange, setItemEdited, selectedItem, attributeCurrentValue }) => {
    const { id, qty_revisi, notes } = selectedItem || {};
    setItemEdited((prev) => {
        let clone = [...prev];
        const hasDuplicateItem = clone.find((item) => item?.id === id);
        if (Boolean(hasDuplicateItem)) {
            clone.map((item) => {
                if (item?.id === id) {
                    item[attributeCurrentValue] = valueOnchange;
                }
                return item;
            });
        } else {
            clone.push({
                ...selectedItem,
                [attributeCurrentValue]: valueOnchange,
                prev_qty_revisi: qty_revisi,
                prev_notes: notes,
                has_notes_error: null,
                has_qty_revisi_error: null,
            });
        }
        return clone;
    });
};
export const valueRebuild = ({ value, type }) => {
    if (type === 'number') {
        if (value === '') return '-';
        if (value !== '') return formatNumber(value);
    }
    if (type === 'text') {
        if (value === '') return '-';
        else return value;
    }
};
