import { Flex, Text, Box } from '@chakra-ui/react';
import { EditIcon, RemoveIcon } from './icons';
import Cookies from 'universal-cookie';
import { isAllowed } from '@helpers/roleAccess';

export const tableFix = [
    {
        name: 'No',
        value: 'id',
        width: 119,
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Nama',
        flex: 1,
        value: 'name',
        component: ({ value }) => (
            <Text className="column-text" textTransform="uppercase">
                {value}
            </Text>
        ),
    },
];

export const tableListSuperAdmin = [
    ...tableFix,
    {
        name: 'ROLE',
        width: 192,
        value: 'role',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Access',
        width: 235,
        value: 'apps',
        component: ({ user }) => {
            const dataAccess = user?.apps;
            return (
                <Box>
                    {dataAccess?.map((item, idx) => (
                        <Box key={idx}>
                            <Text className="column-text">{item}</Text>
                        </Box>
                    ))}
                </Box>
            );
        },
    },
    {
        name: 'Jenis Material',
        width: 192,
        value: 'jenis_material',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'ID',
        width: 301,
        flex: 1,
        value: 'email',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Action',
        value: null,
        isCenter: true,
        width: 161,
        component: ({ onEditHandler, onDeleteHandle }) => (
            <Flex w="100%" justifyContent="center">
                {isAllowed({ pageName: 'User Management', permissionName: 'Edit accoount' }) && (
                    <Box
                        cursor="pointer"
                        mr="10px"
                        onClick={onEditHandler}
                        border="1px solid #1746A2"
                        borderRadius="4px"
                        p="6px"
                    >
                        <EditIcon />
                    </Box>
                )}
                {isAllowed({ pageName: 'User Management', permissionName: 'Delete account' }) && (
                    <Box
                        cursor="pointer"
                        onClick={onDeleteHandle}
                        border="1px solid #EF5350"
                        borderRadius="4px"
                        p="6px"
                    >
                        <RemoveIcon />
                    </Box>
                )}
            </Flex>
        ),
    },
];
export const tableList = [
    ...tableFix,
    {
        name: 'ROLE',
        width: 192,
        value: 'role',
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'EMAIL',
        width: 301,
        flex: 1,
        value: 'email',
        component: ({ value }) => <Text className="column-text email-blue__text">{value}</Text>,
    },
    {
        name: 'ACTIONS',
        value: null,
        isCenter: true,
        width: 161,
        component: ({ onEditHandler, onDeleteHandle }) => (
            <Flex w="100%" justifyContent="center">
                <Box
                    cursor="pointer"
                    mr="10px"
                    onClick={onEditHandler}
                    border="1px solid #1746A2"
                    borderRadius="4px"
                    p="6px"
                >
                    <EditIcon />
                </Box>
                <Box cursor="pointer" onClick={onDeleteHandle} border="1px solid #EF5350" borderRadius="4px" p="6px">
                    <RemoveIcon />
                </Box>
            </Flex>
        ),
    },
];
export const appRoleList = [
    {
        id: '0',
        role: 'Super Admin',
        entitas: [
            {
                id: '1',
                formType: 'normal',
                name: 'Warehouse to Supllier',
                role: 'Admin',
                jenis_material: {
                    id: 3,
                    type: 'General',
                },
            },
            {
                id: '2',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Admin',
                jenis_material: {
                    id: 3,
                    type: 'General',
                },
            },
            {
                id: '3',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'Superior',
                jenis_material: {
                    id: 3,
                    type: 'General',
                },
            },
            {
                id: '4',
                formType: 'supplier',
                name: 'Warehouse to supplier',
                role: 'Supplier',
            },
            {
                id: '5',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'SAP',
            },
            {
                id: '6',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'Logistik',
            },
            {
                id: '7',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'SSC',
            },
            {
                id: '8',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'NDC',
            },
            {
                id: '9',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'PPC',
            },
            {
                id: '10',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Distributor planner',
            },
            {
                id: '11',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Produksi',
            },
            {
                id: '12',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Logistik packaging',
            },
            {
                id: '13',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Logistik raw material',
            },
            {
                id: '14',
                formType: 'normal',
                name: 'Manufacture to warehouse ',
                role: 'Viewer',
            },
        ],
    },
    {
        id: '1',
        role: 'AdminWTS',
        entitas: [
            {
                id: '1',
                formType: 'supplier',
                name: 'Warehouse to supplier',
                role: 'Supplier',
            },
            {
                id: '2',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'SAP',
            },
            {
                id: '3',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'Logistik',
            },
            {
                id: '4',
                formType: 'normal',
                name: 'Warehouse to supplier',
                role: 'SSC',
            },
        ],
    },
    {
        id: '2',
        role: 'AdminNTM',
        entitas: [
            {
                id: '1',
                formType: 'normal',
                role: 'PPC',
            },
            {
                id: '2',
                formType: 'normal',
                role: 'Distplan',
            },
            {
                id: '3',
                formType: 'normal',
                role: 'Produksi',
            },
            {
                id: '4',
                formType: 'normal',
                role: 'NDC',
            },
            {
                id: '5',
                formType: 'normal',
                role: 'Logistik',
            },
            {
                id: '6',
                formType: 'normal',
                role: 'Viewer',
            },
            {
                id: '7',
                formType: 'normal',
                role: 'Admin',
                jenis_material: {
                    id: 3,
                    type: 'General',
                },
            },
        ],
    },
];

export const changeTableListHandler = () => {
    const cookies = new Cookies();
    const appType = cookies.get('role');
    if (appType === 'Super Admin') {
        return tableListSuperAdmin;
    }
    return tableList;
};
