import React from 'react';
import { tableOrderDeliveryContext } from '../..';
import { Box, Text, Tooltip } from '@chakra-ui/react';
import { handleChange, valueRebuild } from './function';
import { NumericFormat } from 'react-number-format';

import './styles.scss';

function Index({
    item,
    value,
    type,
    widthInput = '80px',
    attributeCurrentValue,
    errorHighlight = { has_notes_error: null, has_qty_revisi_error: null },
}) {
    const { isEdit, setItemEdited } = React.useContext(tableOrderDeliveryContext);

    if (!isEdit) return <Text className="column-text">{valueRebuild({ value, type })}</Text>;
    if (type === 'number') {
        return (
            <Tooltip
                hasArrow
                bg="red.500"
                label={errorHighlight?.has_qty_revisi_error ? errorHighlight?.has_qty_revisi_error : ''}
            >
                <Box className="container-edit-column-order-delivery">
                    <NumericFormat
                        value={value}
                        style={{
                            width: widthInput,
                            border: errorHighlight.has_qty_revisi_error ? '1px red solid' : '',
                        }}
                        onChange={(i) =>
                            handleChange({
                                valueOnchange: i.target.value,
                                selectedItem: item,
                                setItemEdited,
                                attributeCurrentValue,
                            })
                        }
                        prefix=""
                        thousandSeparator
                        className="input-column"
                    />
                </Box>
            </Tooltip>
        );
    }
    return (
        <Tooltip hasArrow bg="red.500" label={errorHighlight?.has_notes_error ? errorHighlight?.has_notes_error : ''}>
            <Box className="container-edit-column-order-delivery">
                <input
                    style={{ width: widthInput, border: errorHighlight?.has_notes_error ? '1px red solid' : '' }}
                    value={value}
                    onChange={(i) =>
                        handleChange({
                            valueOnchange: i.target.value,
                            selectedItem: item,
                            setItemEdited,
                            attributeCurrentValue,
                        })
                    }
                    className="input-column"
                    type={type}
                />
            </Box>
        </Tooltip>
    );
}

export default Index;
