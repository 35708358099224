export const colorVariant = [
    {
        name: 'Flow Through',
        bg: '#CCE2FF',
        color: '#004199',
    },
    {
        name: 'Interchangeable',
        bg: '#FDDDEC',
        color: '#F22B89',
    },
    {
        name: 'Immediately',
        bg: '#D9F2E3',
        color: '#267346',
    },
];
