export const IconMinus = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.3335 8H12.6668" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const IconPlus = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3.33301V12.6663" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.3335 8H12.6668" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
