import { useContext, useState } from 'react';
import { OrderManagementIcon } from '@asset/icons';
import { Box, Flex, Text, Button, useToast, useDisclosure } from '@chakra-ui/react';
import { EditIcon } from '@asset/icons';
import InputDateETA from './inputDateETA';
import { tableOrderDeliveryContext } from '../../index.js';
import { handleCancelEdit, handleConfirm, handleEdit } from './function';
import { ChevronLeftIcon } from '@pagesPhase2/rawDataOrder/section/sectionAddMoreOrder/icons';
import { useNavigate } from 'react-router-dom';
import { isEmptyString } from '@helpers/functions';
import FilterTradeName from '@components/filterTradeName';
import FilterItemCode from '@components/filterItemCode';
import ModalConfirm from '@components/modalConfirm';
import { useSelector } from 'react-redux';

import './styles.scss';
import ModalAutocorrection from '@pagesPhase2/orderManagement/component/modalAutocorrection';

const SectionHead = ({ goBack }) => {
    const {
        isEdit,
        setIsEdit,
        setPage: setMainPage,
        setOrderDelivery,
        orderDelivery,
        itemEdited,
        setItemEdited,
        filter,
        setFilter,
        setRefetch,
    } = useContext(tableOrderDeliveryContext);
    const [autocorrectionList, setAutocorrectionList] = useState([]);

    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [isLoading, setIsLoading] = useState(false);
    const toast = useToast();
    const { isOpen, onClose, onOpen } = useDisclosure();
    const navigation = useNavigate();
    return (
        <div>
            <ModalConfirm
                loading={isLoading}
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={() =>
                    handleConfirm({
                        itemEdited,
                        toast,
                        setIsLoading,
                        setItemEdited,
                        setIsEdit,
                        filter,
                        onClose,
                        setRefetch,
                        jenisMaterial,
                        supplier,
                        setAutocorrectionList,
                    })
                }
                submitLabel="Confirm"
                title="Apa anda yakin telah mengisi dengan benar?"
            />

            <ModalAutocorrection
                autocorrectionList={autocorrectionList}
                isOpen={autocorrectionList?.length > 0}
                onClose={() => setAutocorrectionList([])}
                isOrderDelivery
            />
            <Flex className="order-delivery-box-header">
                <Flex className="container-text-order-delivery" onClick={goBack}>
                    <Box>
                        <ChevronLeftIcon />
                    </Box>
                    <Box>
                        <OrderManagementIcon />
                    </Box>
                    <Text className="text-order-delivery">Order Delivery</Text>
                </Flex>
                <Flex className="action-button-container" alignItems="center">
                    <Box display={isEdit ? 'none' : 'block'}>
                        <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                    </Box>
                    <Box display={isEdit ? 'none' : 'block'} mx="12px">
                        <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                    </Box>

                    <Box display={isEdit ? 'none' : 'block'}>
                        <InputDateETA />
                    </Box>

                    <Box display={isEdit ? 'none' : 'block'}>
                        <Box className="divider" />
                    </Box>

                    {isEdit ? (
                        <Flex className="wrapper-btn">
                            <Button
                                className="btn-cancel"
                                onClick={() =>
                                    handleCancelEdit({
                                        setIsEdit,
                                        setOrderDelivery,
                                        itemEdited,
                                        setItemEdited,
                                        setRefetch,
                                    })
                                }
                            >
                                Cancel
                            </Button>
                            <Button
                                isDisabled={isLoading}
                                _hover={{ cursor: 'block' }}
                                isLoading={isLoading}
                                className="btn-confirm"
                                onClick={onOpen}
                            >
                                Confirm
                            </Button>
                        </Flex>
                    ) : (
                        <Button
                            className="button-edit"
                            isDisabled={isEmptyString(filter?.inputDate) || !Boolean(orderDelivery?.length > 0)}
                            onClick={() => handleEdit({ setIsEdit, setOrderDelivery })}
                        >
                            <Box mr="5px">
                                <EditIcon />
                            </Box>
                            edit
                        </Button>
                    )}
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;
