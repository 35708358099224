export const handleReset = ({ setItems, itemEdited, selectedItem, setItemEdited }) => {
    const { id } = selectedItem || {};
    setItems((prev) => {
        let clone = [...prev];
        const findPrevValueBefore = itemEdited?.find((item) => item?.id === id);
        const { prev_konfirmasi_pcs, prev_konfirmasi_kanban, prev_konfirmasi_eta } = findPrevValueBefore || {};
        for (let index = 0; index < clone.length; index++) {
            if (clone[index]?.id === id) {
                clone[index].konfirmasi_kanban = prev_konfirmasi_kanban || '';
                clone[index].konfirmasi_pcs = prev_konfirmasi_pcs || '';
                clone[index].konfirmasi_eta = prev_konfirmasi_eta || '';
            }
        }
        return clone;
    });
    setItemEdited((prev) => {
        const deleteItemReset = prev?.filter((item) => item?.id !== id);
        return deleteItemReset;
    });
};
