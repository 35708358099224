import { Box, Text } from '@chakra-ui/react';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';
import { isAllowed } from '@helpers/roleAccess';
import EditableNotes from '../demandAnticipation/components/editableNotes';
import EditableStatus from '../demandAnticipation/components/editableStatus';
import { currentYearDemand, findWeekYear, pastYearDemand } from './function';

export const tableList = [
    {
        name: 'no',
        value: 'index',
        width: 70,
        children: ['-'],
        component: ({ index, numbers }) => <Text className="column-text">{numbers[index] + 1}</Text>,
    },
    {
        name: 'kode item',
        value: 'kode_item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text">{value}</Text>
            </Box>
        ),
    },
    {
        name: 'nama item',
        width: 288,
        value: 'trade_name',
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value}</Text>,
    },
    {
        name: 'supplier',
        value: 'supplier_name',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'UOM',
        value: 'uom',
        width: 75,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'baseline',
        value: 'baseline_pcs',
        width: 79,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'status',
        width: 150,
        value: 'status',
        children: ['act/npd'],
        component: ({ value, dataItem, id }) => (
            <Box className="td-status-container">
                {Boolean(dataItem?.demand_anticipation_id) &&
                isAllowed({
                    pageName: 'Demand Anticipation',
                    permissionName: 'Edit Demand Anticipation',
                }) ? (
                    <EditableStatus dataItem={dataItem} id={id} value={value} />
                ) : (
                    <Text className="column-text">{value || '-'}</Text>
                )}
            </Box>
        ),
    },
    {
        name: 'notes',
        width: 250,
        value: 'notes',
        children: ['Free Text'],
        component: ({ dataItem }) => <EditableNotes dataItem={dataItem} />,
    },
    {
        name: 'addtional order',
        width: 120,
        value: 'additional_order',
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'W44',
        year: '-',
        width: 40,
        value: '',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(44, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W45',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(45, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W46',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(46, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W47',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(47, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W48',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(48, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W49',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(49, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W50',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(50, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W51',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(51, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W52',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(52, pastYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W1',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(1, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W2',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(2, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W3',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(3, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W4',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(4, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W5',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(5, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W6',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(6, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W7',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(7, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W8',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(8, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W9',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(9, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W10',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(10, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W11',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(11, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W12',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(12, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W13',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(13, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W14',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(14, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W15',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(15, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W16',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(16, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W17',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(17, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W18',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(18, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W19',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(19, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W20',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(20, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W21',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(21, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W22',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(22, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: '23',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(23, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W24',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(24, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W25',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(25, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W26',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(26, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W27',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(27, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W28',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(28, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W29',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(29, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W30',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(30, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W31',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(31, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W32',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(32, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W33',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(33, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W34',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(34, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W35',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(35, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W36',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(36, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W37',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(37, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W38',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(38, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W39',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(39, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W40',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(40, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W41',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(41, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W42',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(42, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W43',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(43, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
    {
        name: 'W44',
        year: '-',
        width: 40,
        value: 'value_w',
        children: ['pcs'],
        component: ({ demandPerWeek }) => (
            <Text className="column-text">{formatNumber(findWeekYear(44, currentYearDemand, demandPerWeek))}</Text>
        ),
    },
];

export const optionValueStatus = [
    {
        name: 'Activity',
        value: 1,
    },
    {
        name: 'NPD',
        value: 2,
    },
    {
        name: 'Activity & NPD',
        value: 3,
    },
    {
        name: '-',
        value: null,
    },
];
