export const onChangeInputDate = async ({ selectDate, selectedItem, setItemEdited }) => {
    const { id } = selectedItem || {};
    setItemEdited((prev) => {
        let clone = [...prev];
        const hasDuplicateItem = clone.find((item) => item?.id === id);
        if (Boolean(hasDuplicateItem)) {
            clone.map((item) => {
                if (item?.id === id) {
                    item.konfirmasi_eta = selectDate;
                }
                return item;
            });
        } else {
            clone.push({
                ...selectedItem,
                konfirmasi_eta: selectDate,
            });
        }
        return clone;
    });
};
