import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { AddIconBlue } from '@asset/icons';
import { RemoveIcon } from '@pages/skuConfiguration/icons';
import { handleClickMenu } from '../function';
import { initialValue } from '../const';

import './styles.scss';

function Index({ formData, setFormData }) {
    const handleAddMaterial = () => {
        const clone = [...formData?.stock_raw_material || []];
        clone.push({
            id: Math.floor(Math.random() * 99999999) + 1,
            material_name: '',
            rop: '',
            max_stock: '',
            delete: false,
            is_new: true,
        });
        setFormData((prev) => ({ ...prev, stock_raw_material: clone }));
    };
    const handleDeleteFormRawMaterial = (item) => {
        setFormData((prev) => {
            const newStockMat = prev?.stock_raw_material?.map((srm) => {
                if (srm?.id === item?.id) {
                    srm.delete = true;
                }
                return srm;
            });
            return { ...prev, stock_raw_material: newStockMat };
        });
    };
    const rawMatLength = React.useMemo(() => {
        return formData?.stock_raw_material?.filter((list) => !list?.delete)?.length - 1;
    }, [formData]);

    /// https://stackoverflow.com/a/58837188/18038473
    React.useEffect(() => {
        setFormData(() => {
            return {
                ...initialValue,
                stock_raw_material: [
                    {
                        id: 1,
                        material_name: '',
                        rop: '',
                        max_stock: '',
                        delete: false,
                        is_new: true,
                    },
                ],
            };
        });
    }, []);
    return (
        <Flex className="stock-raw-material">
            <Box width="267px">
                <Text className="title-stock-raw-material">Stock RAW Material Supplier</Text>
                <Text className="tag-stock-raw-material">
                    Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                </Text>
            </Box>
            <Box className="stock-raw-material-container">
                {formData?.stock_raw_material
                    ?.filter((list) => !list?.delete)
                    ?.map((item, index) => {
                        const showErrorMustBeIntegerMaxStock =
                            formData?.dataLengkap?.jenis_material === 'PM' &&
                            (String(item?.max_stock)?.includes('.') || String(item?.max_stock)?.includes(','));
                        const showErrorMustBeIntegerRop =
                            formData?.dataLengkap?.jenis_material === 'PM' &&
                            (String(item?.rop)?.includes('.') || String(item?.rop)?.includes(','));
                        return (
                            <Box
                                key={index}
                                sx={{
                                    position: 'relative',
                                    borderBottom: formData?.stock_raw_material?.length > 1 && '0.5px solid #D7D7D7',
                                    paddingBottom: formData?.stock_raw_material?.length > 1 && '30px',
                                }}
                            >
                                {/* {formData?.stock_raw_material?.length > 1 && <Text className="qty">{index + 1}</Text>} */}

                                <Box>
                                    <Text className="label">Nama Material (Opsional)</Text>
                                    <Box className="child-container">
                                        <Box position="relative">
                                            <input
                                                placeholder="masukan nama material"
                                                onChange={(event) =>
                                                    handleClickMenu({
                                                        valueOnChange: event?.target?.value,
                                                        selected: item?.id,
                                                        itemForm: { class: 'raw_material', key: 'material_name' },
                                                        setFormData,
                                                    })
                                                }
                                                value={item?.material_name}
                                                style={{
                                                    padding: '12px 16px',
                                                }}
                                                className={`input-style-stock-raw-material-MC`}
                                            />
                                        </Box>
                                    </Box>
                                </Box>

                                <Box className="child-container" mt="40px">
                                    <Flex gap="34px">
                                        <Box>
                                            <Text className="label">ROP (Optional)</Text>
                                            <Box position="relative">
                                                <input
                                                    type="number"
                                                    min={0}
                                                    placeholder="Masukan nominal"
                                                    onChange={(event) =>
                                                        handleClickMenu({
                                                            valueOnChange: event?.target?.value,
                                                            selected: item?.id,
                                                            itemForm: { class: 'raw_material', key: 'rop' },
                                                            setFormData,
                                                        })
                                                    }
                                                    value={item?.rop}
                                                    style={{
                                                        padding: '12px 16px',
                                                    }}
                                                    className={`input-style-add-new-item-MC`}
                                                />
                                            </Box>
                                            {showErrorMustBeIntegerRop && (
                                                <Text className="required-field__text">Must be integer</Text>
                                            )}
                                        </Box>
                                        <Box>
                                            <Text className="label">MAX Stock (Optional)</Text>
                                            <Box position="relative">
                                                <input
                                                    min={0}
                                                    type="number"
                                                    placeholder="Masukan nominal"
                                                    onChange={(event) =>
                                                        handleClickMenu({
                                                            valueOnChange: event?.target?.value,
                                                            selected: item?.id,
                                                            itemForm: { class: 'raw_material', key: 'max_stock' },
                                                            setFormData,
                                                        })
                                                    }
                                                    value={item?.max_stock}
                                                    style={{
                                                        padding: '12px 16px',
                                                    }}
                                                    className={`input-style-add-new-item-MC`}
                                                />
                                            </Box>
                                            {showErrorMustBeIntegerMaxStock && (
                                                <Text className="required-field__text">Must be integer</Text>
                                            )}
                                        </Box>
                                    </Flex>

                                    <Box w="100%">
                                        {Boolean(rawMatLength) && (
                                            <Flex
                                                onClick={() => handleDeleteFormRawMaterial(item)}
                                                justifyContent="flex-end"
                                                my="10px"
                                                cursor="pointer"
                                            >
                                                <RemoveIcon />
                                            </Flex>
                                        )}

                                        {index ==
                                            formData?.stock_raw_material?.filter((isDelete) => !isDelete?.delete)
                                                ?.length -
                                                1 && (
                                            <Box display={index === 4 ? 'none' : 'block'}>
                                                <Box onClick={handleAddMaterial} className="btn-add-new-material">
                                                    <AddIconBlue />
                                                    Add More
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        );
                    })}
            </Box>
        </Flex>
    );
}

export default Index;
