export const NotesIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 5.5C6 5.22386 6.22386 5 6.5 5H11.5L14 7.5V14.5C14 14.7761 13.7761 15 13.5 15H6.5C6.22386 15 6 14.7761 6 14.5V5.5Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path d="M8 9H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 11H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="10" cy="10" r="9.5" stroke="#1746A2" />
    </svg>
);

export function ArrowUpIconBlack() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <g clipPath="url(#clip0_2975_36392)">
                <path fill="#2D2D2D" d="M6 13.98l1.41 1.41L12 10.81l4.59 4.58L18 13.98l-6-6-6 6z"></path>
            </g>
            <defs>
                <clipPath id="clip0_2975_36392">
                    <path fill="#fff" d="M0 0H24V24H0z" transform="rotate(-90 12 12)"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export const CalendarIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M12 1.5V4.5" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 1.5V4.5" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M2.25 7.5H15.75" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const LeftArrowIcon = ({ color }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4141 11.9998L14.0001 15.5858C14.3911 15.9768 14.3911 16.6098 14.0001 16.9998C13.6091 17.3908 12.9761 17.3908 12.5861 16.9998L9.00006 13.4138C8.21906 12.6328 8.21906 11.3668 9.00006 10.5858L12.5861 6.99979C12.9761 6.60879 13.6091 6.60879 14.0001 6.99979C14.3911 7.39079 14.3911 8.02379 14.0001 8.41379L10.4141 11.9998Z"
            fill={color}
        />
    </svg>
);

export const RightArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5859 11.9998L9.99991 15.5858C9.60991 15.9758 9.60991 16.6088 9.99991 16.9998C10.3899 17.3908 11.0239 17.3908 11.4139 16.9998L14.9999 13.4138C15.7809 12.6328 15.7809 11.3668 14.9999 10.5858L11.4149 6.99979C11.0239 6.60879 10.3909 6.60879 9.99991 6.99979C9.60891 7.39079 9.60991 8.02379 9.99991 8.41379L13.5859 11.9998Z"
            fill="#686868"
        />
    </svg>
);
