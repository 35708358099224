export const EditIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.625 15.75H16.125" stroke="#1746A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.125 10.02V12.75H6.86895L14.625 4.99054L11.8857 2.25L4.125 10.02Z"
            stroke="#1746A2"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);

export const AddIcon = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export function AddIconBlue() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path
                stroke="#4163A4"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 3.75v10.5M3.75 9h10.5"
            ></path>
        </svg>
    );
}
