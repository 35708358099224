import { Flex, Box } from '@chakra-ui/react';
import { tableArchivedList } from '../../const';
import './styles.scss';

const TableColumn = ({ list, handleClickRestore, handleClickNote }) => {
    return (
        <Flex className="table-column table-column__container__section-archived-list">
            {tableArchivedList.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        flex={item.flex}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: list[item.value] || '-',
                            handleClickRestore,
                            handleClickNote,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;
