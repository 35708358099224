export const EditIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.625 15.75H16.125" stroke="#1746A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.125 10.02V12.75H6.86895L14.625 4.99054L11.8857 2.25L4.125 10.02Z"
            stroke="#1746A2"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);

export const AddIcon = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const EmptyDataIcon = () => (
    <svg width="104" height="100" viewBox="0 0 104 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1522_29101)">
            <path
                d="M65.8163 22.4898H38.2074C37.5782 22.4905 36.975 22.7408 36.5301 23.1856C36.0853 23.6304 35.835 24.2335 35.8342 24.8625V86.2143L35.5178 86.3108L28.7449 88.3846C28.4239 88.4825 28.0772 88.449 27.7809 88.2915C27.4846 88.134 27.2629 87.8654 27.1644 87.5447L7.01812 21.7479C6.92008 21.4269 6.95349 21.0802 7.11102 20.7839C7.26855 20.4876 7.5373 20.2659 7.85821 20.1676L18.2953 16.9723L48.5526 7.71217L58.9896 4.51685C59.1484 4.46797 59.3154 4.45089 59.4808 4.46659C59.6462 4.48228 59.807 4.53044 59.9538 4.60832C60.1006 4.68619 60.2306 4.79224 60.3363 4.9204C60.4421 5.04857 60.5215 5.19633 60.5701 5.35523L65.7198 22.1734L65.8163 22.4898Z"
                fill="#F2F2F2"
            />
            <path
                d="M71.84 22.174L65.6335 1.90423C65.5302 1.5665 65.3614 1.25242 65.1367 0.979943C64.912 0.707467 64.6358 0.481933 64.3239 0.316228C64.012 0.150523 63.6704 0.0478941 63.3188 0.0142089C62.9672 -0.0194762 62.6124 0.0164428 62.2747 0.11991L47.6009 4.61076L17.3451 13.8725L2.6713 18.3649C1.98963 18.5742 1.41886 19.0453 1.08427 19.675C0.74968 20.3046 0.678608 21.0412 0.886658 21.7231L22.0993 90.9968C22.2683 91.5473 22.6093 92.0291 23.0723 92.3716C23.5353 92.7141 24.0959 92.8992 24.6718 92.8998C24.9383 92.8999 25.2034 92.8599 25.458 92.7811L35.5169 89.7029L35.8333 89.6048V89.2742L35.5169 89.3707L25.3647 92.479C24.763 92.6624 24.1131 92.5997 23.5576 92.3046C23.0021 92.0096 22.5863 91.5062 22.4015 90.9051L1.19048 21.6298C1.09889 21.3319 1.06697 21.0189 1.09655 20.7087C1.12612 20.3985 1.2166 20.0971 1.36282 19.8219C1.50904 19.5467 1.70813 19.303 1.94867 19.1049C2.1892 18.9067 2.46648 18.7579 2.76461 18.667L17.4384 14.1746L47.6943 4.91448L62.3681 0.422045C62.5942 0.353045 62.8293 0.317863 63.0658 0.317643C63.5732 0.318782 64.0669 0.482337 64.4745 0.784353C64.8822 1.08637 65.1825 1.51098 65.3314 1.99598L71.5094 22.174L71.6075 22.4903H71.9366L71.84 22.174Z"
                fill="#3F3D56"
            />
            <path
                d="M20.1794 20.22C19.8745 20.2197 19.5776 20.1218 19.3324 19.9406C19.0872 19.7593 18.9066 19.5043 18.817 19.2128L16.7792 12.5577C16.7245 12.379 16.7055 12.1912 16.7233 12.0051C16.7411 11.819 16.7955 11.6382 16.8832 11.4731C16.9709 11.308 17.0903 11.1618 17.2346 11.0428C17.3788 10.9238 17.5451 10.8344 17.7239 10.7797L45.559 2.25931C45.92 2.14914 46.3101 2.18672 46.6435 2.36379C46.9769 2.54086 47.2265 2.84296 47.3373 3.20379L49.3751 9.85896C49.4852 10.22 49.4476 10.61 49.2705 10.9433C49.0934 11.2767 48.7913 11.5261 48.4305 11.6371L20.5954 20.1575C20.4606 20.1988 20.3204 20.2199 20.1794 20.22Z"
                fill="#6E83AC"
            />
            <path
                d="M30.8536 7.11058C32.6011 7.11058 34.0178 5.69415 34.0178 3.94689C34.0178 2.19963 32.6011 0.783203 30.8536 0.783203C29.1061 0.783203 27.6895 2.19963 27.6895 3.94689C27.6895 5.69415 29.1061 7.11058 30.8536 7.11058Z"
                fill="#6E83AC"
            />
            <path
                d="M30.8533 5.95005C31.9598 5.95005 32.8569 5.05312 32.8569 3.9467C32.8569 2.84029 31.9598 1.94336 30.8533 1.94336C29.7467 1.94336 28.8496 2.84029 28.8496 3.9467C28.8496 5.05312 29.7467 5.95005 30.8533 5.95005Z"
                fill="white"
            />
            <path
                d="M96.1122 92.0917H42.6377C42.2812 92.0913 41.9394 91.9495 41.6873 91.6974C41.4352 91.4453 41.2934 91.1036 41.293 90.7471V26.6825C41.2934 26.326 41.4352 25.9842 41.6873 25.7321C41.9394 25.4801 42.2812 25.3383 42.6377 25.3379H96.1122C96.4687 25.3383 96.8105 25.4801 97.0626 25.7322C97.3147 25.9842 97.4566 26.326 97.457 26.6825V90.7471C97.4565 91.1036 97.3147 91.4453 97.0626 91.6974C96.8105 91.9495 96.4687 92.0913 96.1122 92.0917Z"
                fill="#E6E6E6"
            />
            <path
                d="M71.51 22.1738H38.2071C37.4941 22.1748 36.8106 22.4585 36.3064 22.9626C35.8023 23.4667 35.5186 24.1501 35.5176 24.863V89.3705L35.834 89.274V24.863C35.8348 24.2339 36.085 23.6308 36.5299 23.186C36.9748 22.7412 37.578 22.491 38.2071 22.4902H71.6081L71.51 22.1738ZM100.541 22.1738H38.2071C37.4941 22.1748 36.8106 22.4585 36.3064 22.9626C35.8023 23.4667 35.5186 24.1501 35.5176 24.863V97.3114C35.5186 98.0243 35.8023 98.7077 36.3064 99.2118C36.8106 99.7159 37.4941 99.9995 38.2071 100.001H100.541C101.254 99.9995 101.938 99.7159 102.442 99.2118C102.946 98.7077 103.23 98.0243 103.231 97.3114V24.863C103.23 24.1501 102.946 23.4667 102.442 22.9626C101.938 22.4585 101.254 22.1748 100.541 22.1738ZM102.914 97.3114C102.914 97.9405 102.663 98.5435 102.218 98.9883C101.774 99.4332 101.17 99.6834 100.541 99.6842H38.2071C37.578 99.6834 36.9748 99.4332 36.5299 98.9883C36.085 98.5435 35.8348 97.9405 35.834 97.3114V24.863C35.8348 24.2339 36.085 23.6308 36.5299 23.186C36.9748 22.7412 37.578 22.491 38.2071 22.4902H100.541C101.17 22.491 101.774 22.7412 102.218 23.186C102.663 23.6308 102.914 24.2339 102.914 24.863V97.3114Z"
                fill="#3F3D56"
            />
            <path
                d="M83.9288 29.1336H54.8184C54.4409 29.1332 54.079 28.983 53.812 28.7162C53.5451 28.4493 53.395 28.0874 53.3945 27.7099V20.7498C53.395 20.3724 53.5451 20.0105 53.812 19.7436C54.079 19.4767 54.4409 19.3266 54.8184 19.3262H83.9288C84.3063 19.3266 84.6682 19.4767 84.9351 19.7436C85.2021 20.0105 85.3522 20.3724 85.3526 20.7498V27.7099C85.3522 28.0874 85.2021 28.4493 84.9351 28.7162C84.6682 28.983 84.3063 29.1332 83.9288 29.1336Z"
                fill="#6E83AC"
            />
            <path
                d="M69.3751 19.8C71.1226 19.8 72.5393 18.3836 72.5393 16.6363C72.5393 14.8891 71.1226 13.4727 69.3751 13.4727C67.6276 13.4727 66.2109 14.8891 66.2109 16.6363C66.2109 18.3836 67.6276 19.8 69.3751 19.8Z"
                fill="#6E83AC"
            />
            <path
                d="M69.3745 18.5629C70.4389 18.5629 71.3018 17.7002 71.3018 16.636C71.3018 15.5717 70.4389 14.709 69.3745 14.709C68.3101 14.709 67.4473 15.5717 67.4473 16.636C67.4473 17.7002 68.3101 18.5629 69.3745 18.5629Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_1522_29101">
                <rect width="102.462" height="100" fill="white" transform="translate(0.769531)" />
            </clipPath>
        </defs>
    </svg>
);
