import { Flex, Skeleton, Box } from '@chakra-ui/react';
import './styles.scss';

const TableSkeleton = ({ tableList, tableHeadHeight, count, tablColumnHeight }) => {
    return (
        <Box className="table-skeleton__container">
            <Flex className="section-head__container__table-skeleton">
                {tableList.map((table, index) => (
                    <Flex
                        key={index}
                        className="section-head__item-container"
                        h={tableHeadHeight}
                        w={table.width}
                        flex={table.flex}
                    >
                        <Skeleton
                            borderRadius="8px"
                            width="75%"
                            h="100%"
                            startColor="#97c3fe"
                            endColor="rgba(#97c3fe,0.5)"
                        />
                    </Flex>
                ))}
            </Flex>
            {count.map((_, idx) => (
                <Flex key={idx} className="section-body__container__table-skeleton">
                    {tableList.map((table, index) => (
                        <Flex
                            key={index}
                            h={tablColumnHeight}
                            w={table.width}
                            flex={table.flex}
                            className="section-body__item-container"
                        >
                            <Skeleton borderRadius="8px" width="75%" h="100%" />
                        </Flex>
                    ))}
                </Flex>
            ))}
        </Box>
    );
};

export default TableSkeleton;
