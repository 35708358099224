import { isEmptyString } from '@helpers/functions';
import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const handleCancelEdit = ({ setRefetch, setOrderDelivery, setIsEdit, itemEdited, setItemEdited }) => {
    setOrderDelivery((prev) => {
        let clone = [...prev];
        clone.forEach((item) => {
            itemEdited?.forEach((itemEdit) => {
                if (item?.id === itemEdit?.id) {
                    item.qty_revisi = itemEdit?.prev_qty_revisi || '';
                    item.notes = itemEdit?.prev_notes || '';
                }
            });
        });
        return clone;
    });
    setItemEdited([]);
    setIsEdit(false);
    setRefetch((prev) => {
        return prev + 1;
    });
};
export const handleEdit = ({ setIsEdit, setOrderDelivery }) => {
    setOrderDelivery((prev) => {
        let clone = [...prev];
        clone?.forEach((item) => {
            if (item?.qty_revisi === null) {
                item.qty_revisi = item?.qty_konfirmasi;
            }
        });
        return clone;
    });
    setIsEdit(true);
};
export const handleConfirm = async ({
    itemEdited,
    toast,
    setIsLoading,
    setItemEdited,
    setIsEdit,
    filter,
    setRefetch,
    onClose,
    jenisMaterial,
    supplier,
    setAutocorrectionList,
}) => {
    const { inputDate, itemCode, trade_name } = filter;
    let supplierFilter = supplier?.join(';');
    let tradeNameFilter = trade_name
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.trade_name)
        ?.join(';');
    let tradeItemCode = itemCode
        ?.filter((item) => item?.checked)
        ?.map((item) => item?.kode_item)
        ?.join(';');
    const filterStringify = queryString.stringify(
        {
            trade_name: tradeNameFilter,
            kode_item: tradeItemCode,
            jenis_material_id: jenisMaterial?.id,
            supplier_name: supplierFilter,
            eta_update: inputDate,
        },
        { skipEmptyString: true }
    );
    let initialData = [...itemEdited];
    initialData = initialData?.map((item) => {
        return {
            ...item,
            qty_revisi:
                typeof item?.qty_revisi === 'string' ? Number(item?.qty_revisi?.replaceAll(',', '')) : item?.qty_revisi,
        };
    });
    let isErrorValidation = false;
    let itemError = [];
    for (let index = 0; index < initialData.length; index++) {
        /// VALIDASI JIKA REVISI QTY KURANG DARI 0
        if (initialData[index]?.qty_revisi < 0) {
            isErrorValidation = true;
            itemError.push({
                id: initialData[index]?.id,
                has_qty_revisi_error: 'Revisi Qty tidak boleh kurang dari 0',
            });
        }
        /// VALIDASI JIKA REVISI QTY KURANG DARI QUANTITY KONFIRMASI DAN USER TIDAK MENGISI NOTES
        if (
            initialData[index]?.qty_revisi < Number(initialData[index]?.qty_konfirmasi) &&
            Number(initialData[index]?.qty_revisi) !== 0 &&
            isEmptyString(initialData[index]?.notes)
        ) {
            isErrorValidation = true;
            itemError.push({
                id: initialData[index]?.id,
                has_notes_error: 'Notes wajib diisi jika Revisi Qty kurang dari quantity konfirmasi',
            });
        }
    }
    /// SET ERROR MESSAGE
    if (isErrorValidation) {
        setItemEdited((prev) => {
            let clone = [...prev];
            clone.forEach((item) => {
                item.has_notes_error = null;
                item.has_qty_revisi_error = null;
                itemError?.forEach((errorItem) => {
                    if (item?.id === errorItem?.id) {
                        item.has_notes_error = errorItem?.has_notes_error;
                        item.has_qty_revisi_error = errorItem?.has_qty_revisi_error;
                    }
                });
                return item;
            });

            return clone;
        });
        toast({
            description: 'Confirm gagal. Periksa kembali!',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top-center',
        });
        onClose();
        return;
    }

    setIsLoading(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const data = initialData
        ?.filter((emptyItem) => emptyItem?.qty_revisi !== '')
        ?.map((item) => ({
            kode_item: item['orderManagement.materialConfig.kode_item'],
            no_order: item?.no_order,
            supplier_name: item['orderManagement.materialConfig.supplier.name'],
            revisi_qty: isEmptyString(item?.qty_revisi) ? 0 : Number(item?.qty_revisi),
            notes: item?.notes || '',
        }));
    await Axios({
        method: 'POST',
        url: `/order-management/delivery?${filterStringify}`,
        headers: { Authorization: 'Bearer ' + userToken },
        data,
    })
        .then((res) => {
            const autoCorrectList = res?.data?.data?.autoCorrectList;

            setItemEdited([]);
            toast({
                position: 'center-top',
                description: res.data?.data?.message,
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            if (autoCorrectList?.length) {
                setAutocorrectionList(autoCorrectList);
            }

            setRefetch((prev) => {
                return prev + 1;
            });
            setIsEdit(false);
        })
        .catch((error) => {
            try {
                toast({
                    title: 'Error',
                    description: error?.response?.data?.errors[0]?.message
                        ? error?.response?.data?.errors?.map((item) => item?.message)?.join()
                        : error?.response?.data?.errors?.join(),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-center',
                });
            } catch (error) {
                toast({
                    description: 'Invalid Handling Error',
                    status: 'error',
                    duration: 7000,
                    isClosable: true,
                    position: 'top-center',
                });
                throw Error('Invalid Handling Error');
            }
        })
        .finally(() => {
            setIsLoading(false);
            onClose();
        });
};
