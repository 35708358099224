import { Box, Flex } from '@chakra-ui/react';
import TableHead from '@pagesPhase2/orderManagement/component/tableHead';
import TableColumn from '@pagesPhase2/orderManagement/component/tableColumn';
import './styles.scss';

const SectionTable = ({ orders }) => {
    return (
        <>
            <Flex
                className={`section-table-order_management section-table__container__table-order_management ${
                    orders.length <= 0 && 'hide-scrollbar-section-table__container__section-table-order_management'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {orders?.map((item, index) => {
                            return <TableColumn key={index} ordersData={item} />;
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;