import { Box, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, DownloadBlueIcon } from '../../icons';
import ModalFilterMultiple from './modalFilterMultiple';
import { TableRekapDeliveryContext } from '../..';
import React from 'react';

import './styles.scss';
import FilterItemCode from '@components/filterItemCode';
import FilterTradeName from '@components/filterTradeName';

const SectionHead = ({ onDownload }) => {
    const { setFilter, setPage: setMainPage } = React.useContext(TableRekapDeliveryContext);
    const navigate = useNavigate();
    return (
        <div>
            <Flex className="rekap-delivery-box-header">
                <Flex gap="10px" alignItems="center" cursor="pointer" onClick={() => navigate('/raw-data-order')}>
                    <Box>
                        <ChevronLeftIcon />
                    </Box>
                    <Text className="text-order-confirmation">Rekap Delivery</Text>
                </Flex>
                <Flex className="action-button-container" alignItems="center">
                    <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                    <Box mx="12px">
                        <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                    </Box>
                    <ModalFilterMultiple />
                    <Box className="divider" />
                    <Box className="box-download-data" onClick={onDownload}>
                        <DownloadBlueIcon />
                        <Text className="text-download-data">Download Data</Text>
                    </Box>
                </Flex>
            </Flex>
        </div>
    );
};

export default SectionHead;
