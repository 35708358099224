export const NotesIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 5.5C6 5.22386 6.22386 5 6.5 5H11.5L14 7.5V14.5C14 14.7761 13.7761 15 13.5 15H6.5C6.22386 15 6 14.7761 6 14.5V5.5Z"
            stroke="#1746A2"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path d="M8 9H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 11H12" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <circle cx="10" cy="10" r="9.5" stroke="#1746A2" />
    </svg>
);

export const ChevronLeftIcon = () => (
    <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.83777 5.14683L2.48537 5.5L2.83777 5.85317L6.30165 9.32461L5.83337 9.79289L1.54048 5.5L5.83337 1.20711L6.30165 1.67539L2.83777 5.14683Z"
            fill="white"
            stroke="#4163A4"
        />
    </svg>
);

export const DownloadBlueIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.8027 9.00199L8.65625 9.85554V8.64844V2.8125C8.65625 2.72133 8.69247 2.6339 8.75693 2.56943C8.8214 2.50497 8.90883 2.46875 9 2.46875C9.09117 2.46875 9.1786 2.50497 9.24307 2.56943C9.30753 2.6339 9.34375 2.72133 9.34375 2.8125V8.64844V9.85554L10.1973 9.00199L11.709 7.49027C11.7738 7.42553 11.8616 7.38916 11.9531 7.38916C12.0447 7.38916 12.1325 7.42553 12.1972 7.49027C12.262 7.55501 12.2983 7.64282 12.2983 7.73438C12.2983 7.82593 12.262 7.91374 12.1972 7.97848L9.24642 10.9293C9.24611 10.9296 9.2458 10.9299 9.2455 10.9302C9.17994 10.9944 9.09181 11.0304 9 11.0304C8.90819 11.0304 8.82006 10.9944 8.7545 10.9302C8.7542 10.9299 8.75389 10.9296 8.75358 10.9293L5.80277 7.97848C5.73803 7.91374 5.70166 7.82593 5.70166 7.73438C5.70166 7.64282 5.73803 7.55501 5.80277 7.49027C5.86751 7.42553 5.95532 7.38916 6.04688 7.38916C6.13843 7.38916 6.22624 7.42553 6.29098 7.49027L7.8027 9.00199ZM14.8438 14.8438V14.3438V10.6875C14.8438 10.5963 14.88 10.5089 14.9444 10.4444L14.5909 10.0909L14.9444 10.4444C15.0089 10.38 15.0963 10.3438 15.1875 10.3438C15.2787 10.3438 15.3661 10.38 15.4306 10.4444L15.7841 10.0909L15.4306 10.4444C15.495 10.5089 15.5312 10.5963 15.5312 10.6875V14.6236C15.5298 14.8638 15.4337 15.0939 15.2638 15.2638C15.0939 15.4337 14.8638 15.5298 14.6236 15.5312H3.37645C3.13618 15.5298 2.90615 15.4337 2.73623 15.2638L2.38349 15.6165L2.73623 15.2638C2.56632 15.0939 2.47023 14.8639 2.46875 14.6236V10.6875C2.46875 10.5963 2.50497 10.5089 2.56943 10.4444L2.21588 10.0909L2.56943 10.4444C2.6339 10.38 2.72133 10.3438 2.8125 10.3438C2.90367 10.3438 2.9911 10.38 3.05557 10.4444L3.40912 10.0909L3.05557 10.4444C3.12003 10.5089 3.15625 10.5963 3.15625 10.6875V14.3438V14.8438H3.65625H14.3438H14.8438Z"
            stroke="#4163A4"
        />
    </svg>
);

export function FilterIcon({ color = '#2D2D2D' }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
            <path
                stroke={color}
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M2.25 3.375l5.4 6.307v4.735l2.7 1.333V9.682l5.4-6.307H2.25z"
            ></path>
        </svg>
    );
}
