import { useState } from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';
import { DownloadIcon, SearchIcon } from '@pages/skuConfiguration/icons';
import './styles.scss';
import { isAllowed } from '@helpers/roleAccess';

const SeactionSearch = ({ onChangeModalState, onSkuExportData, onSearchHandler }) => {
    const [searchKey, setSearchKey] = useState('');

    return (
        <Flex className="section-head__container__sku-configuration">
            <Box className="section-left section-search__container__sku-configuration">
                <input
                    className="search-input"
                    placeholder="Cari nama produk"
                    onChange={(e) => setSearchKey(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            onSearchHandler({ value: searchKey });
                        }
                    }}
                />
                <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchKey })}>
                    <SearchIcon />
                </Box>
            </Box>
            <Box className="section-right">
                {isAllowed({
                    pageName: 'SKU Management',
                    permissionName: 'Download format excel',
                }) && (
                    <Box
                        className="button-download  section-right__button"
                        cursor={'pointer'}
                        experimental_spaceX={2}
                        onClick={onSkuExportData}
                    >
                        <DownloadIcon />
                        <Text className="button-text" pt="3px" ml="10px !important">
                            Download
                        </Text>
                    </Box>
                )}
                {isAllowed({ pageName: 'SKU Management', permissionName: 'Manual add item' }) && (
                    <Box
                        onClick={() => onChangeModalState({ value: 'modal-create', isEdit: false })}
                        className="button-add section-right__button"
                        cursor="pointer"
                    >
                        <Text className="button-text">Add Item Manualy</Text>
                    </Box>
                )}
            </Box>
        </Flex>
    );
};

export default SeactionSearch;
