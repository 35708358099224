export function SearchIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
            <path
                fill="#000"
                d="M19.6 21l-6.3-6.3A6.096 6.096 0 019.5 16c-1.817 0-3.354-.63-4.612-1.888C3.63 12.853 3.001 11.316 3 9.5c0-1.817.63-3.354 1.888-4.612C6.147 3.63 7.684 3.001 9.5 3c1.817 0 3.354.63 4.612 1.888C15.37 6.147 15.999 7.684 16 9.5a6.096 6.096 0 01-1.3 3.8l6.3 6.3-1.4 1.4zM9.5 14c1.25 0 2.313-.438 3.188-1.313S14.001 10.749 14 9.5c0-1.25-.438-2.313-1.313-3.188S10.749 4.999 9.5 5c-1.25 0-2.313.438-3.188 1.313S4.999 8.251 5 9.5c0 1.25.438 2.313 1.313 3.188S8.251 14.001 9.5 14z"
            ></path>
        </svg>
    );
}

export const InfoIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99967 14.6666C9.84061 14.6666 11.5073 13.9204 12.7137 12.714C13.9201 11.5075 14.6663 9.84085 14.6663 7.99992C14.6663 6.15899 13.9201 4.49232 12.7137 3.28587C11.5073 2.07945 9.84061 1.33325 7.99967 1.33325C6.15874 1.33325 4.49207 2.07945 3.28563 3.28587C2.0792 4.49232 1.33301 6.15899 1.33301 7.99992C1.33301 9.84085 2.0792 11.5075 3.28563 12.714C4.49207 13.9204 6.15874 14.6666 7.99967 14.6666Z"
            stroke="#1746A2"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00033 3.66675C8.46056 3.66675 8.83366 4.03985 8.83366 4.50008C8.83366 4.96031 8.46056 5.33341 8.00033 5.33341C7.54009 5.33341 7.16699 4.96031 7.16699 4.50008C7.16699 4.03985 7.54009 3.66675 8.00033 3.66675Z"
            fill="#1746A2"
        />
        <path d="M8.16667 11.3334V6.66675H7.83333H7.5" stroke="#1746A2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 11.3333H9.33333" stroke="#1746A2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
