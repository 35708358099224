import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { PyramidHamburgerIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { handleCloseModalFilter, fetchDataInfinite } from './function';
import ModalFilter from './modalFilterChecked';
import { useSelector } from 'react-redux';
import './styles.scss';

function FilterTradeName({ setFilter, setMainPage }) {
    const { supplierRef, jenisMaterial } = useSelector((reducer) => reducer.globalFilterSlice);
    const [selectedData, setSelectedData] = React.useState([]);
    const [openModalFilter, setOpenModalFilter] = React.useState(false);
    const [tradeName, setTradeName] = React.useState([]);
    const [tradeNameChoosed, setTradeNameChoosed] = React.useState([]);
    const [totalData, setTotalData] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [search, setSearch] = React.useState('');
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [refetch, setRefetch] = React.useState(false);
    const dataSelected = tradeNameChoosed?.filter((item) => item.checked);

    useEffect(() => {
        if (openModalFilter) {
            fetchDataInfinite({
                page: supplierRef && jenisMaterial ? 1 : page,
                setTradeName,
                tradeName,
                setPage,
                setTotalData,
                search,
                tradeNameChoosed,
                setLoadingSearch,
                supplierRef,
                jenisMaterial,
            });
        }
    }, [refetch, supplierRef, jenisMaterial, openModalFilter]);

    const getMoreData = ({ moreData }) => {
        fetchDataInfinite({
            page,
            setTradeName,
            tradeName,
            setPage,
            setTotalData,
            moreData: Boolean(moreData),
            search,
            tradeNameChoosed,
            setLoadingSearch,
            supplierRef,
            jenisMaterial,
        });
    };

    const onSearchHandler = () => {
        fetchDataInfinite({
            page: 1,
            setTradeName,
            tradeName,
            setPage,
            setTotalData,
            search,
            tradeNameChoosed,
            setLoadingSearch,
            supplierRef,
            jenisMaterial,
        });
    };

    const handleApplyFilter = () => {
        setOpenModalFilter(false);
        setSelectedData(dataSelected);
        setFilter((prev) => ({ ...prev, trade_name: dataSelected }));
        setPage(1);
        setMainPage(1);
        setSearch('');
        setRefetch(!refetch);
    };
    const handleResetFilter = () => {
        handleCloseModalFilter({
            selectedData,
            data: tradeName,
            setData: setTradeName,
            type: 'reset',
            setTradeNameChoosed,
        });
        setPage(1);
        setSearch('');
        setRefetch(!refetch);
    };

    return (
        <Box className="container-filter-trade-name-component">
            <ModalFilter
                tradeNameChoosed={tradeNameChoosed}
                onSearchHandler={onSearchHandler}
                setTradeNameChoosed={setTradeNameChoosed}
                setSearch={setSearch}
                search={search}
                getMoreData={getMoreData}
                totalData={totalData}
                loadingSearch={loadingSearch}
                outlineLabel="Reset Filter"
                modalTitle="Filter Trade Name"
                labelSearch="Pilih trade name"
                labelSelectedData="Trade name yang dipilih"
                tradeName={tradeName}
                setTradeName={setTradeName}
                isOpen={openModalFilter}
                onClose={() => {
                    handleCloseModalFilter({
                        setOpenModalFilter,
                        selectedData,
                        data: tradeName,
                        setData: setTradeName,
                        setTradeNameChoosed,
                    });
                    setPage(1);
                    setSearch('');
                    setRefetch(!refetch);
                }}
                selectedData={selectedData}
                handleApplyFilter={handleApplyFilter}
                handleResetFilter={handleResetFilter}
            />
            <Box
                onClick={() => {
                    setOpenModalFilter(true);
                }}
                className={`btn-filter-code-item-container ${selectedData?.length > 0 && 'has-trade-name-selected'}`}
            >
                <Text className={`btn-filter-text`}>Cari Trade name</Text>
                <Box className={`btn-filter-code-item-icon`}>
                    <PyramidHamburgerIcon fill={selectedData?.length > 0 ? '#1746A2' : '#2D2D2D'} />
                </Box>
                {selectedData?.length > 0 && <Text className="btn-filter-badge">{selectedData?.length}</Text>}
            </Box>
        </Box>
    );
}

export default FilterTradeName;
