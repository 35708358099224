import React from 'react';
import { Flex, Box, useToast } from '@chakra-ui/react';
import { MaterialConfigurationContext } from '@pagesPhase2/materialConfiguration';
import './styles.scss';

const TableColumn = ({
    shortenTradeName,
    material,
    onEditHandler,
    onOpenModal,
    onModalDelete,
    onSelectedValue,
    onClickWarning,
    togglerDeleteMaterialModal,
}) => {
    const toast = useToast();
    const { tableListMC } = React.useContext(MaterialConfigurationContext);
    const onDeleteMaterial = () => {
        onSelectedValue({ value: material });
        togglerDeleteMaterialModal();
    };

    return (
        <Flex className="table-column table-column__container__material-configuration">
            {tableListMC.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: material[item.value] !== null ? material[item.value] : '-',
                            material,
                            shortenTradeName,
                            onEditHandler,
                            onOpenModal,
                            onModalDelete,
                            onSelectedValue,
                            onClickWarning,
                            onDeleteMaterial,
                            toast,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;
