import { useState, useEffect } from 'react';
import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { LeftArrowIcon } from '@pagesPhase2/materialConfiguration/icons';
import ModalConfirm from '@components/modalConfirm';
import ModalSuccess from '@components/modalSuccess';
import ModalEditNote from './components/modalEditNote';
import Pagination from '@components/pagination';
import EmptyState from '@components/emptyState';
import TableSkeleton from '@components/tableSkeleton';
import SectionTable from './sections/sectionTableArchiveList/index';
import { fetchArchivedListHandler, handleSaveNote, handleSubmitRestore } from './function';
import { tableArchivedList } from './const';
import { numToArr } from '@helpers/functions';

import './styles.scss';

const SectionArchiveList = () => {
    const navigate = useNavigate();
    const toast = useToast();
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState({ isOpen: false, message: '' });
    const [openModalEditNote, setOpenModalEditNote] = useState({ isOpen: false, note: '' });
    const [filter, setFilter] = useState([]);
    const [archivedList, setArchivedList] = useState([]);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState('');
    const [loading, setLoading] = useState(false);

    const handlePageClick = ({ selected }) => setPage(selected + 1);

    const handleClickRestore = (item) => {
        setOpenModalConfirm(true);
        setSelectedItem(item);
    };
    const handleClickNote = ({ note }) => {
        setSelectedItem(note);
        setOpenModalEditNote(true);
        setOpenModalEditNote((prev) => ({ note: note?.notes, isOpen: true }));
    };

    useEffect(() => {
        fetchArchivedListHandler({
            filter,
            page,
            setArchivedList,
            setTotalData,
            setTotalPage,
            setIsLoading,
            searchQuery,
            selectedPerPage,
        });
    }, [page, searchQuery, filter, selectedPerPage, refetch]);

    return (
        <Box className="section-table-archived-list__container">
            <Flex mb="39px" alignItems="center">
                <Box onClick={() => navigate('/material-configuration')} cursor="pointer" mr="10px" display="flex">
                    <LeftArrowIcon color={'#1746A2'} />
                    <Text className="title-archive">Archive List</Text>
                </Box>
            </Flex>
            {isLoading && (
                <TableSkeleton
                    tableList={tableArchivedList}
                    count={numToArr(10)}
                    tableHeadHeight="50px"
                    tablColumnHeight="70px"
                />
            )}
            {!isLoading && (
                <SectionTable
                    archivedList={archivedList}
                    handleClickRestore={handleClickRestore}
                    handleClickNote={handleClickNote}
                />
            )}
            {!isLoading && !archivedList.length && (
                <EmptyState
                    description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                    text="Tidak ada data yg di tampilkan"
                />
            )}
            <Pagination
                onChangeLimit={() => {
                    setPage(1);
                }}
                page={page}
                totalPage={totalPage}
                totalData={totalData}
                selectedPerPage={selectedPerPage}
                setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                onPageChange={handlePageClick}
            />
            {/* ========== Modals ============ */}
            <ModalConfirm
                onClose={() => setOpenModalConfirm(false)}
                title="Apa anda yakin ingin merestor item ?"
                isOpen={openModalConfirm}
                submitLabel="Lanjut"
                loading={loading}
                onSubmit={() =>
                    handleSubmitRestore({
                        selectedItem,
                        setOpenModalConfirm,
                        setOpenModalSuccess,
                        toast,
                        setLoading,
                        setRefetch,
                    })
                }
            />

            <ModalEditNote
                onClose={({ blur, setEdit }) => {
                    setEdit();
                    blur();
                    setOpenModalEditNote((prev) => ({ ...prev, isOpen: false }));
                }}
                modalTitle="Catatan"
                isOpen={openModalEditNote.isOpen}
                handleSave={() => {
                    handleSaveNote({
                        selectedItem,
                        setOpenModalEditNote,
                        setOpenModalSuccess,
                        toast,
                        setLoading,
                        setRefetch,
                        note: openModalEditNote.note,
                    });
                }}
                isLoading={loading}
                note={openModalEditNote.note}
                setOpenModalEditNote={setOpenModalEditNote}
                outlineLabel="Edit Catatan"
            />

            <ModalSuccess
                isOpen={openModalSuccess.isOpen}
                title={openModalSuccess.message}
                description=""
                onClose={() => setOpenModalSuccess({ isOpen: false, message: '' })}
            />
        </Box>
    );
};

export default SectionArchiveList;
