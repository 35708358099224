import Axios from '@services/axios';
import Cookies from 'universal-cookie';

export const handleGenerate = async ({ setShowBottomForm, kode_item, supplier_name, toast, setIsLoading }) => {
    setIsLoading(true);
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    await Axios({
        method: 'GET',
        url: `po/list?limit=1&page=1&source=ODOO&kode_item=${kode_item}&supplier_name=${supplier_name}`,
        headers: { Authorization: 'Bearer ' + userToken },
    })
        .then((res) => {
            setShowBottomForm(true);
        })
        .catch((error) => {
            setShowBottomForm(false);
            toast({
                title: 'Error',
                description:
                    error?.response?.data?.errors?.map((item) => item?.message)?.join(', ') || 'Something wrong',
                status: 'error',
                duration: 7000,
                isClosable: true,
                position: 'top-center',
            });
        })
        .finally(() => {
            setIsLoading(false);
        });
};
