export const EditIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.625 15.75H16.125" stroke="#1746A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.125 10.02V12.75H6.86895L14.625 4.99054L11.8857 2.25L4.125 10.02Z"
            stroke="#1746A2"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);

export const AddIcon = () => (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.5 3.75V14.25" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.25 9H14.75" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const InfoIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99967 14.6666C9.84061 14.6666 11.5073 13.9204 12.7137 12.714C13.9201 11.5075 14.6663 9.84085 14.6663 7.99992C14.6663 6.15899 13.9201 4.49232 12.7137 3.28587C11.5073 2.07945 9.84061 1.33325 7.99967 1.33325C6.15874 1.33325 4.49207 2.07945 3.28563 3.28587C2.0792 4.49232 1.33301 6.15899 1.33301 7.99992C1.33301 9.84085 2.0792 11.5075 3.28563 12.714C4.49207 13.9204 6.15874 14.6666 7.99967 14.6666Z"
            stroke="#1746A2"
            strokeLinejoin="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.00033 3.66675C8.46056 3.66675 8.83366 4.03985 8.83366 4.50008C8.83366 4.96031 8.46056 5.33341 8.00033 5.33341C7.54009 5.33341 7.16699 4.96031 7.16699 4.50008C7.16699 4.03985 7.54009 3.66675 8.00033 3.66675Z"
            fill="#1746A2"
        />
        <path d="M8.16667 11.3334V6.66675H7.83333H7.5" stroke="#1746A2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 11.3333H9.33333" stroke="#1746A2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
