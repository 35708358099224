import { configureStore } from '@reduxjs/toolkit';
import sideMenuSlice from './list/sideMenu';
import loginSlice from './list/login';
import assetManagement from './list/assetManagement';
import materialSlice from './list/material';
import accessPermissionSlice from './list/accessPermission';
import globalFilterSlice from './list/globalFilter';
import downloadManagement from './list/downloadManagement';

export default configureStore({
    reducer: {
        sideMenuSlice,
        loginSlice,
        assetManagement,
        materialSlice,
        accessPermissionSlice,
        globalFilterSlice,
        downloadManagement,
    },
    middleware: (getDefaultMiddlewares) =>
        getDefaultMiddlewares({
            serializableCheck: false,
        }),
});
