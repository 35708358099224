import { Modal, ModalOverlay, ModalContent, Box, Button, Text, Flex } from '@chakra-ui/react';
import { SuccessIcon } from './icons';

import './styles.scss';

const ModalAutocorrection = ({
    isOpen,
    onClose,
    labelButton = 'Kembali',
    nextAction = false,
    autocorrectionList,
    isOrderDelivery,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <Box className="modal-autocorrection__container">
                    <SuccessIcon />
                    <Text className="success-title">Berhasil melakukan update, dengan beberapa notes:</Text>
                    <Flex className="description-container">
                        {autocorrectionList?.map((item, idx) => (
                            <Box className="success-description">
                                <p>{idx + 1}. </p>
                                {isOrderDelivery
                                    ? `Kode item ${item?.kode_item} telah dikoreksi dari ${item?.revisi_qty_before} menjadi ${item?.revisi_qty_after} sesuai qty in preparation`
                                    : `Kode item ${item?.kode_item}  telah dikoreksi sesuai kelipatan kanban dari ${item?.konfirmasi_pcs_before} menjadi ${item?.konfirmasi_pcs_after} `}
                            </Box>
                        ))}
                    </Flex>
                    <Button className="back-button" onClick={nextAction || onClose} _hover="none">
                        {labelButton}
                    </Button>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalAutocorrection;
