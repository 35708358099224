import React from 'react';
import { Table as TableChakra, Thead, Tbody, Tr, Th, Td, Box, Input, Text, Flex, Tooltip } from '@chakra-ui/react';
import { statusCondition } from './const';
import { NoteIcon } from '@pagesPhase2/supplierStock/icons';

import './styles.scss';
import { EditCircleIcon } from '../../icons';

function Table({ head = [], body = [] }) {
    return (
        <Box className="table-additional-upload-supplier-stock">
            <TableChakra className="table-container-archived">
                <Thead>
                    <Tr className="container-head">
                        {head.map((tHead, index) => {
                            const status = tHead.id?.toString().toLowerCase();
                            const statusWidth = status?.includes('status') ? 25 : 0;
                            const notesWidth = status?.includes('notes') ? 100 : 0;
                            return (
                                <Th
                                    key={index}
                                    className="title-head"
                                    sx={{
                                        minWidth: `${tHead?.widthCell * 10 + statusWidth + notesWidth}px`,
                                        textAlign:
                                            status?.includes('Status') || status?.includes('status')
                                                ? 'center'
                                                : 'left',
                                        paddingLeft: index === 0 ? '16px !important' : '6px !important',
                                    }}
                                >
                                    {tHead.name}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody className="table-body-container">
                    {body.map((tBody, bodyIndex) => (
                        <Tr className="table-tr" key={bodyIndex}>
                            {head.map((tHead, headIndex) => {
                                const headValue = tHead.name?.toString().toLowerCase();
                                const getStatusCondition = Boolean(tBody['status'])
                                    ? statusCondition.find((item) => item.name === tBody['status'])
                                    : statusCondition.find((item) => item.name === tBody['Status']);
                                const messageError = tBody[tHead.id]?.messageError || '';
                                const isErrorHighLight = Boolean(tBody[tHead.id]?.isError);
                                return (
                                    <Td
                                        className="table-td"
                                        sx={{
                                            paddingLeft: headIndex === 0 ? '10px !important' : '0px !important',
                                        }}
                                        key={headIndex}
                                    >
                                        {headValue?.includes('status') ? (
                                            <Flex justifyContent="center">
                                                <Text
                                                    sx={{
                                                        color: `${getStatusCondition?.color} !important`,
                                                        bgColor: `${getStatusCondition?.bgColor} !important`,
                                                    }}
                                                    className="table-column-status"
                                                >
                                                    {tBody[tHead.id]?.value}
                                                </Text>
                                            </Flex>
                                        ) : (
                                            <Flex position="relative">
                                                <Tooltip
                                                    hasArrow
                                                    bg="red.500"
                                                    label={Boolean(messageError) ? messageError : ''}
                                                >
                                                    <Input
                                                        readOnly
                                                        className={`input-value ${isErrorHighLight && 'empty-input'}`}
                                                        value={tBody[tHead.id]?.value}
                                                        pr={
                                                            Boolean(tHead?.id?.toLowerCase() === 'notes') &&
                                                            '37px !important'
                                                        }
                                                    />
                                                </Tooltip>
                                                {Boolean(tHead?.id?.toLowerCase() === 'notes') && (
                                                    <Box className="icon-note">
                                                        <NoteIcon />
                                                    </Box>
                                                )}
                                                {Boolean(tHead?.id?.toLowerCase() === 'manufacturing date') && (
                                                    <Box className="icon-circle-edit">
                                                        <EditCircleIcon />
                                                    </Box>
                                                )}
                                            </Flex>
                                        )}
                                    </Td>
                                );
                            })}
                        </Tr>
                    ))}
                </Tbody>
            </TableChakra>
        </Box>
    );
}

export default Table;
