import { Box, Flex } from '@chakra-ui/react';
import TableHead from '../../components/tableHead';
import TableColumn from '../../components/tableColumn';
import './styles.scss';

const SectionTable = ({ rawData, onEdit }) => {
    return (
        <>
            <Flex
                className={`section-table-raw-data_order ${
                    rawData.length <= 0 && 'hide-scrollbar-section-table__container__table-raw-data_order'
                }`}
            >
                <Box className="section-table">
                    <TableHead />
                    <Box className="columns__container" bg="white">
                        {rawData.map((item, index) => {
                            return <TableColumn key={index} dataItem={item} onEdit={() => onEdit(item)}/>;
                        })}
                    </Box>
                </Box>
            </Flex>
        </>
    );
};

export default SectionTable;
