import { Box } from '@chakra-ui/react';
import { createContext, useEffect, useState } from 'react';

import DetailProductStatus from '@pages/mainDashboard/components/detailProductStatus';
import TableHead from '../../components/tableHead';
import ProductionStatusSkeleton from '../../components/productionStatusSkeleton';
import { fetchProductStatus, getStreamlineColor } from './functions';
import TableColumn from '../../components/tableColumn';
import { tableList } from './const';

import './styles.scss';
import EmptyState from '@components/emptyState';
import { useSelector } from 'react-redux';

export const SectionProductionStatusContext = createContext();

const SectionProductionStatus = ({ selectedFilterStreamline, searchStreamline }) => {
    const { selectedMaterial } = useSelector((reducer) => reducer.materialSlice);

    const [productionStatus, setProductionStatus] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isOpenDetail, setOpenDetail] = useState(false);
    const [selectedDataProductStatus, setSelectedDataProductStatus] = useState({});

    useEffect(() => {
        if (selectedMaterial?.id) {
            fetchProductStatus({
                setProductionStatus,
                setIsLoading,
                filter: { sediaan_id: selectedMaterial?.id, id: selectedFilterStreamline, q: searchStreamline },
            });
        }
    }, [selectedMaterial, selectedFilterStreamline, searchStreamline]);

    const refetchProgessStatus = () => {
        fetchProductStatus({
            setProductionStatus,
            setIsLoading,
            filter: { sediaan_id: selectedMaterial?.id, id: selectedFilterStreamline, q: searchStreamline },
        });
    };

    const onSelectedDataProductStatus = ({ value }) => {
        setSelectedDataProductStatus(value);
    };

    const onSetOpenDetail = () => {
        setOpenDetail(!isOpenDetail);
        setSelectedDataProductStatus({});
    };

    useEffect(() => {
        const interval = setInterval(() => window.location.reload(), 5 * 60 * 1000);
        return () => clearInterval(interval);
    }, []);


    return (
        <SectionProductionStatusContext.Provider
            value={{
                onSelectedDataProductStatus,
                setOpenDetail,
            }}
        >
            <Box className="section-production-status__container__main-dashboard">
                {!isLoading && (
                    <>
                        <TableHead tableList={tableList} />
                        <Box className="columns-container">
                            {productionStatus?.map((item, idx) => (
                                <TableColumn
                                    key={idx}
                                    tableList={tableList}
                                    obj={item}
                                    idx={idx}
                                    streamlineColor={getStreamlineColor({ name: item })}
                                />
                            ))}
                        </Box>
                    </>
                )}
                {isLoading && <ProductionStatusSkeleton />}
                {!isLoading && productionStatus.length === 0 && (
                    <EmptyState
                        text="Tidak ada data yg di tampilkan"
                        description="Maaf, tidak ada data yang bisa ditamplikan"
                    />
                )}
            </Box>

            {/* DETAIL PRODUCT STATUS */}
            <DetailProductStatus
                isOpenDetail={isOpenDetail}
                selectedDataProductStatus={selectedDataProductStatus}
                onClose={onSetOpenDetail}
                refetchProgessStatus={refetchProgessStatus}
            />
        </SectionProductionStatusContext.Provider>
    );
};

export default SectionProductionStatus;
