import {
    Box,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuList,
    Spinner,
    Text,
    Tooltip,
    useToast,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { debounce } from 'lodash';
import { isEmptyString } from '@helpers/functions';
import { fetchDataInfiniteTradeName, handleChangeKonfigurasiSatuan, handleFocus } from '../function';
import { FormCreateNewItemContext } from '..';

function TradeName({
    itemForm,
    openInfiniteScroll,
    loadingSearch,
    setLoadingSearch,
    setOpenInfiniteScroll,
    setFormData,
    formData,
    isSubmitted,
}) {
    const { searchTradeName, setSearchTradeName, dataMaterialRaci, isLoadingMaterialRaci } =
        useContext(FormCreateNewItemContext);
    const [dataTradeName, setDataTradeName] = React.useState([]);
    const [trigger, setTrigger] = React.useState(false);
    const [totalData, setTotalData] = React.useState(1);
    const [loadingUserTyping, setLoadingUserTyping] = React.useState(false);
    const [debounceValueTradeName, setDebounceValueTradeName] = React.useState('');
    const toast = useToast();
    const getMoreDataTradeName = ({ hasMore, loadingOnUserTyping }) => {
        if (!Boolean(formData?.dataLengkap?.supplier_code)) return;
        fetchDataInfiniteTradeName({
            setDataTradeName,
            dataTradeName,
            setTotalData,
            moreData: Boolean(hasMore),
            searchTradeName: debounceValueTradeName,
            setLoadingSearch,
            api: openInfiniteScroll.api,
            toast,
            loadingOnUserTyping,
            setLoadingUserTyping,
        });
    };
    const handleChangeDebounceTradeName = (text) => {
        setTotalData(1);
        setDebounceValueTradeName(text.target.value);
        const isEmptyField = !Boolean(text.target.value);
        setFormData((prev) => {
            return {
                ...prev,
                dataLengkap: {
                    ...prev?.dataLengkap,
                    trade_name: text.target.value,
                    code_item: isEmptyField ? '' : prev?.dataLengkap?.code_item,
                    internal_name: isEmptyField ? '' : prev?.dataLengkap?.internal_name,
                    jenis_material: isEmptyField ? '' : prev?.dataLengkap?.jenis_material,
                },
            };
        });
    };
    const debounceSearchTradeName = React.useMemo(() => debounce(handleChangeDebounceTradeName, 500), []);
    const handleClickTradeName = (item, itemForm) => {
        setSearchTradeName(item?.name);
        setFormData((prev) => ({
            ...prev,
            dataLengkap: {
                ...prev?.dataLengkap,
                trade_name: item?.name,
                code_item: item?.korporate_code,
                internal_name: item?.internal_name,
                jenis_material: item?.category_code,
            },
        }));
        setOpenInfiniteScroll((prev) => ({ ...prev, [itemForm.name]: false }));
    };
    React.useEffect(() => {
        if (openInfiniteScroll.trade_name) {
            getMoreDataTradeName({ hasMore: false, loadingOnUserTyping: true });
        }
    }, [debounceValueTradeName, trigger]);
    React.useEffect(() => {
        handleChangeKonfigurasiSatuan({ setFormData, dataMaterialRaci, toast, formData });
    }, [isLoadingMaterialRaci, formData?.dataLengkap?.trade_name]);
    return (
        <Box>
            <Text className="label">
                {itemForm.title}
                {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
            </Text>
            <Box className="infinite-scroll-container" position="relative">
                <InputGroup>
                    <Tooltip
                        label={
                            Boolean(itemForm.name?.includes('trade_name')) &&
                            `${
                                !Boolean(formData?.dataLengkap?.supplier_code)
                                    ? 'pilih nama supplier terlebih dahulu'
                                    : ''
                            }`
                        }
                        aria-label="A tooltip"
                    >
                        <Input
                            placeholder="pilih trade name"
                            readOnly={!Boolean(formData?.dataLengkap?.supplier_code)}
                            className="enabled-input-supplier-name"
                            onFocus={() => {
                                setTrigger(!trigger);
                                setDebounceValueTradeName(searchTradeName || '');
                                handleFocus({
                                    debounceValueTradeName,
                                    itemForm,
                                    setOpenInfiniteScroll,
                                    supplierRef: formData?.dataLengkap?.supplier_code,
                                });
                            }}
                            value={searchTradeName || ''}
                            height="48px"
                            onChange={(text) => {
                                ///https://stackoverflow.com/a/69584798/18038473
                                setSearchTradeName((prev) => {
                                    return text.target.value;
                                });
                                debounceSearchTradeName(text);
                            }}
                        />
                    </Tooltip>
                    <InputRightElement height="48px" right="12px">
                        <Box className="divider_input_infinite_scroll" />
                        {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                    </InputRightElement>
                </InputGroup>
                <Menu
                    isOpen={openInfiniteScroll[itemForm.name]}
                    onClose={() => setOpenInfiniteScroll((prev) => ({ ...prev, [itemForm.name]: false }))}
                >
                    <MenuList mt="50px" zIndex={20}>
                        {/* https://stackoverflow.com/a/73260330 */}
                        <div id="scrollableDiv" className="scroll-box-container">
                            <InfiniteScroll
                                height={dataTradeName.length > 1 ? '310px' : 'auto'}
                                dataLength={dataTradeName.length}
                                next={() => getMoreDataTradeName({ hasMore: true, loadingOnUserTyping: false })}
                                hasMore={dataTradeName.length === totalData ? false : true}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    dataTradeName.length < 9 ? null : (
                                        <Text className="loading_fetch_next_page">Loading...</Text>
                                    )
                                }
                                endMessage={null}
                            >
                                {dataTradeName.length === 0 && !loadingSearch && (
                                    <Text className="no_data_infinite_scroll">
                                        Tidak Bisa Menemukan "{debounceValueTradeName}"
                                    </Text>
                                )}
                                {loadingUserTyping ? (
                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                ) : (
                                    dataTradeName?.map((item, index) => {
                                        return (
                                            <Box
                                                onClick={() => handleClickTradeName(item, itemForm)}
                                                className="list_item_infinite"
                                                key={index}
                                            >
                                                {item?.name}
                                            </Box>
                                        );
                                    })
                                )}
                            </InfiniteScroll>
                        </div>
                    </MenuList>
                </Menu>
                {isSubmitted && isEmptyString(formData?.dataLengkap?.trade_name) && (
                    <Text className="required-field__text">Field required!</Text>
                )}
            </Box>
        </Box>
    );
}

export default TradeName;
