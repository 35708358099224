import { DownloadBlackIcon } from '@asset/icons';

export const optionMenuDot = [
    {
        name: 'Download Data',
        section: 'download',
        icon: <DownloadBlackIcon />,
    },
];

export const title = [
    {
        name: 'main',
        title: 'Order Management',
    },
    {
        name: 'order_delivery',
        title: 'Order Delivery',
    },
    {
        name: 'order_confirmation',
        title: 'Order Confirmation',
    },
];
