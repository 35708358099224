import React from 'react';
import { Box, Button, Text, useToast } from '@chakra-ui/react';
import InfiniteSupplier from '../../components/infiniteSupplier';
import InfiniteDataSelectKodeItem from '../../components/infiniteDataSelectKodeItem';
import { isEmptyString } from '@helpers/functions';
import { handleGenerate } from './function';

import './styles.scss';

function Index({ formData, setFormData, setShowBottomForm, IsLoadingEdit }) {
    const [searchData, setSearchData] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const { kode_item, supplier_name } = formData || {};
    const [searchKodeItem, setSearchKodeItem] = React.useState('');
    const toast = useToast();
    const handleBlur = (i) => {
        if (isEmptyString(i?.target?.value)) {
            setShowBottomForm(false);
            return;
        }
    };
    return (
        <Box className="form-complete-data-container">
            <Box className="complete-data-container">
                <Box className="left-side">
                    <Text className="title">Isi data lengkap</Text>
                    <Text className="description">
                        Isi data dengan lengkap dan benar, sesuai dengan ketentuan yang ada
                    </Text>
                </Box>
                <Box className="right-side">
                    <Box>
                        <Text className="label-input-item-code">
                            Kode Item<span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                        </Text>
                        <InfiniteDataSelectKodeItem
                            handleBlur={handleBlur}
                            placeholderInput="masukan kode item"
                            setFormData={setFormData}
                            formData={formData}
                            initialAttribute="kode_item"
                            api="/material/filter"
                            dataResponse="list"
                            apiParams={{
                                attributes: 'kode_item',
                                sort: 'kode_item asc',
                                q: searchKodeItem,
                            }}
                            searchData={searchKodeItem}
                            setSearchData={setSearchKodeItem}
                            IsLoadingEdit={IsLoadingEdit}
                        />
                    </Box>
                    <Box mt="40px">
                        <Text mb="6px" className="label-input-item-code">
                            Nama Supplier
                            <span style={{ color: '#DC0000', marginLeft: '2px' }}>*</span>
                        </Text>
                        <InfiniteSupplier
                            handleBlur={handleBlur}
                            placeholderInput="masukan nama supplier"
                            setFormData={setFormData}
                            formData={formData}
                            keyFormData="supplier_name"
                            ///
                            /// NAMA INITIALVALUE DI FORMDATA
                            initialAttribute="supplier_name"
                            ///
                            /// ENPOIN
                            api="/supplier/list"
                            ///
                            /// MENGAMBIL RESPON DATA DARI BE, CONTOH : respon dari be = data.data.supplier,
                            /// maka ATTRIBUTE dataResponse={suppliers}
                            dataResponse="suppliers"
                            ///
                            /// PARAMETER ENDPOIN, CONTOH : apiParams={{ source: 'INTERNAL', name: 'someone' }}
                            apiParams={{ source: 'INTERNAL', q: searchData, kodeItem: formData?.kode_item }}
                            keyInitialName="supplier_name"
                            keyResponse="name"
                            widthDropDownContainer="706px"
                            searchData={searchData}
                            setSearchData={setSearchData}
                            IsLoadingEdit={IsLoadingEdit}
                        />
                    </Box>
                    <Button
                        isLoading={isLoading}
                        _hover="none"
                        isDisabled={isEmptyString(formData?.kode_item) || isEmptyString(formData?.supplier_name)}
                        onClick={() =>
                            handleGenerate({ setShowBottomForm, kode_item, supplier_name, toast, setIsLoading })
                        }
                        className="btn-generate"
                    >
                        Generate
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}

export default Index;
