import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Box, Text, Flex, Spinner } from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import { useRef, useState } from 'react';

import './styles.scss';

function Index({ isOpen, onClose, setOpenModalEditNote, handleSave, outlineLabel, modalTitle, note, isLoading }) {
    const [edit, setEdit] = useState(true);
    const ref = useRef();
    return (
        <Modal
            isOpen={isOpen}
            onClose={() => onClose({ blur: () => ref.current?.blur(), setEdit: () => setEdit(true) })}
        >
            <Box className="modal-container-edit-note">
                <ModalOverlay />
                <ModalContent maxW="544px">
                    <Flex className="modal-edit-note-header">
                        <Text className="modal-header-text">{modalTitle}</Text>
                        <Box
                            onClick={() => {
                                onClose({ blur: () => ref.current?.blur(), setEdit: () => setEdit(true) });
                                setEdit(true);
                                ref.current?.blur();
                            }}
                            cursor="pointer"
                        >
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Box className="container-edit-note">
                            <textarea
                                onChange={(event) =>
                                    setOpenModalEditNote((prev) => ({ ...prev, note: event?.target?.value }))
                                }
                                disabled={edit}
                                ref={ref}
                                value={note || ''}
                                className="input-edit"
                            />
                        </Box>
                    </ModalBody>

                    <ModalFooter className="modal-footer">
                        <Box
                            className="btn-modal-close"
                            onClick={() => {
                                setEdit(false);
                                /// https://stackoverflow.com/a/58830782
                                setTimeout(() => {
                                    ref.current?.focus();
                                }, 5);
                            }}
                        >
                            <Text className="text-modal-close">{outlineLabel}</Text>
                        </Box>
                        <Box
                            className={isLoading ? 'btn-apply-edit-note-disabled' : `btn-apply-edit-note`}
                            onClick={() => {
                                if (isLoading) return;
                                handleSave();
                            }}
                        >
                            {isLoading ? (
                                <Spinner size="sm" color="#ffffff" />
                            ) : (
                                <Text className="text-apply-edit-note">save Catatan</Text>
                            )}
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;
