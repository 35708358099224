import { Box, Flex } from '@chakra-ui/react';

import { SearchIcon } from '@pages/orderPlanning/icons';
import { onChangeSearchInput } from '@store/list/assetManagement';
import { AddIcon } from '@pages/assetManagement/icons';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { isAllowed } from '@helpers/roleAccess';


const SectionHead = ({ onChangeModalState, onSearchHandler }) => {
    const { searchQuery } = useSelector((slices) => slices.assetManagement);
    const isCanAcces = isAllowed({
        pageName: 'Daftar Zona dan Line Produksi',
        permissionName: 'Tambah',
    });
    const dispatch = useDispatch();
    return (
        <Flex className="section-head__container__asset-management">
            <Flex>
                <Flex className="section-search__container__asset-management" mr="16px">
                    <input
                        className="search-input"
                        placeholder="Cari ......"
                        onChange={(e) => dispatch(onChangeSearchInput(e.target.value))}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                onSearchHandler({ value: searchQuery });
                            }
                        }}
                        value={searchQuery}
                    />
                    <Box className="search-icon__container" onClick={() => onSearchHandler({ value: searchQuery })}>
                        <SearchIcon />
                    </Box>
                </Flex>
            </Flex>
            {isCanAcces && (
                <Box className="button-add-row" onClick={() => onChangeModalState({ value: 'modal-create' })}>
                    Add New Asset
                    <AddIcon />
                </Box>
            )}
        </Flex>
    );
};

export default SectionHead;
