import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';

export const getNamaItem = async ({ toast, kode_item, setFormData }) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify({ kode_item }, { skipEmptyString: true });
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/material/config/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        const trade_name = data.data?.listMaterial[0]?.trade_name || '';
        setFormData((prev) => ({ ...prev, kode_item, nama_item: trade_name, no_po: '', qty_ospo: '' }));
    } catch (error) {
        console.log(error);
        toast({
            title: 'Error',
            description: 'Something wrong',
            status: 'error',
            duration: 7000,
            isClosable: true,
            position: 'top-center',
        });
    }
};
