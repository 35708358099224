import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import { isAllowed } from '@helpers/roleAccess';
import EditableColumn from './components/editableColumn';
import EditableNotes from './components/editableNotes';
import {
    getColorHandler,
    getColorTextHandler,
    getCurrMap,
    getCurrtLengthDates,
    getValueTooltip,
    onFormatUpdateDate,
} from './function';
import { EditDateIcon } from './icons';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableList = [
    {
        name: 'Status',
        value: 'stockSupplier',
        sortValue: 'status',
        label: 'sort by status',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box className="box-column-status" background={getColorHandler({ status: value?.status })}>
                <Text className="text-colum-status" color={getColorTextHandler({ status: value?.status })}>
                    {value?.status}
                </Text>
            </Box>
        ),
    },
    {
        name: 'Kode Item',
        value: 'kode_item',
        sortValue: 'kode_item',
        label: 'sort by kode item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text text-ellipsis">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Nama Item',
        value: 'trade_name',
        sortValue: 'trade_name',
        label: 'sort by nama item',
        width: 300,
        children: ['-'],
        component: ({ value }) => (
            <Tooltip
                label={value}
                fontStyle={'normal'}
                fontWeight={'400'}
                fontSize={'12px'}
                lineHeight={'18px'}
                textAlign={'left'}
                color="white"
            >
                <Text className="column-text text-ellipsis">{value || '-'}</Text>
            </Tooltip>
        ),
    },
    {
        name: 'Supplier',
        value: 'supplier',
        sortValue: 'supplier_name',
        label: 'sort by supplier',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text text-ellipsis">{value?.name || '-'}</Text>,
    },
    {
        name: 'UOM',
        value: 'uom',
        width: 50,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value || '-'}</Text>,
    },
    {
        name: 'ROP Supplier',
        value: 'stock_supp_rop',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value) || '-'}</Text>,
    },
    {
        name: 'MAX Stock Supplier',
        value: 'stock_supp_max',
        width: 90,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value) || '-'}</Text>,
    },
    {
        name: 'MPQ Supplier',
        value: 'materialCriticalPoint',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value?.mpq_supplier) || '-'}</Text>,
    },
    {
        name: 'Production Target',
        value: 'stockSupplier',
        width: 130,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value?.production_target)}</Text>,
    },
    {
        name: 'SOH Supplier',
        value: 'stockSupplier',
        width: 100,
        children: ['pcs'],
        component: ({ value }) => (
            <EditableColumn keyAttribute="soh_supplier" currentValue={value?.soh_supplier || '-'} id={value?.id} />
        ),
    },
    {
        name: 'On Process',
        value: 'stockSupplier',
        width: 40,
        children: ['pcs'],
        component: ({ value }) => (
            <EditableColumn keyAttribute="on_process" currentValue={value?.on_process || '-'} id={value?.id} />
        ),
    },
    {
        name: 'Manufacturing Date',
        value: 'stockSupplier',
        width: 80,
        flex: 1,
        children: ['-'],
        component: ({ value, dataItem, onChangeModalState, onSelectedValue }) => (
            <Flex w="300px" justifyContent="space-between" alignItems="center" position="relative">
                <Box display="flex">
                    {value?.stockManufDates?.length >= 1 ? (
                        getCurrMap({ value: value?.stockManufDates }).map((_, index) => (
                            <Tooltip
                                className="text-tooltip-label"
                                label={value?.stockManufDates[index]?.manufacturing_date}
                                key={index}
                            >
                                <Text className="column-text" mr={'5px'} key={index}>
                                    {value?.stockManufDates[index]?.manufacturing_date}
                                </Text>
                            </Tooltip>
                        ))
                    ) : (
                        <Text>{'-'}</Text>
                    )}
                </Box>
                <Tooltip className="text-tooltip-label" label={getValueTooltip({ value: value?.stockManufDates })}>
                    <Box
                        bg={'#EBEBEB'}
                        position="absolute"
                        top="2px"
                        right="38px"
                        borderRadius={'50%'}
                        w={'16px'}
                        h={'16px'}
                        fontSize={'8px'}
                        lineHeight={'12px'}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        color={'#B1B1B1'}
                        mr={'12px'}
                    >
                        {`+${getCurrtLengthDates({ value: value?.stockManufDates })}`}
                    </Box>
                </Tooltip>
                {Boolean(value?.id) && (
                    <>
                        {isAllowed({ pageName: "Supplier's Stock", permissionName: "Edit Supplier's Stock" }) && (
                            <Box
                                cursor="pointer"
                                onClick={() => {
                                    onSelectedValue({ value: dataItem });
                                    onChangeModalState({ value: true });
                                }}
                            >
                                <EditDateIcon />
                            </Box>
                        )}
                    </>
                )}
            </Flex>
        ),
    },
    {
        name: 'Notes',
        value: 'stockSupplier',
        width: 210,
        children: ['-'],
        component: ({ value }) => <EditableNotes dataItem={value} />,
    },
    {
        name: 'Last Update',
        value: 'stockSupplier',
        width: 120,
        children: ['-'],
        component: ({ value, dataItem }) => <Text className="column-text">{onFormatUpdateDate(value?.updatedAt)}</Text>,
    },
];

export const formDefaultValues = {
    note: 'Sedang Produksi',
    process: '500.000',
    supplier: '500.000',
};

export const initialValueFilter = {
    status: '',
};
