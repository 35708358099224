import { Box, Input, InputGroup, InputRightElement, Menu, MenuList, Spinner, Text, useToast } from '@chakra-ui/react';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { SearchIcon } from '@pages/userManagement/sections/sectionHead/nameFilter/icons';
import { debounce } from 'lodash';
import { isEmptyString } from '@helpers/functions';
import { fetchDataInfiniteSupplierName, fetchDataInfiniteTradeName, handleFocus } from '../function';
import { FormCreateNewItemContext } from '..';

function SupplierName({
    itemForm,
    openInfiniteScroll,
    formData,
    setOpenInfiniteScroll,
    setLoadingTradeName,
    setFormData,
    isSubmitted,
}) {
    const { searchSupplierName, setSearchSupplierName, setSearchTradeName } =
        React.useContext(FormCreateNewItemContext);
    const [dataSupplierName, setDataSupplierName] = React.useState([]);
    const [totalData, setTotalData] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const toast = useToast();
    const [loadingSearch, setLoadingSearch] = React.useState(false);
    const [loadingUserTyping, setLoadingUserTyping] = React.useState(false);
    const [debounceValueSupplierName, setDebounceValueSupplierName] = React.useState('');
    const getMoreDataSupplierName = ({ hasMore, loadingOnUserTyping }) => {
        fetchDataInfiniteSupplierName({
            page,
            setDataSupplierName,
            dataSupplierName,
            setPage,
            setTotalData,
            moreData: Boolean(hasMore),
            searchSupplierName: debounceValueSupplierName,
            setLoadingSearch,
            api: openInfiniteScroll.api,
            toast,
            loadingOnUserTyping,
            setLoadingUserTyping,
        });
    };

    const handleChangeDebounceSupplierName = (text) => {
        setTotalData(1);
        setDebounceValueSupplierName(text.target.value);
        const isEmptyField = !Boolean(text.target.value);
        setFormData((prev) => {
            return {
                ...prev,
                dataLengkap: {
                    ...prev?.dataLengkap,
                    supplier_name: text.target.value,
                    supplier_code: isEmptyField ? '' : prev?.dataLengkap?.supplier_code,
                    trade_name: isEmptyField ? '' : prev?.dataLengkap?.trade_name,
                    code_item: isEmptyField ? '' : prev?.dataLengkap?.code_item,
                    internal_name: isEmptyField ? '' : prev?.dataLengkap?.internal_name,
                    jenis_material: isEmptyField ? '' : prev?.dataLengkap?.jenis_material,
                },
            };
        });
        setSearchTradeName('');
        setPage((prev) => {
            return 1;
        });
    };
    const debounceSearchSupplierName = React.useMemo(() => debounce(handleChangeDebounceSupplierName, 500), []);
    const handleClickSupplierName = (item, itemForm) => {
        setSearchSupplierName(item?.name);
        setFormData((prev) => ({
            ...prev,
            dataLengkap: {
                ...prev?.dataLengkap,
                supplier_name: item?.name,
                supplier_code: item?.supplier_ref,
                trade_name: '',
                code_item: '',
                internal_name: '',
                jenis_material: '',
            },
        }));
        setSearchTradeName('');
        setOpenInfiniteScroll((prev) => ({ ...prev, [itemForm.name]: false, supplierRef: item?.supplier_ref }));
        fetchDataInfiniteTradeName({
            api: `/material/list?supplierRef=${item?.supplier_ref}&`,
            isPureFetch: true,
            toast,
            setLoadingSearch: setLoadingTradeName,
        });
    };

    React.useEffect(() => {
        if (openInfiniteScroll.supplier_name) {
            getMoreDataSupplierName({ hasMore: false, loadingOnUserTyping: true });
        }
    }, [debounceValueSupplierName, openInfiniteScroll.api]);

    return (
        <Box>
            <Text className="label">
                {itemForm.title}
                {itemForm.isRequired && <span style={{ color: 'red' }}>*</span>}
            </Text>
            <Box className="infinite-scroll-container" position="relative">
                <InputGroup>
                    <Input
                        placeholder="masukan nama supplier"
                        className="enabled-input-supplier-name"
                        onFocus={() => {
                            setDebounceValueSupplierName(searchSupplierName || '');
                            setPage(1);
                            handleFocus({
                                itemForm,
                                setOpenInfiniteScroll,
                                supplierRef: openInfiniteScroll.supplierRef,
                                setPage,
                            });
                        }}
                        value={searchSupplierName || ''}
                        height="48px"
                        onChange={(text) => {
                            ///https://stackoverflow.com/a/69584798/18038473
                            setSearchSupplierName((prev) => {
                                return text.target.value;
                            });
                            debounceSearchSupplierName(text);
                        }}
                    />
                    <InputRightElement height="48px" right="12px">
                        <Box className="divider_input_infinite_scroll" />
                        {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                    </InputRightElement>
                </InputGroup>
                <Menu
                    isOpen={openInfiniteScroll[itemForm.name]}
                    onClose={() => setOpenInfiniteScroll((prev) => ({ ...prev, [itemForm.name]: false }))}
                >
                    <MenuList mt="50px" zIndex={20}>
                        {/* https://stackoverflow.com/a/73260330 */}
                        <div id="scrollableDiv">
                            <InfiniteScroll
                                className="scroll-box-container"
                                height={dataSupplierName.length > 1 ? '310px' : 'auto'}
                                dataLength={dataSupplierName.length}
                                next={() => getMoreDataSupplierName({ hasMore: true, loadingOnUserTyping: false })}
                                hasMore={dataSupplierName.length === totalData ? false : true}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    dataSupplierName.length < 9 ? null : (
                                        <Text className="loading_fetch_next_page">Loading...</Text>
                                    )
                                }
                                endMessage={null}
                            >
                                {dataSupplierName.length === 0 && !loadingSearch && (
                                    <Text className="no_data_infinite_scroll">
                                        Tidak Bisa Menemukan "{debounceValueSupplierName}"
                                    </Text>
                                )}
                                {loadingUserTyping ? (
                                    <Text className="loading_fetch_next_page">Loading...</Text>
                                ) : (
                                    dataSupplierName?.map((item, index) => {
                                        return (
                                            <Box
                                                onClick={() => handleClickSupplierName(item, itemForm)}
                                                className="list_item_infinite"
                                                key={index}
                                            >
                                                {item?.name}
                                            </Box>
                                        );
                                    })
                                )}
                            </InfiniteScroll>
                        </div>
                    </MenuList>
                </Menu>
                {isSubmitted && isEmptyString(formData?.dataLengkap?.supplier_name) && (
                    <Text className="required-field__text">Field required!</Text>
                )}
            </Box>
        </Box>
    );
}

export default SupplierName;
