import { Box, Text, Flex } from '@chakra-ui/react';
import { getColorHandler, getColorText, getStatus } from './function';
import { round } from 'lodash';

export const tableList = [
    {
        name: 'Status',
        value: 'status',
        width: 164,
        // sortValue: 'kardOrders,status',
        component: ({ value, bg }) => (
            <Flex flexDirection="column" bg={bg}>
                <Box
                    className="column-status"
                    background={getColorHandler({ status: value })}
                    color={getColorText({ status: value })}
                >
                    {getStatus({ status: value })}
                </Box>
            </Flex>
        ),
    },
    {
        name: 'STREAMLINE',
        value: 'streamline',
        sortValue: 'streamline,name',
        width: 120,
        component: ({ value }) => <Flex>{value && <Text className="column-text">{value}</Text>}</Flex>,
    },
    {
        name: 'NAMA ITEM',
        value: 'nama_item',
        sortValue: 'nama_item',
        width: 330,
        component: ({ value, bg }) => <Flex>{value && <Text className="column-text">{value}</Text>}</Flex>,
    },
    {
        name: 'KODE ITEM',
        width: 177,
        value: 'kode_item',
        sortValue: 'kode_item',
        component: ({ value }) => <Box>{value && <Text className="column-text">{value}</Text>}</Box>,
    },
    {
        name: 'KODE ODOO',
        width: 120,
        value: 'odoo_code',
        sortValue: 'odoo_code',
        component: ({ value }) => <Box>{value && <Text className="column-oddo">{value}</Text>}</Box>,
    },

    {
        name: 'JENIS STORAGE',
        width: 140,
        value: 'jenis_storage_ndc',
        withBorderRight: true,
        component: ({ value }) => <Box>{value && <Text className="column-text">{value}</Text>}</Box>,
    },
    {
        name: 'STOCK NATIONAL',
        width: 184,
        value: 'stock_nasional',
        children: ['Standar', 'Total'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value &&
                    ['standar_stock_nasional', 'stock_nasional'].map((item, index) => (
                        <Text key={index} className="column-text__child">
                            {Math.round(value[item]) || Math.round(value[item]) === 0
                                ? Math.round(value[item])?.toLocaleString()
                                : '-'}
                        </Text>
                    ))}
            </Flex>
        ),
    },
    {
        name: 'STOCK NDC',
        value: 'stock_ndc',
        withBorderRight: true,
        width: 200,
        children: ['Qty Storage', 'Pcs'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value &&
                    ['stock_ndc_palet', 'stock_ndc'].map((item, index) => {
                        return (
                            <Text key={index} className="column-text__child">
                                {value[item] || value[item] === 0 ? round(value[item], 1)?.toLocaleString() : '-'}
                            </Text>
                        );
                    })}
            </Flex>
        ),
    },
    {
        name: 'SAFETY',
        value: 'safety',
        width: 180,
        children: ['Qty Storage', 'Pcs'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value && (
                    <Box className="column__item-child">
                        {['qty_storage', 'pcs'].map((item, index) => {
                            return (
                                <Text key={index} className="column-text__child">
                                    {value[item] || value[item] === 0 ? round(value[item], 1).toLocaleString() : '-'}
                                </Text>
                            );
                        })}
                    </Box>
                )}
            </Flex>
        ),
    },

    {
        name: 'ROP',
        value: 'rop',
        width: 180,
        children: ['Qty Storage', 'Pcs'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value && (
                    <Box className="column__item-child">
                        {['qty_storage', 'pcs'].map((item, index) => {
                            return (
                                <Text key={index} className="column-text__child">
                                    {value[item] || value[item] === 0 ? round(value[item], 1).toLocaleString() : '-'}
                                </Text>
                            );
                        })}
                    </Box>
                )}
            </Flex>
        ),
    },

    {
        name: 'MAX',
        value: 'maximum',
        width: 180,
        children: ['Qty Storage', 'Pcs'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                {value && (
                    <Box className="column__item-child">
                        {['qty_storage', 'pcs'].map((item, index) => {
                            return (
                                <Text key={index} className="column-text__child">
                                    {value[item] || value[item] === 0 ? round(value[item], 1)?.toLocaleString() : '-'}
                                </Text>
                            );
                        })}
                    </Box>
                )}
            </Flex>
        ),
    },
    {
        name: 'UMUR STOCK',
        width: 124,
        withBorderRight: true,
        value: 'umur_stock',
        sortValue: '',
        children: ['Days'],
        component: ({ value, index }) => (
            <Box className="column__item-child">
                {index === 0 && (
                    <Text className="column-text__child">
                        {value || value === 0 ? round(value, 1)?.toLocaleString() : '-'}
                    </Text>
                )}
            </Box>
        ),
    },

    {
        name: 'MPQ',
        withBorderRight: true,
        value: 'mpq_kemas',
        width: 200,
        children: ['Qty Storage', 'Pcs'],
        component: ({ value }) => (
            <Flex className="column__item-child">
                <Flex className="column__item-child">
                    {['storage_ndc', 'pcs'].map((item, index) => (
                        <Text key={index} className="column-text__child">
                            {value && (value[item] || value[item] === 0)
                                ? Math.round(value[item]).toLocaleString()
                                : '-'}
                        </Text>
                    ))}
                </Flex>
            </Flex>
        ),
    },

    {
        name: 'ORDER REQUEST',
        withBorderRight: true,
        value: 'order_request',
        width: 160,
        children: ['Batch'],
        component: ({ value, index }) => (
            <Flex className="column__item-child">
                {index === 0 && (
                    <Text className="column-text__child">{value || value === 0 ? value?.toLocaleString() : '-'}</Text>
                )}
            </Flex>
        ),
    },
    {
        name: 'IN PROGRESS ORDER',
        value: 'batch_inprogress_order',
        children: ['Batch'],
        width: 180,
        component: ({ value, index }) => (
            <Flex className="column__item-child">
                <Flex className="column__item-child">
                    {index === 0 && (
                        <Text className="column-text__child">
                            {value || value === 0 ? value?.toLocaleString() : '-'}
                        </Text>
                    )}
                </Flex>
            </Flex>
        ),
    },
    {
        name: 'IN PROGRESS PRODUCTION',
        value: 'batch_inprogress_prod',
        children: ['Batch'],
        width: 210,
        component: ({ value, index }) => (
            <Flex className="column__item-child">
                <Flex className="column__item-child">
                    {index === 0 && (
                        <Text className="column-text__child">
                            {value || value === 0 ? value?.toLocaleString() : '-'}
                        </Text>
                    )}
                </Flex>
            </Flex>
        ),
    },
    {
        name: 'DONE',
        value: 'done',
        children: ['Batch'],
        width: 105,
        component: ({ value, index }) => (
            <Flex className="column__item-child">
                <Flex className="column__item-child">
                    {index === 0 && (
                        <Text className="column-text__child">
                            {value || value === 0 ? value?.toLocaleString() : '-'}
                        </Text>
                    )}
                </Flex>
            </Flex>
        ),
    },

    {
        name: 'BATCH BOOKING',
        value: 'batch_booking',
        children: ['Batch'],
        width: 160,
        component: ({ value, index }) => (
            <Flex className="column__item-child">
                {index === 0 && (
                    <Text className="column-text__child">{value || value === 0 ? value?.toLocaleString() : '-'}</Text>
                )}
            </Flex>
        ),
    },
];

const setRandomStatus = () => {
    const arr = ['In Proses', 'OK', 'Done', 'Antrian Konfirmasi', 'Antrian Konfirmasi', 'Antrian Pending']; // <---- Status order planning dari mba dinda .
    const randomIndex = Math.round(Math.random() * arr.length);
    return arr[randomIndex];
};

export const obj = {
    status: setRandomStatus(),
    oddoCode: 1987,
    itemCode: 'FW-BSN2-EERM',
    itemName: 'Emina Bright Stuff Face Wash 100 ml',
    storageType: 'PALET',
    plan: 45,
    batchBookingNextWeek: {
        Batch: 1,
    },
    nationalStock: {
        Standar_nasional: '132,998',
        Total: '176,470',
    },
    batchBookingCurrentWeek: {
        Batch: 1,
        Pcs: 8775,
        planning: 2,
        adjust: 4,
        realisasi: 4,
        sisa: 3,
    },
    request: {
        Batch: 2,
        Pcs: 17550,
        Qty_Storage: 2,
    },
    stokNCD: {
        Pcs: '32,340',
        Koli: 1078,
        // storage: 6,
    },
    critical_point: {
        Safety: 18,
        ROP: 22,
        MAX: 28,
    },
    rekap: {
        Done: 0,
        Adjust: 1,
        In_Progress: 0,
        Sisa_Booking: 1,
    },
    mpq: {
        Pcs: 8775,
        Qty_Storage: 6,
    },
};
