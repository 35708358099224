import { Modal, ModalOverlay, ModalContent, Box, Text, CircularProgress, Flex } from '@chakra-ui/react';
import './styles.scss';

const ModalDownload = ({ isOpen, onClose, description }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w={'219px'} position="absolute" top="345px" left="21px">
                <Box className="modal-download-global__container">
                    <Flex alignItems="center">
                        <Box className="box-circular-progress">
                            <CircularProgress size="21px" color="#1746A2" isIndeterminate />
                        </Box>
                        <Text className="text-desc-modal">{description}</Text>
                    </Flex>
                </Box>
            </ModalContent>
        </Modal>
    );
};

export default ModalDownload;
