import { useContext } from 'react';
import { Flex, Box, Button, useToast } from '@chakra-ui/react';
import { tableListOrderDelivery } from '../../const';
import { tableOrderDeliveryContext } from '../..';
import { handleReset } from './function';

import './styles.scss';

const TableColumn = ({ orderData, index, orderDelivery }) => {
    const toast = useToast();
    const { onChangeStateEdit, isEdit, setOrderDelivery, itemEdited, setItemEdited } =
        useContext(tableOrderDeliveryContext);
    const hasEdited = itemEdited?.find((item) => item?.id === orderData?.id);

    return (
        <Flex className="table-column table-column__container__order_delivery">
            {tableListOrderDelivery.map((item, idx) => {
                return (
                    <Box
                        background="white"
                        key={item.name}
                        w={item.width}
                        flex={item.flex}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: orderData[item.value] !== null ? orderData[item.value] : '',
                            orderData,
                            index,
                            orderDelivery,
                            onChangeStateEdit,
                            hasEdited,
                            toast,
                        })}
                    </Box>
                );
            })}
            {isEdit && (
                <Flex className="action-btn">
                    <Button
                        isDisabled={!Boolean(hasEdited)}
                        className="reset-btn"
                        onClick={() =>
                            handleReset({ setOrderDelivery, itemEdited, selectedItem: orderData, setItemEdited })
                        }
                    >
                        Reset
                    </Button>
                </Flex>
            )}
        </Flex>
    );
};

export default TableColumn;
