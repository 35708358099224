export const handleReset = ({ setOrderDelivery, itemEdited, selectedItem, setItemEdited }) => {
    const { id } = selectedItem || {};
    setOrderDelivery((prev) => {
        let clone = [...prev];
        const findPrevValueBefore = itemEdited?.find((item) => item?.id === id);
        const { prev_qty_revisi, prev_notes } = findPrevValueBefore || {};
        for (let index = 0; index < clone.length; index++) {
            if (clone[index]?.id === id) {
                clone[index].notes = prev_notes || '';
                clone[index].qty_revisi = prev_qty_revisi || '';
            }
        }
        return clone;
    });
    setItemEdited((prev) => {
        const deleteItemReset = prev?.filter((item) => item?.id !== id);
        return deleteItemReset;
    });
};
