export const SearchIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
            stroke="#2D2D2D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M21 20.9999L16.65 16.6499"
            stroke="#2D2D2D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const CalendarIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z"
            stroke="#223647"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M16 2V6" stroke="#223647" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 2V6" stroke="#223647" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 10H21" stroke="#223647" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);

export const LeftArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4141 11.9998L14.0001 15.5858C14.3911 15.9768 14.3911 16.6098 14.0001 16.9998C13.6091 17.3908 12.9761 17.3908 12.5861 16.9998L9.00006 13.4138C8.21906 12.6328 8.21906 11.3668 9.00006 10.5858L12.5861 6.99979C12.9761 6.60879 13.6091 6.60879 14.0001 6.99979C14.3911 7.39079 14.3911 8.02379 14.0001 8.41379L10.4141 11.9998Z"
            fill="#6B6B6B"
        />
    </svg>
);

export const RightArrowIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.5859 11.9998L9.99991 15.5858C9.60991 15.9758 9.60991 16.6088 9.99991 16.9998C10.3899 17.3908 11.0239 17.3908 11.4139 16.9998L14.9999 13.4138C15.7809 12.6328 15.7809 11.3668 14.9999 10.5858L11.4149 6.99979C11.0239 6.60879 10.3909 6.60879 9.99991 6.99979C9.60891 7.39079 9.60991 8.02379 9.99991 8.41379L13.5859 11.9998Z"
            fill="#686868"
        />
    </svg>
);

export const DownloadIcon = () => (
    <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.8027 7.95707L7.65625 8.81062V7.60352V1.76758C7.65625 1.67641 7.69247 1.58898 7.75693 1.52451C7.8214 1.46004 7.90883 1.42383 8 1.42383C8.09117 1.42383 8.1786 1.46004 8.24307 1.52451C8.30753 1.58898 8.34375 1.67641 8.34375 1.76758V7.60352V8.81062L9.1973 7.95707L10.709 6.44535C10.7738 6.38061 10.8616 6.34424 10.9531 6.34424C11.0447 6.34424 11.1325 6.38061 11.1972 6.44535C11.262 6.51009 11.2983 6.5979 11.2983 6.68945C11.2983 6.78101 11.262 6.86882 11.1972 6.93356L8.24642 9.88438C8.24611 9.88468 8.2458 9.88499 8.2455 9.88529C8.17994 9.94952 8.09181 9.98551 8 9.98551C7.90819 9.98551 7.82006 9.94952 7.7545 9.88529C7.7542 9.88499 7.75389 9.88468 7.75358 9.88438L4.80277 6.93356C4.73803 6.86882 4.70166 6.78101 4.70166 6.68945C4.70166 6.5979 4.73803 6.51009 4.80277 6.44535C4.86751 6.38061 4.95532 6.34424 5.04688 6.34424C5.13843 6.34424 5.22624 6.38061 5.29098 6.44535L6.8027 7.95707ZM13.8438 13.7988V13.2988V9.64258C13.8438 9.55141 13.88 9.46398 13.9444 9.39951L13.5909 9.04596L13.9444 9.39951C14.0089 9.33504 14.0963 9.29883 14.1875 9.29883C14.2787 9.29883 14.3661 9.33504 14.4306 9.39951L14.7841 9.04596L14.4306 9.39951C14.495 9.46398 14.5312 9.55141 14.5312 9.64258V13.5786C14.5298 13.8189 14.4337 14.0489 14.2638 14.2189C14.0939 14.3888 13.8638 14.4849 13.6236 14.4863H2.37645C2.13618 14.4849 1.90615 14.3888 1.73623 14.2189L1.38349 14.5716L1.73623 14.2189C1.56632 14.049 1.47023 13.8189 1.46875 13.5787V9.64258C1.46875 9.55141 1.50497 9.46398 1.56943 9.39951L1.21588 9.04596L1.56943 9.39951C1.6339 9.33504 1.72133 9.29883 1.8125 9.29883C1.90367 9.29883 1.9911 9.33504 2.05557 9.39951L2.40912 9.04596L2.05557 9.39951C2.12003 9.46398 2.15625 9.55141 2.15625 9.64258V13.2988V13.7988H2.65625H13.3438H13.8438Z"
            fill="none"
            stroke="white"
        />
    </svg>
);

export const EditIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.625 15.75H16.125" stroke="#1746A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M4.125 10.02V12.75H6.86895L14.625 4.99054L11.8857 2.25L4.125 10.02Z"
            stroke="#1746A2"
            strokeWidth="2"
            strokeLinejoin="round"
        />
    </svg>
);

export const RemoveIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.375 3.75V16.5H14.625V3.75H3.375Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
        <path d="M7.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.5 7.5V12.375" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.5 3.75H16.5" stroke="#EF5350" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 3.75L7.23337 1.5H10.7914L12 3.75H6Z" stroke="#EF5350" strokeWidth="2" strokeLinejoin="round" />
    </svg>
);

export const OrderConfirmIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M6 9L8.25 11.25L12 7.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const OrderDeliveryIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 12.375L9.75 13.125C9.75 13.125 15.375 12 16.125 12C16.875 12 16.875 12.75 16.125 13.5C15.375 14.25 12.75 16.5 10.5 16.5C8.25 16.5 6.75 15.375 5.25 15.375H1.5"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.5 10.875C2.25 10.125 3.75 9 5.25 9C6.75 9 10.3125 10.5 10.875 11.25C11.4375 12 9.75 13.125 9.75 13.125M6 6.75V3.75C6 3.55109 6.07902 3.36032 6.21967 3.21967C6.36032 3.07902 6.55109 3 6.75 3H15.75C15.9489 3 16.1397 3.07902 16.2803 3.21967C16.421 3.36032 16.5 3.55109 16.5 3.75V9.75"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.375 3H13.125V6.375H9.375V3Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const WarningIcon = ({ color }) => (
    <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2317_9439)">
            <path
                d="M5 0.78125C4.16561 0.78125 3.34996 1.02868 2.65619 1.49224C1.96242 1.9558 1.42169 2.61468 1.10239 3.38555C0.783078 4.15643 0.699533 5.00468 0.862314 5.82304C1.0251 6.64139 1.42689 7.3931 2.0169 7.98311C2.6069 8.57311 3.35861 8.97491 4.17696 9.13769C4.99532 9.30047 5.84357 9.21692 6.61445 8.89762C7.38532 8.57831 8.0442 8.03758 8.50776 7.34381C8.97133 6.65004 9.21875 5.83439 9.21875 5C9.21772 3.88144 8.77291 2.80898 7.98197 2.01804C7.19102 1.22709 6.11857 0.782284 5 0.78125ZM5 8.28125C4.35103 8.28125 3.71664 8.08881 3.17704 7.72826C2.63744 7.36771 2.21687 6.85525 1.96852 6.25568C1.72017 5.65611 1.65519 4.99636 1.7818 4.35986C1.90841 3.72336 2.22092 3.1387 2.67981 2.67981C3.1387 2.22091 3.72336 1.90841 4.35986 1.7818C4.99636 1.65519 5.65611 1.72017 6.25568 1.96852C6.85525 2.21687 7.36771 2.63744 7.72826 3.17704C8.08881 3.71663 8.28125 4.35103 8.28125 5C8.28022 5.86992 7.93418 6.70392 7.31905 7.31905C6.70392 7.93418 5.86993 8.28022 5 8.28125ZM4.53125 5.15625V3.125C4.53125 3.00068 4.58064 2.88145 4.66855 2.79354C4.75645 2.70564 4.87568 2.65625 5 2.65625C5.12432 2.65625 5.24355 2.70564 5.33146 2.79354C5.41937 2.88145 5.46875 3.00068 5.46875 3.125V5.15625C5.46875 5.28057 5.41937 5.3998 5.33146 5.48771C5.24355 5.57561 5.12432 5.625 5 5.625C4.87568 5.625 4.75645 5.57561 4.66855 5.48771C4.58064 5.3998 4.53125 5.28057 4.53125 5.15625ZM5.625 6.71875C5.625 6.84236 5.58835 6.9632 5.51967 7.06598C5.45099 7.16876 5.35338 7.24887 5.23918 7.29618C5.12498 7.34348 4.99931 7.35586 4.87807 7.33174C4.75683 7.30763 4.64547 7.2481 4.55806 7.16069C4.47065 7.07328 4.41113 6.96192 4.38701 6.84068C4.3629 6.71944 4.37527 6.59378 4.42258 6.47957C4.46988 6.36537 4.54999 6.26776 4.65277 6.19908C4.75555 6.13041 4.87639 6.09375 5 6.09375C5.16576 6.09375 5.32473 6.1596 5.44194 6.27681C5.55915 6.39402 5.625 6.55299 5.625 6.71875Z"
                fill={color}
            />
        </g>
        <defs>
            <clipPath id="clip0_2317_9439">
                <rect width="40" height="40" fill={color} />
            </clipPath>
        </defs>
    </svg>
);

export const SortHeaderIcon = () => (
    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 7H0L3 10V0H2V7ZM5 1.5V10H6V3H8L5 0V1.5Z" fill="black" />
    </svg>
);
