import { Box, Checkbox, Flex, Text, Tooltip } from '@chakra-ui/react';
import { isAllowed } from '@helpers/roleAccess';
import { getColorHandler } from './function';
import { Attention } from './icons';
import TextStatus from './components/textStatus';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

// cek function formatTableMC
export const tableList = [
    {
        name: '!',
        value: 'is_warning',
        width: 10,
        children: ['-'],
        component: ({ value, onClickWarning, material }) => (
            <Flex
                onClick={() => {
                    if (!Boolean(material?.is_draft) && !Boolean(material?.is_warning)) return;
                    onClickWarning({ value: material });
                }}
                cursor={`${material?.is_warning ? 'pointer' : 'default'}`}
                flexDirection="column"
            >
                {(isAllowed({ pageName: 'Material Config', permissionName: 'Edit Material Config' }) ||
                    material?.is_draft) && (
                    <Attention
                        color={getColorHandler({
                            isDraft: material?.is_draft,
                            isWarn: material?.is_warning,
                        })}
                    />
                )}
            </Flex>
        ),
    },
    {
        name: 'kode item',
        value: 'kode_item',
        sortValue: 'kode_item',
        label: 'sort by kode item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text">{value}</Text>
            </Box>
        ),
    },
    {
        name: 'trade name',
        width: 288,
        value: 'trade_name',
        sortValue: 'trade_name',
        label: 'sort by trade name',
        children: ['-'],
        component: ({ value }) => (
            <Box width={'270px'}>
                <Tooltip
                    label={value}
                    fontStyle={'normal'}
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'left'}
                    color="white"
                >
                    <Text className="column-text-ellipsis">{value ? value : '-'}</Text>
                </Tooltip>
            </Box>
        ),
    },
    {
        name: 'internal name',
        value: 'internal_name',
        width: 247,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'jenis material',
        width: 150,
        value: 'supplier',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value?.jenisMaterial?.name}</Text>,
    },
    {
        name: 'UOM',
        width: 70,
        value: 'uom',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value ? value : '-'}</Text>,
    },
    {
        name: 'supplier',
        width: 180,
        value: 'supplier',
        sortValue: 'supplier_name',
        label: 'sort by supplier',
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['name'].map((suppName, index) => (
                    <Text className="column-text" key={index}>
                        {value[suppName] ? value[suppName] : '-'}
                    </Text>
                ))}
            </Box>
        ),
    },
    {
        name: 'kode supplier',
        width: 113,
        value: 'supplier_ref',
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'kategori supplier',
        value: 'supplier',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value?.supplierCategory?.category}</Text>,
    },
    {
        name: 'transition',
        value: 'transition_code',
        sortValue: 'transition_code',
        label: 'sort by transition',
        width: 172,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'status',
        value: 'transition_status',
        sortValue: 'transition_status',
        label: 'sort by status',
        width: 125,
        children: ['-'],
        component: ({ value }) => <TextStatus>{value ? value : '-'}</TextStatus>,
    },
    {
        name: 'main component',
        value: 'materialItemSets',
        width: 133,
        children: ['-'],
        component: ({ value }) => {
            const isChecked = Boolean(value?.toString());
            return (
                <Flex flexDirection="column">
                    <Checkbox defaultChecked={isChecked} readOnly className="checkbox-disable" />
                </Flex>
            );
        },
    },
    {
        name: 'set item',
        value: 'set_item',
        width: 125,
        children: ['pcs'],
        component: ({ value }) => <Text className="column-text">{value ? value : '-'}</Text>,
    },
    {
        name: 'MTO/MTS',
        value: 'is_mto',
        width: 90,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value ? 'MTO' : 'MTS'}</Text>,
    },
    {
        name: 'close PO by',
        value: 'close_po_by',
        width: 106,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'satuan order',
        value: 'satuan_order',
        width: 103,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'jenis kemasan',
        value: 'materialRaci',
        width: 111,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['jenis_kemasan'].map((jenisItem, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[jenisItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Pcs/Koli atau Kg/Kemasan',
        value: 'materialRaci',
        width: 178,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['qty_per_kemasan'].map((itemPcs, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemPcs])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Koli/Pallet atau Kemasan/Pallet',
        value: 'materialRaci',
        width: 206,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['kemasan_per_storage'].map((itemKoli, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemKoli])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Pcs/Pallet atau Kg/Pallet',
        value: 'materialRaci',
        width: 178,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['qty_per_storage'].map((itemPallet, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemPallet])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'kanban',
        value: 'kanban',
        width: 67,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Baseline/Week (Pcs atau Kg)',
        value: 'baseline_pcs',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Baseline/Week (koli atau kemasan)',
        value: 'baseline_koli',
        width: 240,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Baseline/Week (Pallet)',
        value: 'baseline_pallet',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'avg baseline/day',
        value: 'avg_baseline_pcs',
        width: 131,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Stdev',
        value: 'stdev',
        width: 71,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'safety by demand',
        value: 'materialCriticalPoint',
        width: 142,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['safety_demand_pcs'].map((dataItem, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[dataItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'Safety By Demand (hari)',
        value: 'materialCriticalPoint',
        width: 170,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['safety_demand_hari'].map((itemData, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemData])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'safety by vulnerability',
        value: 'safety_vulnerability',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'safety by deviasi LT',
        value: 'safety_leadtime',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'frekuensi delivery(hari)',
        value: 'delivery_freq',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'lead time produksi(hari)',
        value: 'production_leadtime',
        sortValue: 'production_leadtime',
        label: 'sort by lead time produksi',
        width: 140,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'lead time delivery (hari)',
        value: 'delivery_leadtime',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MPQ PTI',
        value: 'mpq_pti',
        width: 82,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MPQ PTI roundup',
        value: 'materialCriticalPoint',
        width: 85,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_pti_roundup'].map((mpqItem, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[mpqItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'MPQ supplier',
        value: 'mpq_supplier',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MPQ supplier roundup',
        value: 'materialCriticalPoint',
        width: 108,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_supplier_roundup'].map((suppItem, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[suppItem])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'MDQ supplier',
        value: 'mdq_supplier',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'indeks MPQ vs baseline',
        value: 'materialCriticalPoint',
        sortValue: 'mpq_v_baseline',
        label: 'sort by indeks MPQ vs baseline',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_v_baseline'].map((itemMpq, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemMpq])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'indeks MPQ vs MPQ PTI',
        value: 'materialCriticalPoint',
        sortValue: 'mpq_supp_v_mpq_pti',
        label: 'sort by indeks MPQ vs MPQ PTI',
        width: 120,
        children: ['-'],
        component: ({ value }) => (
            <Box>
                {['mpq_supp_v_mpq_pti'].map((itemSupp, index) => {
                    return (
                        <Text className="column-text" key={index}>
                            {formatNumber(value[itemSupp])}
                        </Text>
                    );
                })}
            </Box>
        ),
    },
    {
        name: 'ROP OSPO',
        value: 'rop_ospo',
        width: 89,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'ROP PTI (Pcs)',
        value: 'stock_pti_rop',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX Stock PTI (Pcs atau Kg)',
        value: 'stock_pti_max',
        width: 190,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX Stock PTI (Koli atau Kemasan)',
        value: 'stock_pti_max_koli',
        width: 240,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX stock PTI (pallet)',
        value: 'stock_pti_max_storage',
        width: 160,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'indeks max vs baseline',
        value: 'max_stock_v_baseline',
        sortValue: 'max_stock_v_baseline',
        label: 'sort by indeks max vs baseline',
        width: 120,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'ROP supplier',
        value: 'stock_supp_rop',
        width: 142,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX Stock Supplier (Pcs atau Kg)',
        value: 'stock_supp_max',
        width: 230,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX Stock Supplier (Koli atau Kemasan)',
        value: 'stock_supp_max_koli',
        width: 270,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'MAX stock supplier (Pallet)',
        value: 'stock_supp_max_storage',
        width: 200,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
];

export const initialValueFormTransition = {
    old_item_code: '',
    old_trade_name: '',
    new_item_code: '',
    new_trade_name: '',
    status: '',
};
