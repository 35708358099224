import { Box, Button, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AddIconBlue, DottedIcon, DatabaseIcon } from '@asset/icons';
import DropDown from '@pagesPhase2/materialConfiguration/components/dropDownMenu';
import { optionMenuDotMC } from './const';
import { onDownloadDataRACI } from './function';
import MultipleFilter from './modalFiltersMultiple';
import { useNavigate } from 'react-router-dom';
import { isAllowed } from '@helpers/roleAccess';
import { MaterialConfigurationContext } from '@pagesPhase2/materialConfiguration';

import './styles.scss';
import FilterItemCode from '@components/filterItemCode';
import FilterTradeName from '@components/filterTradeName';

function Index({ section, setSection, onDownload }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { setFilter, setPage: setMainPage } = React.useContext(MaterialConfigurationContext);
    const [open, setOpen] = React.useState(false);

    const handleMenuDropDown = (name) => {
        switch (name) {
            case 'Upload':
                navigate('/material-configuration/upload-item');
                break;
            case 'Add Item Set':
                navigate('/material-configuration/add-new-item-set');
                break;
            case 'Archived List':
                navigate('/material-configuration/archive-list');
                break;
            case 'Download Data':
                onDownload();
                break;
            case 'Download RACI':
                onDownloadDataRACI({ dispatch });
                break;
            default:
        }
    };

    return (
        <Box>
            <Flex className="material-configuration-box-header">
                <Flex gap="10px" alignItems="center">
                    <DatabaseIcon />
                    <Text className="text-material-conf">Material Configuration</Text>
                </Flex>
                {section?.includes('main') && (
                    <Flex className="action-button-container" alignItems="center">
                        <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                        <Box mx="16px">
                            <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                        </Box>
                        <MultipleFilter />
                        <Box className="divider" />
                        {isAllowed({ pageName: 'Material Config', permissionName: 'Create Material Config' }) && (
                            <Box
                                onClick={() => navigate('/add-new-item-material-config')}
                                className="button-add-item__container"
                            >
                                <AddIconBlue />
                                Add New Item
                            </Box>
                        )}
                        <DropDown
                            fontWeightListMenu={600}
                            width="160px"
                            placement="bottom-start"
                            option={optionMenuDotMC()}
                            open={open}
                            onClickMenu={(item) => {
                                setSection(item?.section);
                                handleMenuDropDown(item.name);
                            }}
                            onClose={() => setOpen(false)}
                        >
                            <Button
                                onClick={() => setOpen(true)}
                                sx={{
                                    width: '32px',
                                    padding: 1,
                                    height: '32px',
                                }}
                                variant="ghost"
                            >
                                <DottedIcon />
                            </Button>
                        </DropDown>
                    </Flex>
                )}
            </Flex>
        </Box>
    );
}

export default Index;
