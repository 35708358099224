import { useState, createContext, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import SectionHead from '../sectionOrderConfirmation/sections/sectionHead';
import SectionTable from '../sectionOrderConfirmation/sections/sectionTable';
import TableSkeleton from '@components/tableSkeleton';
import EmptyState from '@components/emptyState';
import Modalsuccess from '@components/modalSuccess';
import Pagination from '@components/pagination';
import ModalConfirm from '@components/modalConfirm';
import { tableListOrderConfirmation } from './const';
import { numToArr } from '@helpers/functions';
import './styles.scss';
import { useSelector } from 'react-redux';
import { fetchDataOrderConfirmation, handleReplaceItem } from './function';

export const TableOrderConfirmationContext = createContext();

const SectionOrderConfirmation = ({ goBack }) => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [items, setItems] = useState([]);
    const [itemEdited, setItemEdited] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [totalPage, setTotalPage] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditColumn, setIsEditColumn] = useState(false);
    const [page, setPage] = useState(1);
    const [filter, setFilter] = useState({ trade_name: [], itemCode: [] });
    const [isOpen, setIsOpen] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const refecthOrderConfirmation = () => {
        fetchDataOrderConfirmation({
            setItems,
            selectedPerPage,
            setIsLoading,
            setTotalData,
            setTotalPage,
            jenisMaterial,
            supplier,
            setPage,
            filter,
            page,
            itemEdited,
        });
    };
    useEffect(() => {
        if (jenisMaterial?.id) {
            refecthOrderConfirmation();
        }
    }, [jenisMaterial?.id, supplier, selectedPerPage, page, filter]);
    useEffect(() => {
        if (Boolean(itemEdited?.toString())) {
            handleReplaceItem({ itemEdited, setItems });
        }
    }, [itemEdited, page, isLoading, selectedPerPage]);

    return (
        <TableOrderConfirmationContext.Provider
            value={{
                filter,
                setFilter,
                setPage,
                isEditColumn,
                setIsEditColumn,
                items,
                setItems,
                itemEdited,
                refecthOrderConfirmation,
                setItemEdited,
            }}
        >
            <Box className="section-table-order-confirmation__configuration">
                <SectionHead goBack={goBack} />
                {isLoading && (
                    <TableSkeleton
                        tableList={tableListOrderConfirmation}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                {!isLoading && <SectionTable items={items} />}
                {!isLoading && !items.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                <Pagination
                    onChangeLimit={() => {
                        setPage(1);
                    }}
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />
                {/*---------MODAL-----------*/}
                <ModalConfirm
                    isOpen={isOpen}
                    onClose={() => setIsOpen(false)}
                    onSubmit={() => setIsSubmit(true)}
                    title="Apakah anda yakin?"
                    submitLabel="Lanjut"
                />
                {isSubmit && <Modalsuccess isOpen={isOpen} onClose={() => setIsOpen(false)} title="Submit success" />}
            </Box>
        </TableOrderConfirmationContext.Provider>
    );
};

export default SectionOrderConfirmation;
