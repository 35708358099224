export const NotesIcon = () => (
    <svg width="40" height="40" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 5.5C6 5.22386 6.22386 5 6.5 5H11.5L14 7.5V14.5C14 14.7761 13.7761 15 13.5 15H6.5C6.22386 15 6 14.7761 6 14.5V5.5Z"
            stroke="#EF6E6E"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <path d="M8 9H12" stroke="#EF6E6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 11H12" stroke="#EF6E6E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        {/* <circle cx="10" cy="10" r="9.5" stroke="#EF6E6E" /> */}
    </svg>
);
