import { DeliveryTruckIcon, DottedIcon, HandBoxIcon, OrderManagementIcon } from '@asset/icons';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import DropDown from '@pagesPhase2/orderManagement/component/dropDownMenu';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { optionMenuDot, title } from './const';
import MultipleFilter from './modalFiltersMultiple';
import FilterTradeName from '@components/filterTradeName';
import { OrderManagementContext } from '@pagesPhase2/orderManagement';
import FilterItemCode from '@components/filterItemCode';
import { isAllowed } from '@helpers/roleAccess';

import './styles.scss';

const SectionHead = ({ section, onDownload, setSelectedPage }) => {
    const { setFilter, setPage: setMainPage } = useContext(OrderManagementContext);
    const [open, setOpen] = useState(false);
    const label = title.find((item) => item.name === section).title;
    const navigate = useNavigate();

    const handleMenuDropDown = (name) => {
        switch (name) {
            case 'Download Data':
                onDownload();
                break;
            default:
        }
    };

    return (
        <div>
            <Flex className="order-management-box-header">
                <Flex gap="10px" alignItems="center">
                    <Box>
                        <OrderManagementIcon />
                    </Box>
                    <Text className="text-order-management">{label}</Text>
                </Flex>
                {section?.includes('main') && (
                    <Flex className="action-button-container" alignItems="center">
                        <FilterItemCode setFilter={setFilter} setMainPage={setMainPage} />
                        <Box mx="12px">
                            <FilterTradeName setFilter={setFilter} setMainPage={setMainPage} />
                        </Box>
                        <MultipleFilter />
                        <Box className="divider" />
                        {isAllowed({ pageName: 'Order Management', permissionName: 'Order Confirmation' }) && (
                            <Box
                                display={section?.includes('order_delivery') ? 'none !important' : 'flex !important'}
                                className="button-order-confirmation__container"
                                onClick={() => setSelectedPage('order-confirmation')}
                            >
                                <Box mr="8px">
                                    <HandBoxIcon />
                                </Box>
                                Order Confirmation
                            </Box>
                        )}
                        {isAllowed({ pageName: 'Order Management', permissionName: 'Order Delivery' }) && (
                            <Box
                                display={section?.includes('order_delivery') ? 'none !important' : 'flex !important'}
                                onClick={() => setSelectedPage('order-delivery')}
                                className="button-order-delivery__container"
                            >
                                <Box mr="8px">
                                    <DeliveryTruckIcon />
                                </Box>
                                Order Delivery
                            </Box>
                        )}
                        {section?.includes('order_delivery') ? null : (
                            <DropDown
                                customBodyContentClassName="box"
                                fontWeightListMenu={600}
                                placement="left-start"
                                width="160px"
                                option={optionMenuDot}
                                open={open}
                                onClickMenu={(item) => handleMenuDropDown(item.name)}
                                onClose={() => setOpen(false)}
                            >
                                <Button
                                    onClick={() => setOpen(true)}
                                    sx={{
                                        width: '32px',
                                        padding: 1,
                                        height: '32px',
                                        marginLeft: '6px',
                                    }}
                                    variant="ghost"
                                >
                                    <DottedIcon />
                                </Button>
                            </DropDown>
                        )}
                    </Flex>
                )}
            </Flex>
        </div>
    );
};

export default SectionHead;
