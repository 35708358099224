import { useState } from 'react'
import {
    Box,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Button,
    Flex,
    Text,
    PopoverBody,
    SimpleGrid,
    Checkbox,
    PopoverFooter,
    Tooltip,
} from '@chakra-ui/react';
import { ArrowRightIcon } from '@asset/icons';
import { streamlineDummy } from '@pages/recapOrder/const';
import './styles.scss';


const SectionFilterStreamline = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectFilterStreamline, setSelectedFilterStreamline] = useState([]);
  const [filterStreamline, setFilterStreamline] = useState([]);
  const [selectedStreamlineProductionStatus, setSelectedStreamlineProductionStatus] = useState([]);
  const [searchStreamline, setSearchStreamline] = useState('');

  const truncateText = (text) => {
      if (text.length > 4) {
          return text.substring(0, 4) + '';
      } else {
          return text;
      }
  };

  const onApplyHandler = () => {
      setSelectedFilterStreamline(filterStreamline);
      setIsOpen(false);
  };

  const onReset = () => {
      setFilterStreamline([]);
      setSelectedStreamlineProductionStatus([]);
      searchStreamline('');
  };

  const onCheckStreamLine = ({ isCheck, val }) => {
      if (isCheck) {
          setFilterStreamline([...filterStreamline, val.id]);
          return;
      }
      setFilterStreamline(filterStreamline.filter((i) => i !== val.id));
  };

  const onCloseFilterStreamline = () => {
      setIsOpen(false);
      setFilterStreamline([]);
  };

  return (
      <>
          <Popover isOpen={isOpen} isLazy onClose={onCloseFilterStreamline}>
              <PopoverTrigger>
                  <Flex className="section-select-streamline" cursor="pointer" onClick={() => setIsOpen(true)}>
                      <Text className="text-select-streamline">Pilih Streamline</Text>
                      <Box className="icon-search-streamline">
                          <ArrowRightIcon />
                      </Box>
                      {selectFilterStreamline?.length > 0 && (
                          <Box className="notif-filter__container">
                              <Text>{selectFilterStreamline?.length}</Text>
                          </Box>
                      )}
                  </Flex>
              </PopoverTrigger>
              <PopoverContent w="404px" height={'337px'} position="absolute" top="0px" left="-72px">
                  <PopoverBody h="full">
                      <Text className="text-head-popover">Pilih Streamline</Text>
                      <Flex className="after-head-popover__container">
                          <Text w="54px" cursor={'pointer'}>
                              Select all
                          </Text>
                          <Box w="27px">
                              <Box className="dots" />
                          </Box>
                          <Text cursor={'pointer'} onClick={onReset}>
                              Reset
                          </Text>
                      </Flex>
                      {isLoading && <Text>Loading...</Text>}
                      {!isLoading && (
                          <SimpleGrid columns={5} mt="12px">
                              {streamlineDummy?.map((i, index) => {
                                  return (
                                      <Checkbox
                                          key={index}
                                          fontSize={'14px'}
                                          fontWeight={400}
                                          isChecked={filterStreamline?.includes(i.id)}
                                          onChange={(e) => onCheckStreamLine({ isCheck: e.target.checked, val: i })}
                                      >
                                          <Tooltip aria-label="tooltip">{truncateText(i.name)}</Tooltip>
                                      </Checkbox>
                                  );
                              })}
                          </SimpleGrid>
                      )}
                  </PopoverBody>
                  <PopoverFooter border={'none'} ml="auto" mr={0}>
                      <Button
                          sx={{
                              alignItems: 'center',
                              padding: '5px 12px',
                              width: '104px',
                              height: '36px',
                              backgroundColor: '#4163a4',
                              borderRadius: '4px',
                              fontWeight: 500,
                              fontSize: '12px',
                              color: '#ffffff',
                              '&:hover': {
                                  backgroundColor: '#5279c3',
                              },
                          }}
                          onClick={onApplyHandler}
                      >
                          Apply
                      </Button>
                  </PopoverFooter>
              </PopoverContent>
          </Popover>
      </>
  );
}

export default SectionFilterStreamline;