import { ArrowDownIcon } from '@asset/icons';
import {
    Box,
    Checkbox,
    Menu,
    MenuList,
    Text,
    InputGroup,
    Tooltip,
    Input,
    InputRightElement,
    Spinner,
    Button,
} from '@chakra-ui/react';
import { ResetIcon } from '@asset/icons';
import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { onChangeSupplierType, onChangeSelectedMaterial, onChangeSupplierRef } from '@store/list/globalFilter';
import { SearchIcon } from '../icons';
import { fetchSupplierDataHandler } from './function';
import './styles.scss';

const MenuFilterHeaderSupplier = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalData, setTotalData] = useState(0);
    const [search, setSearch] = useState('');
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);

    const { jenisMaterial, supplier, selectedMaterial, supplierRef } = useSelector(
        (reducer) => reducer.globalFilterSlice
    );
    useEffect(() => {
        fetchSupplierDataHandler({
            page: jenisMaterial?.id ? 1 : !Boolean(jenisMaterial?.id) ? 1 : page,
            setData,
            setPage,
            setTotalData,
            search,
            jenisMaterial,
            setIsLoading,
            // supplierChoosed: supplier,
        });
    }, [jenisMaterial]);

    const getMoreData = () => {
        fetchSupplierDataHandler({
            page,
            setData,
            setPage,
            setTotalData,
            search,
            moreData: true,
            jenisMaterial,
            setIsLoading,
        });
    };

    const onSearchHandler = () => {
        fetchSupplierDataHandler({
            page: 1,
            setData,
            setPage,
            setTotalData,
            search,
            jenisMaterial,
            setIsLoading: setLoadingSearch,
        });
    };

    const onSelectedSupplier = ({ value, checked }) => {
        dispatch(onChangeSelectedMaterial(value));
        if (!checked) {
            const newValueSupplier = supplier.filter((item) => item !== value.name);
            const newValueSupplierRef = supplierRef.filter((item) => item !== value.supplier_ref);
            dispatch(onChangeSupplierRef(newValueSupplierRef));
            dispatch(onChangeSupplierType(newValueSupplier));
            if (supplier?.length <= 1) {
                dispatch(onChangeSupplierRef([]));
                dispatch(onChangeSelectedMaterial({}));
            }
            return;
        }
        const concatSupplier = supplier?.concat(value.name);
        const concatSupplierRef = supplierRef?.concat(value.supplier_ref);
        dispatch(onChangeSupplierType(concatSupplier));
        dispatch(onChangeSupplierRef(concatSupplierRef));
    };
    const handleReset = () => {
        dispatch(onChangeSelectedMaterial([]));
        dispatch(onChangeSupplierType([]));
        dispatch(onChangeSupplierRef([]));
    };
    return (
        <Box className="menu-filter-supplier__container">
            <Box>
                <InputGroup className="input-filter-parent-supplier">
                    <Tooltip label="pilh supplier" aria-label="A tooltip">
                        <Input
                            focusBorderColor={'#D7D7D7'}
                            className="input-selected-supplier"
                            value={
                                (supplier?.length <= 1 ? selectedMaterial?.name?.slice(0, 20) : 'Mutiple Selection') ||
                                '-'
                            }
                            readOnly
                            onFocus={() => setOpenInfiniteScroll(true)}
                        />
                    </Tooltip>
                    <InputRightElement h="35px" onClick={() => setOpenInfiniteScroll(true)}>
                        {isLoading ? <Spinner /> : <ArrowDownIcon />}
                    </InputRightElement>
                </InputGroup>
                <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                    <MenuList height={data.length > 1 ? '285px' : '130px'} className="menu-list-wrapper">
                        <Box>
                            <input
                                className="input-box-menu-list"
                                placeholder="cari nama item"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchHandler();
                                    }
                                }}
                            />
                            <Box className="search-icon" onClick={onSearchHandler}>
                                {loadingSearch ? <Spinner /> : <SearchIcon />}
                            </Box>
                        </Box>
                        <Box className="container-item-supplier">
                            <div id="scrollableDiv">
                                <InfiniteScroll
                                    dataLength={data.length}
                                    next={getMoreData}
                                    hasMore={data.length === totalData ? false : true}
                                    loader={
                                        data.length === 0 ? (
                                            <Text className="loading_fetch_next_page">Loading...</Text>
                                        ) : data.length < 9 ? null : (
                                            <Text className="loading_fetch_next_page">Loading...</Text>
                                        )
                                    }
                                    endMessage={null}
                                    scrollableTarget="scrollableDiv"
                                >
                                    {data.length === 0 && !loadingSearch ? (
                                        <Text className="no_data_infinite_scroll">Nama supplier tidak tersedia</Text>
                                    ) : (
                                        <Box>
                                            {data?.map((item, index) => {
                                                return (
                                                    <Box key={index} className="menu-item-supplier">
                                                        <Box className="wrapper-menu-checkbox-item">
                                                            <Text className="menu-item-text">{item.name}</Text>
                                                            <Checkbox
                                                                isChecked={supplier.includes(item.name)}
                                                                onChange={(e) => {
                                                                    onSelectedSupplier({
                                                                        value: item,
                                                                        checked: e.target.checked,
                                                                    });
                                                                }}
                                                                className="checkbox-custom-style"
                                                            />
                                                        </Box>
                                                    </Box>
                                                );
                                            })}
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </div>
                        </Box>
                    </MenuList>
                </Menu>
            </Box>
            {Boolean(supplier?.toString()) && (
                <Button onClick={handleReset} variant="ghost" className="clear-global-filter-jenis-material">
                    <ResetIcon color="#1746a2" />
                </Button>
            )}
        </Box>
    );
};

export default MenuFilterHeaderSupplier;
