import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    Box,
    Text,
    Flex,
    Checkbox,
    Spinner,
} from '@chakra-ui/react';
import CloseIcon from '@pages/mainDashboard/components/AutoRefreshNotification/Icon';
import { SearchIcon, CloseIcon as CloseIconBlue } from './icons';
import { handleCheckboxChange } from './function';
import InfiniteScroll from 'react-infinite-scroll-component';
import './styles.scss';

function Index({
    isOpen,
    onClose,
    tradeName = [],
    setTradeName,
    handleApplyFilter,
    outlineLabel,
    modalTitle,
    labelSearch,
    labelSelectedData,
    handleResetFilter,
    getMoreData,
    onSearchHandler,
    totalData,
    loadingSearch,
    setSearch,
    search,
    setTradeNameChoosed,
    tradeNameChoosed,
}) {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Box className="modal-container-filter-trade-name-component">
                <ModalOverlay />
                <ModalContent maxW="544px">
                    <Flex className="modal-filter-checked-header">
                        <Text className="modal-header-text">{modalTitle}</Text>
                        <Box onClick={onClose} cursor="pointer">
                            <CloseIcon />
                        </Box>
                    </Flex>
                    <ModalBody className="modal-body">
                        <Text className="label-input">{labelSelectedData}</Text>
                        <Box className="box-selected-item">
                            {/* stackoverflow start */}
                            {/* https://stackoverflow.com/a/45014215 */}
                            {/* issue : https://stackoverflow.com/questions/45014094/how-do-i-fix-expected-to-return-a-value-at-the-end-of-arrow-function-warning */}
                            {/* answer by Kris Selbekk : https://stackoverflow.com/users/1064572/kris-selbekk */}
                            {tradeNameChoosed?.map((value, index) => {
                                return (
                                    <Flex key={index} className="box-card-selected-item">
                                        <Text className="text-selected-item">{value?.trade_name}</Text>
                                        <Box
                                            cursor="pointer"
                                            onClick={() =>
                                                handleCheckboxChange({
                                                    checked: false,
                                                    selectedData: value,
                                                    data: tradeName,
                                                    setData: setTradeName,
                                                    tradeNameChoosed,
                                                    setTradeNameChoosed,
                                                })
                                            }
                                        >
                                            <CloseIconBlue />
                                        </Box>
                                    </Flex>
                                );
                            })}
                            {/* stackoverflow end */}
                        </Box>
                        <Text className="label-input">{labelSearch}</Text>

                        <Box position="relative" mt="16px">
                            <input
                                value={search || ''}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        onSearchHandler();
                                    }
                                }}
                                className="input-box"
                                placeholder="cari nama item"
                            />
                            <Box className="search-icon" onClick={onSearchHandler}>
                                {loadingSearch ? <Spinner size="sm" /> : <SearchIcon />}
                            </Box>
                        </Box>
                        <Text className="label-list-item-text">List nama</Text>

                        {/* https://stackoverflow.com/a/73260330 */}
                        <div id="scrollableDiv" className="scroll-container">
                            <InfiniteScroll
                                height={200}
                                dataLength={tradeName.length}
                                next={() => getMoreData({ moreData: true })}
                                hasMore={tradeName.length === totalData ? false : true}
                                scrollableTarget="scrollableDiv"
                                loader={
                                    tradeName.length < 9 ? null : (
                                        <Text className="loading_fetch_next_page">Loading...</Text>
                                    )
                                }
                                endMessage={
                                    tradeName.length < 1 ? (
                                        <Text className="loading_fetch_next_page">Loading...</Text>
                                    ) : null
                                }
                            >
                                {tradeName.length === 0 && !loadingSearch ? (
                                    <Text className="no_data_infinite_scroll">Trade name tidak tersedia</Text>
                                ) : (
                                    <Box className="container-list-trade-name">
                                        {tradeName.map((item, index) => (
                                            <Flex key={index} alignItems="center" justifyContent="space-between">
                                                <Text className="list-item-text">{item?.trade_name}</Text>
                                                <Checkbox
                                                    isChecked={item.checked}
                                                    onChange={(checked) =>
                                                        handleCheckboxChange({
                                                            checked: checked.target.checked,
                                                            selectedData: item,
                                                            data: tradeName,
                                                            setData: setTradeName,
                                                            setTradeNameChoosed,
                                                            tradeNameChoosed,
                                                        })
                                                    }
                                                    className="checkbox-custom-style"
                                                />
                                            </Flex>
                                        ))}
                                    </Box>
                                )}
                            </InfiniteScroll>
                        </div>
                    </ModalBody>

                    <ModalFooter className="modal-footer">
                        <Box className="btn-modal-reset" onClick={handleResetFilter}>
                            <Text className="text-modal-reset">{outlineLabel}</Text>
                        </Box>
                        <Box className={`btn-apply-filter`} onClick={handleApplyFilter}>
                            <Text className="text-apply-filter">Apply Filter</Text>
                        </Box>
                    </ModalFooter>
                </ModalContent>
            </Box>
        </Modal>
    );
}

export default Index;
