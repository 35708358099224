import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import EmptyState from '@components/emptyState';
import Pagination from '@components/pagination';
import TableSkeleton from '@components/tableSkeleton';
import { numToArr } from '@helpers/functions';
import { createContext, useEffect, useState } from 'react';
import CreateAddItemSet from './components/createAddNewItemSet';
import { formDefaultValue } from './components/createAddNewItemSet/const';
import DeleteModal from './components/deleteModal';
import { tableListAddItem } from './const';
import { fetchMaterialItemSetHandler, onDeletedAccount } from './function';
import SectionHead from './section/sectionHead';
import SectionTable from './section/sectionTable';
import './styles.scss';

export const tableAddNewItemContext = createContext();

const SectionAddnewItemSet = () => {
    const { jenisMaterial, supplier } = useSelector((reducer) => reducer.globalFilterSlice);
    const [isLoading, setIsLoading] = useState(true);
    const [totalPage, setTotalPage] = useState(0);
    const [formData, setFormData] = useState(formDefaultValue);
    const [totalData, setTotalData] = useState(0);
    const [page, setPage] = useState(1);
    const [isOpenModal, setOpenModal] = useState(false);
    const [materialItem, setMaterialItem] = useState([]);
    const [selectedPerPage, setSelectedPerpage] = useState(10);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedItem, setSelectedItem] = useState({});
    const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
    const [modalToggler, setModalToggler] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const onOpenModal = ({ value }) => setOpenModal(value);
    const handlePageClick = ({ selected }) => setPage(selected + 1);
    const onSelectedItem = ({ value }) => setSelectedItem(value);
    const togglerDeleteModal = () => setIsShowDeleteModal(!isShowDeleteModal);

    const onSelectedData = ({ item }) => {
        setSelectedId(item.id);
        onChangeModalState({ value: 'modal-edit' });
    };

    const onResetFilter = () => {
        setPage(1);
        setSearchQuery('');
    };

    const onChangeModalState = ({ value }) => setModalToggler(value);

    const reFetchMaterialItemSetHandler = () => {
        page === 1 && searchQuery === ''
            ? fetchMaterialItemSetHandler({
                  searchQuery,
                  setMaterialItem,
                  setTotalData,
                  setTotalPage,
                  setIsLoading,
                  page,
                  jenisMaterial,
                  supplier,
              })
            : onResetFilter();
    };

    useEffect(() => {
        fetchMaterialItemSetHandler({
            page,
            setMaterialItem,
            setTotalData,
            setTotalPage,
            setIsLoading,
            selectedPerPage,
            jenisMaterial,
            supplier,
        });
    }, [page, searchQuery, selectedPerPage, jenisMaterial, supplier]);

    return (
        <tableAddNewItemContext.Provider
            value={{
                selectedItem,
                isOpenModal,
                onOpenModal,
                onSelectedItem,
                togglerDeleteModal,
                onSelectedData,
            }}
        >
            <Box className="section-table-add-new-item__configuration">
                <SectionHead onChangeModalState={onChangeModalState} />
                {!isLoading && (
                    <SectionTable
                        materialItem={materialItem}
                        onOpenModal={onOpenModal}
                        onSelectedItem={onSelectedItem}
                        onChangeModalState={onChangeModalState}
                    />
                )}
                {!isLoading && !materialItem.length && (
                    <EmptyState
                        description="Maaf, tidak ada data yang bisa ditamplikan,silakan memilih Role yang lain."
                        text="Tidak ada data yg di tampilkan"
                    />
                )}
                {isLoading && (
                    <TableSkeleton
                        tableList={tableListAddItem}
                        count={numToArr(10)}
                        tableHeadHeight="50px"
                        tablColumnHeight="70px"
                    />
                )}
                <Pagination
                    onChangeLimit={() => {
                        setPage(1);
                    }}
                    page={page}
                    totalPage={totalPage}
                    totalData={totalData}
                    selectedPerPage={selectedPerPage}
                    setSelectedPerpage={(selected) => setSelectedPerpage(selected)}
                    onPageChange={handlePageClick}
                />

                <CreateAddItemSet
                    isEditModal={false}
                    isOpen={modalToggler === 'modal-create'}
                    onChangeModalState={onChangeModalState}
                    formData={formData}
                    setFormData={setFormData}
                    reFetchMaterialItemSetHandler={reFetchMaterialItemSetHandler}
                />

                <CreateAddItemSet
                    isEditModal={modalToggler === 'modal-edit'}
                    isOpen={modalToggler === 'modal-edit'}
                    onChangeModalState={onChangeModalState}
                    reFetchMaterialItemSetHandler={reFetchMaterialItemSetHandler}
                    mainId={selectedId}
                />

                {/*================== Modals ================*/}
                <DeleteModal
                    isOpen={isShowDeleteModal}
                    onClose={togglerDeleteModal}
                    onDeletedAccount={onDeletedAccount}
                    reFetchMaterialItemSetHandler={reFetchMaterialItemSetHandler}
                    togglerDeleteModal={togglerDeleteModal}
                    selectedItem={selectedItem}
                />
            </Box>
        </tableAddNewItemContext.Provider>
    );
};

export default SectionAddnewItemSet;
