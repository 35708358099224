export const columnConfig = [
    {
        columnName: 'Kode Item',
        isRequired: true,
        type: 'any',
    },
    {
        columnName: 'Nama Item',
        isRequired: true,
        type: 'any',
    },
    {
        columnName: 'SOH Supplier',
        isRequired: true,
        type: 'number',
    },
    {
        columnName: 'On Process',
        isRequired: true,
        type: 'number',
    },
    {
        columnName: 'Notes',
        isRequired: false,
        type: 'any',
    },
];
