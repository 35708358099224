import Axios from '@services/axios';
import queryString from 'query-string';
import Cookies from 'universal-cookie';
import { initalFormValue } from './const';
import moment from 'moment';

export const fetchModalFilterInfinite = async ({
    page,
    setData,
    setLoadingSearch,
    setPage,
    moreData = false,
    setTotalData,
    search,
    loadingOnUserTyping,
    setLoadingUserTyping,
}) => {
    const cookies = new Cookies();
    const userToken = cookies.get('userToken');
    const filterStringify = queryString.stringify({ page, q: search, source: 'RDO' }, { skipEmptyString: true });
    setLoadingSearch(true);
    setLoadingUserTyping(loadingOnUserTyping);
    try {
        const { data } = await Axios({
            method: 'GET',
            url: `/po/list?${filterStringify}`,
            headers: { Authorization: 'Bearer ' + userToken },
        });
        let { PO, totalData } = data.data;
        if (moreData && !search) {
            setData((prev) => [...prev, ...PO]);
            setPage(page + 1);
        } else {
            setData(PO);
        }
        setTotalData(totalData);
        setPage(page + 1);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    } catch (error) {
        console.log(error);
        setLoadingSearch(false);
        setLoadingUserTyping(false);
    }
};

export const onFormatDate = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    return date;
};

export const selectedFilter = ({ value, setSelectedDataItem, setStoreFilter }) => {
    const filterSelected = Object.values(value);
    const keyFilterSelected = Object.keys(value);
    let result = [];
    for (let index = 0; index < filterSelected.length; index++) {
        if (Boolean(filterSelected[index]) && value[keyFilterSelected[index]] === filterSelected[index]) {
            result.push({ [keyFilterSelected[index]]: filterSelected[index] });
        }
    }
    setSelectedDataItem(result);
    setStoreFilter({ filterSelected: result, formData: value });
};

export const handleApplyFilter = ({
    formData,
    setSelectedDataItem,
    setFilter,
    setStoreFilter,
    setPage,
    setSearch,
    setRefetch,
    refetch,
    setOpenModalFilter,
}) => {
    const formatFormData = {
        no_po: formData?.no_po,
        inputDate: Boolean(formData?.inputDate) ? onFormatDate(formData?.inputDate) : '',
    };
    selectedFilter({ value: formatFormData, setSelectedDataItem, setStoreFilter });
    setFilter((prev) => ({
        ...prev,
        no_PO: formData?.no_po,
        eta_confirm_date: formData?.inputDate,
    }));
    setSearch('');
    setPage(1);
    setRefetch(!refetch);
    setOpenModalFilter(false);
};

export const handleCloseModal = ({ selectedDataItem, setFormData, setOpenModalFilter, setPage, setSearch, setSelectDate }) => {
    let prevInitialValue = { ...initalFormValue };
    let keyValue = Object.keys(initalFormValue);
    let currentSelect = [...selectedDataItem];
    keyValue.map((key) => {
        currentSelect.map((current) => {
            const keyObject = Object.keys(current);

            if (key === keyObject[0]) {
                prevInitialValue[key] = current[keyObject[0]];
            }
            prevInitialValue[keyObject[0]] = current[keyObject[0]];
        });
    });
    setFormData(prevInitialValue);
    setSearch('')
    setSelectDate(null)
    setPage(1);
    setOpenModalFilter(false);
};

export const findBadgeSelectedFilter = (value) => {
    let result = value?.filter((item) => {
        const keyObject = Object.keys(item);
        if (typeof item[keyObject[0]] === 'string') {
            return item;
        }
    });
    return result;
};

export const handleFocus = ({ setSearch, setSelectDate, setOpenIf, formData }) => {
    setSearch(formData?.no_po);
    setSelectDate(formData?.inputDate);
    setOpenIf(true);
};
