import React, { useState, useEffect } from 'react';
import { Flex, Box, Text, InputGroup, Input, InputRightElement, Menu, MenuList, Spinner } from '@chakra-ui/react';
import { ArrowUpIconBlack } from '@asset/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { EmptyField } from '@components/fieldMenu';
import { isEmpty } from 'lodash';
import { fetchInfiniteMainComponent } from './function';
import './styles.scss';

const MainComponent = ({ isSubmitted, kode_item, internal_name, isEditModal, setFormData }) => {
    const [mainData, setMainData] = useState([]);
    const [openInfiniteScroll, setOpenInfiniteScroll] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const getMoreData = () => {
        fetchInfiniteMainComponent({
            setMainData,
            setLoadingSearch: setLoadingSearch,
            setFilterData,
        });
    };
    const handleChange = (text) => {
        const { value } = text.target;
        if (value || value === '') {
            if (!isEmpty(value)) {
                setLoadingSearch(true);
                const filteredData = mainData?.filter((item) => {
                    const itemData = item.kode_item ? item.kode_item.toUpperCase() : ''.toUpperCase();
                    const valUpper = value.toUpperCase();
                    return itemData.indexOf(valUpper) > -1;
                });
                setFormData((prev) => ({
                    ...prev,
                    main_component_kode_item: value,
                }));
                setFilterData(filteredData);
            }
            if (isEmpty(value)) {
                setFormData((prev) => ({
                    ...prev,
                    main_component_kode_item: value,
                    main_component_internal_name: '',
                    main_component_id: null,
                }));
                setFilterData(mainData);
            }
        }

        setLoadingSearch(false);
    };
    const handleClickSupplierName = (item) => {
        setFormData((prev) => ({
            ...prev,
            main_component_kode_item: item?.kode_item,
            main_component_internal_name: item?.internal_name,
            main_component_id: item?.id,
        }));
        setOpenInfiniteScroll(false);
    };

    useEffect(() => {
        if (openInfiniteScroll) {
            getMoreData();
        }
    }, [openInfiniteScroll]);

    return (
        <>
            <Flex className="main-component__container">
                <Box mr="34">
                    <Text className="title-fieldmenu">
                        kode item main<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <Box position="relative">
                        <InputGroup>
                            <Input
                                className={`fieldmenu-input__main-component ${
                                    isSubmitted && isEmpty(kode_item) && 'required-field__input'
                                }`}
                                placeholder={'Masukan kode item'}
                                disabled={isEditModal}
                                onFocus={() => setOpenInfiniteScroll(true)}
                                value={kode_item}
                                height="48px"
                                onChange={(text) => {
                                    handleChange(text);
                                }}
                            />
                            <InputRightElement height="48px" right="12px">
                                <Box className="divider_input_infinite_scroll" />
                                {loadingSearch ? <Spinner size="sm" /> : <ArrowUpIconBlack />}
                            </InputRightElement>
                        </InputGroup>
                        <Menu isOpen={openInfiniteScroll} onClose={() => setOpenInfiniteScroll(false)}>
                            <MenuList mt="55px" zIndex={20} w={'210px'}>
                                <div id="scrollableDiv" className="scroll-container">
                                    <InfiniteScroll
                                        dataLength={filterData.length}
                                        next={() => getMoreData()}
                                        hasMore={false}
                                        scrollableTarget="scrollableDiv"
                                        loader={
                                            filterData.length === 0 ? (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            ) : filterData.length < 9 ? null : (
                                                <Text className="loading_fetch_next_page">Loading...</Text>
                                            )
                                        }
                                        endMessage={null}
                                    >
                                        {filterData.length === 0 && !loadingSearch ? (
                                            <Text className="no_data_infinite_scroll">
                                                Tidak Bisa Menemukan "{kode_item}"
                                            </Text>
                                        ) : (
                                            filterData?.map((item, index) => {
                                                return (
                                                    <Box
                                                        onClick={() => handleClickSupplierName(item)}
                                                        className="list_item_infinite"
                                                        key={index}
                                                    >
                                                        {item?.kode_item}
                                                    </Box>
                                                );
                                            })
                                        )}
                                    </InfiniteScroll>
                                </div>
                            </MenuList>
                        </Menu>
                        {isSubmitted && isEmpty(kode_item) && (
                            <Text className="required-field__text">*Masukan Kode Item</Text>
                        )}
                    </Box>
                </Box>
                <Box>
                    <Text className="title-fieldmenu">
                        internal name<span style={{ color: 'red' }}>*</span>
                    </Text>
                    <EmptyField className="input-field-disabled" buttonName={internal_name || 'Terisi Otomatis'} />
                    {isSubmitted && isEmpty(internal_name) && (
                        <Text className="required-field__text">*Masukan Internal Name</Text>
                    )}
                </Box>
            </Flex>
        </>
    );
};

export default MainComponent;
