import { DownloadBlackIcon } from '@asset/icons';
import { Box, Button, CircularProgress, Flex, Text, useToast } from '@chakra-ui/react';
import CustomButton from '@components/button';
import ModalConfirm from '@components/modalConfirm';
import ModalFormat from '@components/modalFormat';
import React, { useEffect, useRef, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useSelector } from 'react-redux';
import Table from './component/table/Table';
import {
    AutomaticWidthTable,
    handleSaveSubmit,
    handleSubmit,
    onChangeFile,
    onDownloadTemplateUpload,
} from './functions';
import { IconBack, IconFile, UploadIconSm } from './icons';
import Modalsuccess from '@components/modalSuccess';
import ModalFailedUpload from '@components/modalFailed';
import ErrorDetails from './component/errorDetails';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Pagination from '@components/pagination';
import LoadingSliceUpload from '@components/loadingItemSliceUpload';

import './styles.scss';

const initialDataPaginate = {
    data: [],
    totalPage: 1,
    totalData: 0,
};

const Index = () => {
    const toast = useToast();
    const { jenisMaterial } = useSelector((reducer) => reducer.globalFilterSlice);
    const navigate = useNavigate();
    const [previewData, setPreviewData] = useState([]);
    const [disabledSaveItem, setDisabledSaveItem] = useState(false);
    const [isLoadingUpload, setIsLoadingUpload] = useState(false);
    const [state, setState] = useState('upload');
    const [files, setFiles] = useState({});
    const [onRange, setOnrange] = useState(false);
    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [openModalSuccess, setOpenModalSuccess] = useState({ isOpen: false, message: '' });
    const [openModalFailed, setOpenModalFailed] = useState({ isOpen: false, message: '', type: '' });
    const [error, setError] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [loadingState, setLoadingState] = useState(false);
    const [dataPaginate, setDataPaginate] = useState(initialDataPaginate);
    const isData = Boolean(previewData?.length > 0);
    const { isHide } = useSelector((state) => state.sideMenuSlice);

    const handleCancel = () => {
        setDataPaginate(initialDataPaginate);
        setPreviewData([]);
        setState('upload');
        setDisabledSaveItem(false);
        setPage(1);
        setLimit(10);
    };
    React.useEffect(() => {
        if (Boolean(previewData?.toString())) {
            const fileData = {
                data: previewData?.slice((page - 1) * limit, limit + (page - 1) * limit),
                totalPage: Math.ceil(previewData?.length / limit),
                totalData: previewData?.length,
            };
            setDataPaginate(() => {
                return fileData;
            });
            setState('table-view');
        }
    }, [page, limit, previewData]);
    const head = AutomaticWidthTable({ previewData: dataPaginate?.data });
    /// https://stackoverflow.com/a/53090848
    const timer = useRef(null);
    const handleUpload = ({ files }) => {
        setState('loading-generate-file');
        timer.current = setTimeout(() => {
            onChangeFile({ setPreviewData, files, setState, setFiles, setDisabledSaveItem, toast, jenisMaterial });
        }, 3000);
    };
    const handleCancelLoading = () => {
        setDataPaginate(initialDataPaginate);
        setPage(1);
        setLimit(10);
        clearTimeout(timer.current);
        setPreviewData([]);
        setState('upload');
    };
    useEffect(() => {
        if (!isEmpty(jenisMaterial)) {
            setState('upload');
        }
    }, [jenisMaterial?.name]);
    return (
        <Box className="section-upload__container__material-configuration">
            <Flex className="head">
                <Flex onClick={() => navigate('/material-configuration')} className="left-section">
                    <IconBack />
                    <Text className="title-label">Upload File</Text>
                </Flex>
                {!isData && !state?.includes('loading-generate-file') && (
                    <Button
                        className="right-section"
                        isLoading={loadingState}
                        onClick={() =>
                            onDownloadTemplateUpload({
                                setIsLoading: setLoadingState,
                                toast,
                            })
                        }
                    >
                        <Box>
                            <DownloadBlackIcon color="#1746A2" />
                        </Box>
                        <Text className="title-download">Download Template</Text>
                    </Button>
                )}
            </Flex>
            <Box>
                {state?.includes('loading-generate-file') && (
                    <Box className="parent">
                        <Box className="child">
                            <CircularProgress size="70px" isIndeterminate color="#1746a2" />
                            <Text className="text-loading">Uploading File.....</Text>
                            <CustomButton onClick={handleCancelLoading} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>
                        </Box>
                    </Box>
                )}
                {state?.includes('loading-item-slice') && <LoadingSliceUpload />}
                {state?.includes('table-view') && (
                    <Box className="container-table-view">
                        <Text className="label-table">Priview File</Text>
                        <Box
                            className="container-table"
                            sx={{
                                maxWidth: isHide ? 'calc(100vw - 166px)' : 'calc(100vw - 356px)',
                            }}
                        >
                            {!Boolean(dataPaginate?.data?.toString()) ? (
                                <LoadingSliceUpload />
                            ) : (
                                <Table head={head} body={dataPaginate?.data} />
                            )}
                        </Box>
                        <Flex className="btn-action-group">
                            <CustomButton onClick={handleCancel} btnClassName="cancel-button" variant="outline">
                                Cancel
                            </CustomButton>

                            <Button
                                isDisabled={disabledSaveItem}
                                className="save-button"
                                onClick={() => handleSaveSubmit({ setOpenModalConfirm })}
                            >
                                Save Item
                            </Button>
                        </Flex>
                        <Pagination
                            menuList={[{ num: 10 }, { num: 20 }, { num: 30 }]}
                            page={Number(page)}
                            totalPage={dataPaginate.totalPage}
                            totalData={dataPaginate.totalData}
                            selectedPerPage={limit}
                            setSelectedPerpage={(selected) => {
                                setPage(1);
                                setState('loading-item-slice');
                                setLimit(selected);
                            }}
                            onPageChange={(i) => {
                                setState('loading-item-slice');
                                setPage(() => {
                                    return i?.selected + 1;
                                });
                            }}
                        />
                    </Box>
                )}
                {state?.includes('upload') && (
                    <Box className="body">
                        <Flex className="section-body">
                            <Flex className="upload-box">
                                <Box className="parent-line-vertical">
                                    {Array(11)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-vertical" />
                                        ))}
                                </Box>
                                <Box className="parent-line-horizon">
                                    {Array(8)
                                        .fill(1)
                                        .map((item, index) => (
                                            <Box key={index} className="child-line-horizon" />
                                        ))}
                                </Box>
                                <FileUploader
                                    name="file"
                                    onDraggingStateChange={(dragging) => setOnrange(dragging)}
                                    onDrop={(files) => handleUpload({ files })}
                                    handleChange={(files) => handleUpload({ files })}
                                    classes="file-uploader__drag-and-drop-only"
                                    types={['xlsx', 'csv']}
                                    onTypeError={(err) => setError(true)}
                                    disabled
                                />
                                <Box opacity={!onRange ? 0 : 10} className="after-drop">
                                    <Text className="after-drop-title">" Letakan Disini "</Text>
                                </Box>
                                <Box opacity={onRange ? 0 : 10} className="before-drop">
                                    <IconFile />
                                    <Text className="upload-text">
                                        Drag and Drop atau pilih file CSV atau Exel untuk di upload
                                    </Text>
                                    <Button className="upload-button">
                                        <FileUploader
                                            onDrop={(files) => handleUpload({ files })}
                                            handleChange={(files) => handleUpload({ files })}
                                            name="file"
                                            classes="file-uploader"
                                            types={['xlsx', 'csv']}
                                        />
                                        <UploadIconSm />
                                        Upload File
                                    </Button>
                                </Box>
                            </Flex>
                        </Flex>
                    </Box>
                )}
            </Box>

            {/* ========= MODALS ========= */}
            {error && (
                <ModalFormat
                    isOpen={error}
                    onClose={() => setError(false)}
                    title="Format File salah"
                    description="Maaf, format file yang Anda masukkan tidak sesuai. 
                    Mohon pastikan menggunakan format yang benar."
                />
            )}

            <ModalConfirm
                loading={isLoadingUpload}
                isOpen={openModalConfirm}
                onClose={() => setOpenModalConfirm(false)}
                onSubmit={() =>
                    handleSubmit({
                        previewData,
                        setPreviewData,
                        files,
                        setIsLoadingUpload,
                        setOpenModalSuccess,
                        toast,
                        setOpenModalConfirm,
                        setOpenModalFailed,
                        setDisabledSaveItem,
                    })
                }
                title="Apa anda yakin ingin mengupload File?"
                submitLabel="Lanjut"
            />
            <ModalFailedUpload
                isOpen={openModalFailed.isOpen}
                onClose={() => setOpenModalFailed((prev) => ({ ...prev, isOpen: false }))}
                onRightBtnClick={() =>
                    setOpenModalFailed((prev) => ({ ...prev, message: '', isOpen: false, type: '' }))
                }
                onLeftBtnClick={() => {
                    setOpenModalFailed((prev) => ({ ...prev, isOpen: false }));
                    setPreviewData([]);
                    setFiles({});
                    setState('upload');
                }}
                title="Uplod file gagal"
                classNameDescription="description"
                description={<ErrorDetails data={openModalFailed} />}
                leftLabelButton="Upload Ulang"
                rightLabelButton="Kembali"
            />
            <Modalsuccess
                isOpen={openModalSuccess.isOpen}
                onClose={() => {
                    setOpenModalSuccess((prev) => ({ ...prev, isOpen: false }));
                    navigate('/material-configuration');
                }}
                labelButton="Kembali"
                description={
                    Boolean(openModalSuccess.message) ? (
                        <>
                            <Box sx={{ color: 'red', mb: '8px' }}>{openModalSuccess.message}</Box>
                            <Box>
                                <CustomButton
                                    height="36px"
                                    onClick={() => {
                                        setOpenModalSuccess((prev) => ({ ...prev, isOpen: false, message: '' }));
                                    }}
                                >
                                    Tutup
                                </CustomButton>
                            </Box>
                        </>
                    ) : null
                }
                title="File Berhasil Diupload"
            />
        </Box>
    );
};

export default Index;
