import { Box, Text, Tooltip } from '@chakra-ui/react';
import EditTableColumn from './components/editTableColumn';
import EditTableColumnDatePicker from './components/editTableColumnDatePicker';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableListOrderConfirmation = [
    {
        name: 'Kode Item',
        value: 'kode_item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })} cursor="pointer">
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Nama Item',
        value: 'trade_name',
        width: 288,
        children: ['-'],
        component: ({ value }) => (
            <Box width={'270px'}>
                <Tooltip
                    label={value}
                    fontStyle={'normal'}
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'left'}
                    color="white"
                >
                    <Text className="column-text-ellipsis">{value ? value : '-'}</Text>
                </Tooltip>
            </Box>
        ),
    },
    {
        name: 'Qty/Kanban',
        value: 'qty_per_kanban',
        width: 40,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Order',
        value: 'order_qty',
        width: 40,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Order Kanban',
        value: 'order_kanban',
        width: 40,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
    {
        name: 'Konfirmasi Pcs',
        value: 'konfirmasi_pcs',
        width: 40,
        children: ['Pcs'],
        component: ({ item, value, hasEdited }) => {
            return (
                <EditTableColumn
                    item={item}
                    value={value}
                    errorHighlight={{
                        has_konfirmasi_pcs_error: hasEdited?.has_konfirmasi_pcs_error,
                        has_eta_error: hasEdited?.has_eta_error,
                    }}
                />
            );
        },
    },
    {
        name: 'Konfirmasi Kanban',
        value: 'konfirmasi_kanban',
        width: 40,
        children: ['Pcs'],
        component: ({ value }) => (
            <Box>
                <Text className="column-text">{value || '-'}</Text>
            </Box>
        ),
    },
    {
        name: 'Konfirmasi ETA',
        value: 'konfirmasi_eta',
        width: 40,
        children: ['-'],
        component: ({ item, value, hasEdited }) => {
            return (
                <EditTableColumnDatePicker
                    errorHighlight={{
                        has_konfirmasi_pcs_error: hasEdited?.has_konfirmasi_pcs_error,
                        has_eta_error: hasEdited?.has_eta_error,
                    }}
                    initialDateValue={value}
                    item={item}
                />
            );
        },
    },
    {
        name: 'Suggestion',
        value: 'suggestion',
        width: 40,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value)}</Text>,
    },
];
