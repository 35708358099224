import { ArrowDownIcon } from '@asset/icons';
import { Box, Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './styles.scss';
import { onChangeJenisMaterialType, resetSupplier, onChangeSelectedMaterial } from '@store/list/globalFilter';
import { fetchJenisSupplier } from './function';

const MenuFilterHeaderJenisMaterial = () => {
    const dispatch = useDispatch();
    const { jenisMaterial } = useSelector((reducer) => reducer.globalFilterSlice);
    const [selectedSecond, setSelectedSecond] = useState(jenisMaterial);
    const [listJenisMaterial, setListJenisMaterial] = useState([]);

    useEffect(() => {
        fetchJenisSupplier({ setListJenisMaterial });
    }, []);
    return (
        <Box className="menu-filter-jenis-material__container">
            <Box className="box-drop-down-global-filter-jenis-material">
                <Menu>
                    <MenuButton
                        className="menu-button-jenis-material"
                        bg="none"
                        as={Button}
                        rightIcon={<ArrowDownIcon />}
                    >
                        {selectedSecond?.name || '-'}
                    </MenuButton>
                    <MenuList>
                        {listJenisMaterial?.map((material, index) => (
                            <MenuItem
                                key={index}
                                className="menu-item"
                                onClick={() => {
                                    dispatch(onChangeSelectedMaterial({}));
                                    dispatch(resetSupplier());
                                    dispatch(onChangeJenisMaterialType(material));
                                    setSelectedSecond(material);
                                }}
                            >
                                {material.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
        </Box>
    );
};

export default MenuFilterHeaderJenisMaterial;
