import { Text, Box, Flex, Tooltip } from '@chakra-ui/react';
import EditTableColumn from './components/editTableColumn';
import { formatNumber, handleCopyToClipboard } from '@helpers/functions';

export const tableListOrderDelivery = [
    {
        name: 'No Order',
        value: 'no_order',
        width: 100,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'No PO',
        value: 'no_po',
        width: 180,
        children: ['-'],
        component: ({ value }) => <Text className="column-text">{value}</Text>,
    },
    {
        name: 'Kode Item',
        value: 'orderManagement.materialConfig.kode_item',
        width: 140,
        children: ['-'],
        component: ({ value, toast }) => (
            <Box onClick={() => handleCopyToClipboard({ item: value, toast })}>
                <Text className="column-text">{value}</Text>
            </Box>
        ),
    },
    {
        name: 'Nama Item',
        value: 'orderManagement.materialConfig.trade_name',
        flex: 1,
        width: 367,
        children: ['-'],
        component: ({ value }) => (
            <Box width={'367px'}>
                <Tooltip
                    label={value}
                    fontStyle={'normal'}
                    fontWeight={'400'}
                    fontSize={'12px'}
                    lineHeight={'18px'}
                    textAlign={'left'}
                    color="white"
                >
                    <Text className="column-text-ellipsis">{value ? value : '-'}</Text>
                </Tooltip>
            </Box>
        ),
    },
    {
        name: 'Quantity Konfirmasi',
        value: 'in_preparation',
        width: 180,
        children: ['Pcs'],
        component: ({ value }) => <Text className="column-text">{formatNumber(value) || '-'}</Text>,
    },
    {
        name: 'Revisi Qty',
        value: 'qty_revisi',
        width: 180,
        children: ['Pcs'],
        component: ({ value, orderData, hasEdited }) => (
            <Flex alignItems="center">
                <EditTableColumn
                    errorHighlight={{
                        has_note_error: hasEdited?.has_note_error,
                        has_qty_revisi_error: hasEdited?.has_qty_revisi_error,
                    }}
                    type="number"
                    attributeCurrentValue="qty_revisi"
                    value={value}
                    item={orderData}
                />
            </Flex>
        ),
    },
    {
        name: 'Notes',
        value: 'notes',
        width: 400,
        children: ['-'],
        component: ({ value, orderData, hasEdited }) => (
            <Flex alignItems="center">
                <EditTableColumn
                    errorHighlight={{
                        has_notes_error: hasEdited?.has_notes_error,
                        has_qty_revisi_error: hasEdited?.has_qty_revisi_error,
                    }}
                    widthInput="390px"
                    attributeCurrentValue="notes"
                    type="text"
                    item={orderData}
                    value={value}
                />
            </Flex>
        ),
    },
];
