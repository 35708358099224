import { Box, Flex, useToast } from '@chakra-ui/react';
import { tableList } from '@pagesPhase2/orderManagement/const';
import './styles.scss';

const TableColumn = ({ ordersData }) => {
    const toast = useToast();
    return (
        <Flex className="table-column table-column-order-management__container">
            {tableList?.map((item, idx) => {
                return (
                    <Box
                        background={'white'}
                        key={item.name}
                        w={item.width}
                        className="column__item"
                        id={`column__item--${idx}`}
                    >
                        {item.component({
                            value: ordersData[item.value] !== null ? ordersData[item.value] : '-',
                            ordersData,
                            toast,
                        })}
                    </Box>
                );
            })}
        </Flex>
    );
};

export default TableColumn;
