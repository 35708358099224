export const SuccessIcon = () => (
    <svg width="150" height="150" viewBox="0 0 150 150" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="75" cy="75" r="75" fill="#92F296" />
        <path
            d="M98.3337 57.5L66.2503 89.5833L51.667 75"
            stroke="#2D2D2D"
            strokeWidth="8"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
