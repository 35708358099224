export const EditIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.04163 17.25H17.5416"
            stroke="#4163A4"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8.20837 12.7933V14.9167H10.3426L16.375 8.88153L14.2444 6.75L8.20837 12.7933Z"
            stroke="#4163A4"
            strokeWidth="1.5"
            strokeLinejoin="round"
        />
        <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#1746A2" />
    </svg>
);

export const RekapDeliveryIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_7721_2346)">
            <path d="M2 2H16V16H2V2Z" stroke="white" strokeWidth="4" />
            <path
                d="M5.25 11.25H10.5V12.75H5.25V11.25ZM5.25 8.25H12.75V9.75H5.25V8.25ZM5.25 5.25H12.75V6.75H5.25V5.25ZM14.25 2.25H11.115C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25H3.75C3.645 2.25 3.5475 2.2575 3.45 2.28C3.1575 2.34 2.895 2.49 2.6925 2.6925C2.5575 2.8275 2.445 2.9925 2.37 3.1725C2.295 3.345 2.25 3.54 2.25 3.75V14.25C2.25 14.4525 2.295 14.655 2.37 14.835C2.445 15.015 2.5575 15.1725 2.6925 15.315C2.895 15.5175 3.1575 15.6675 3.45 15.7275C3.5475 15.7425 3.645 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.0625C9.3075 2.0625 9.5625 2.3175 9.5625 2.625C9.5625 2.9325 9.3075 3.1875 9 3.1875C8.6925 3.1875 8.4375 2.9325 8.4375 2.625C8.4375 2.3175 8.6925 2.0625 9 2.0625ZM14.25 14.25H3.75V3.75H14.25V14.25Z"
                fill="#4163A4"
            />
            <path
                d="M5.25 11.25H10.5V12.75H5.25V11.25ZM5.25 8.25H12.75V9.75H5.25V8.25ZM5.25 5.25H12.75V6.75H5.25V5.25ZM14.25 2.25H11.115C10.8 1.38 9.975 0.75 9 0.75C8.025 0.75 7.2 1.38 6.885 2.25H3.75C3.645 2.25 3.5475 2.2575 3.45 2.28C3.1575 2.34 2.895 2.49 2.6925 2.6925C2.5575 2.8275 2.445 2.9925 2.37 3.1725C2.295 3.345 2.25 3.54 2.25 3.75V14.25C2.25 14.4525 2.295 14.655 2.37 14.835C2.445 15.015 2.5575 15.1725 2.6925 15.315C2.895 15.5175 3.1575 15.6675 3.45 15.7275C3.5475 15.7425 3.645 15.75 3.75 15.75H14.25C15.075 15.75 15.75 15.075 15.75 14.25V3.75C15.75 2.925 15.075 2.25 14.25 2.25ZM9 2.0625C9.3075 2.0625 9.5625 2.3175 9.5625 2.625C9.5625 2.9325 9.3075 3.1875 9 3.1875C8.6925 3.1875 8.4375 2.9325 8.4375 2.625C8.4375 2.3175 8.6925 2.0625 9 2.0625ZM14.25 14.25H3.75V3.75H14.25V14.25Z"
                stroke="#4163A4"
            />
        </g>
        <defs>
            <clipPath id="clip0_7721_2346">
                <rect width="18" height="18" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const IconPlus = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 3.33301V12.6663" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3.3335 8H12.6668" stroke="#1746A2" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
);
